import { IStackTokens, Stack } from "@fluentui/react";
import React from "react";
import { Maybe } from "../../../../types/base-types";
import { ColumnChart } from "../../../common/charts/column";
import { IColumnData } from "../../../common/charts/column/column.types";
import { PieChart } from "../../../common/charts/pie";
import { IPieData } from "../../../common/charts/pie/pie.types";

export interface IMCSBChartTileProp {
  Title: string,
  IsPieChart: boolean
  dataPointSelection?: (event, chartContext, config) => void
  pieData?: Maybe<IPieData>
  columnChartData?: Maybe<IColumnData>
  error: Maybe<Error>
  isLoading: boolean
}

export class ChartTile extends React.Component<IMCSBChartTileProp> {
  getChartData = (): JSX.Element => {
    return (
      this.props.IsPieChart ?
        <PieChart
          isLoading={this.props.isLoading}
          error={this.props.error?.message}
          showDefaultErrorMessage={true}
          chart={
            {
              name: 'test',
              height: '340px',
              labels: this.props.pieData?.labels,
              data: this.props.pieData?.data,
              dataPointSelection: (event, chartContext, config) => {
                if (this.props.dataPointSelection)
                  this.props.dataPointSelection(event, chartContext, config)
              }
            }
          }
        /> :
        <ColumnChart
          isLoading={this.props.isLoading}
          error={this.props.error?.message}
          showDefaultErrorMessage={true}
          chart={
            {
              name: 'columnChart',
              height: '340px',
              categories: this.props.columnChartData?.categories,
              data: this.props.columnChartData?.data
            }
          }
        />
    )

  }
  render(): JSX.Element {
    return (
      <Stack
        styles={{
          root: {
            boxShadow: '0 3.2px 7.2px rgb(0 0 0 / 13%), 0 0.6px 1.8px rgb(0 0 0 / 11%)',
          }
        }}
      >
        <Stack.Item
          styles={{
            root: {
              // backgroundColor: '#f2f2f2'
            }
          }}
        >
          <div style={{
            fontFamily: 'Segoe UI',
            fontSize: '18px',
            color: '#323130',
            padding: '13px',
            fontWeight: 600,
            height:'54px'
          }}>

            {this.props.isLoading ? '' : this.props.Title}
          </div>
        </Stack.Item>
        <Stack.Item>
          {this.getChartData()}
        </Stack.Item>
      </Stack>
    )
  }
}