/**
 * Create the store with dynamic reducers
 */

 import {
  configureStore,
  getDefaultMiddleware,
  Middleware,
  EnhancedStore,
  CombinedState,
  AnyAction,
  Dispatch,
  StoreEnhancer,
} from '@reduxjs/toolkit';
import { routerMiddleware, RouterState } from 'connected-react-router';
import { createInjectorsEnhancer, forceReducerReload } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';
import { History } from 'history';

import { createReducer } from './reducers';
import { IRootState } from '../types';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: () => StoreEnhancer<{}, {}>;
  }
}
export function configureAppStore(
  history?: History,
  initialState?: IRootState,
): EnhancedStore<
  CombinedState<{ router: RouterState<History.PoorMansUnknown> }>,
  AnyAction,
  Middleware<{}, unknown, Dispatch<AnyAction>>[]
> {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const { run: runSaga } = sagaMiddleware;

  /*
   * Create the store with two middlewares
   * 1. sagaMiddleware: Makes redux-sagas work
   * 2. routerMiddleware: Syncs the location/URL path to the state
   */
  const middlewares = [sagaMiddleware] as Middleware[];

  if (history) {
    middlewares.push(routerMiddleware(history));
  }

  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ];

  if (window?.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }

  const store = configureStore({
    preloadedState: initialState,
    reducer: createReducer(),
    middleware: [...getDefaultMiddleware(), ...middlewares],
    devTools: process.env.NODE_ENV !== 'production',
    enhancers,
  });

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      forceReducerReload(store);
    });
  }

  
  return store;
}
