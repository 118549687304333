import React from "react";
import { IRootState } from "../../../../types";
import { DeepReadonly } from "../../../../types/base-types";
import { ISummaryGrid, ISummaryTabDetails, ISummaryTabMapState, ISummaryTabProps } from "../../store/types/nmsSummaryTab.types";
import * as Actions from '../../store/action';
import { Action, compose } from "@reduxjs/toolkit";
import { injectReducer, injectSaga } from "redux-injectors";
import { connect } from "react-redux";
import { deviceReducer } from "../../store/reducers";
import { InmsDeviceDetailsState } from "../../store/state";
import { devicedetailsSagas } from "../../store/sagas";
import { IColumn, SelectionMode } from "@fluentui/react/lib/DetailsList";
import { getTheme, mergeStyles, mergeStyleSets, ShimmeredDetailsList } from "@fluentui/react";

interface ISummaryTabLocalState { }

const theme = getTheme();
const classNames = mergeStyleSets({
  headerAndFooter: {
    //borderTop: `${1}px solid ${theme.palette.neutralQuaternary}`,
    //borderBottom: `${1}px solid ${theme.palette.neutralQuaternary}`,
    padding: 4,
    margin: `${1}px 0`,
    marginTop: 10,
    //background: theme.palette.neutralLighterAlt,
    backgroundColor: '#edebe9',
    // Overlay the sizer bars
    position: 'relative',
    zIndex: 100,
  },
  headerTitle: [
    theme.fonts.medium,
    {
      padding: '1px 0',
      marginLeft: '10px',
      fontWeight: 'bold'
    },
  ],

  headersubTitle: [
    theme.fonts.medium,
    {
      padding: '1px 0',
    },
  ],
  headerLinkSet: {
    margin: '4px -8px',
  },
  headerLink: {
    margin: '0 8px',
  },
});

export class SummaryTab extends React.Component<ISummaryTabProps, ISummaryTabLocalState> {
  componentDidMount(): void {
    if (this.props.session.queryParam?.id)
      this.props.fetchSummaryTabDetails({
        deviceId: this.props.session.queryParam?.id ?? '',
        serviceTreeId: this.props.session?.serviceTree?.serviceTreeId ?? ''
      })
  }

  //   private getActiveStatusStyle = mergeStyles({
  //     height: '22px',
  //     width: '22px',
  //     backgroundColor: '#008000'
  //   })

  //   private getDisabledStatusStyle = mergeStyles({
  //     height: '22px',
  //     width: '22px',
  //     backgroundColor: '#f00'
  //   })

  //   private getNoStatusStyle = mergeStyles({
  //     height: '22px',
  //     width: '22px',
  //     backgroundColor: '#808080'
  // })

  private getActiveStatusStyle = mergeStyles({
    height: '22px',
    width: '22px',
    marginLeft: '5px',
    marginRight: '5px',
    marginBottom: '-20px',
    borderRadius: '50%',
    borderStyle: 'solid',
    borderWidth: '0.5px',
    borderColor: '#008000',
    backgroundColor: '#008000',

  })

  private getDisabledStatusStyle = mergeStyles({
    height: '22px',
    width: '22px',
    marginLeft: '5px',
    marginRight: '5px',
    marginBottom: '-20px',
    borderRadius: '50%',
    borderStyle: 'solid',
    borderWidth: '0.5px',
    borderColor: '#f00',
    backgroundColor: '#f00'
  })

  private getNoStatusStyle = mergeStyles({
    height: '22px',
    width: '22px',
    marginLeft: '5px',
    marginRight: '5px',
    marginBottom: '-20px',
    borderRadius: '50%',
    borderStyle: 'solid',
    borderWidth: '0.5px',
    borderColor: '#808080',
    backgroundColor: '#808080'
  })

  public componentDidUpdate(prevProps: ISummaryTabProps) {
    if (this.props.session?.queryParam?.id && this.props.session?.queryParam?.id != prevProps.session.queryParam?.id) {
      this.props.fetchSummaryTabDetails({
        deviceId: this.props.session.queryParam?.id ?? '',
        serviceTreeId: this.props.session?.serviceTree?.serviceTreeId ?? ''
      })
    }
  }

  public getColumns = (): IColumn[] => {
    let cols: IColumn[] = [{
      key: "column1",
      name: "column1",
      fieldName: "column1",
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true
    },
    {
      key: "column2",
      name: "column2",
      fieldName: "column2",
      minWidth: 500,
      maxWidth: 500,
      isResizable: true,
      isRowHeader: true
    }, {
      key: "column3",
      name: "column3",
      fieldName: "column3",
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true
    },
    {
      key: "column4",
      name: "column4",
      fieldName: "column4",
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true
    }]
    return cols;
  }

  public onRenderItemColumn = (item: any, index?: number, column?: IColumn): JSX.Element | string | number => {
    if (!column) return "";
    if (column && (column.key === 'column1' || column.key === 'column3')) {
      return <b> {item[column?.key]}</b>
    }
    if (column && column.key == 'column4' && index == 4) {
      return <div className={item[column?.key] == 0 ? this.getDisabledStatusStyle : (item[column?.key] == 1 ? this.getActiveStatusStyle : this.getNoStatusStyle)}></div>
    }
    return item[column?.key]
  };


  private getShimmeredList = (): ISummaryGrid[] => {
    return [
      {
        column1: "Device Name",
        column2: this.props.summaryTabDetailsState.ISummaryTabDetailsResponse?.deviceName ?? '',
        column3: "Model",
        column4: this.props.summaryTabDetailsState.ISummaryTabDetailsResponse?.model ?? ''
      },
      {
        column1: "Data Center",
        column2: this.props.summaryTabDetailsState.ISummaryTabDetailsResponse?.dataCenter ?? '',
        column3: "Enclave",
        column4: this.props.summaryTabDetailsState.ISummaryTabDetailsResponse?.enclave ?? ''
      },
      {
        column1: "Ip Address",
        column2: this.props.summaryTabDetailsState.ISummaryTabDetailsResponse?.ipAddress ?? '',
        column3: "Device Environment",
        column4: this.props.summaryTabDetailsState.ISummaryTabDetailsResponse?.deviceEnvironment ?? ''
      },
      {
        column1: "OS Version",
        column2: this.props.summaryTabDetailsState.ISummaryTabDetailsResponse?.osVersion ?? '',
        column3: "Last Polled Time Stamp",
        column4: this.props.summaryTabDetailsState.ISummaryTabDetailsResponse?.deviceLastPolledTimeStamp ?? ''
      },
      {
        column1: "Service OID",
        column2: this.props.summaryTabDetailsState.ISummaryTabDetailsResponse?.serviceOID ?? '',
        column3: "Device Status",
        column4: this.props.summaryTabDetailsState.ISummaryTabDetailsResponse?.deviceStatus ?? ''
      },
      {
        column1: "Serial Number",
        column2: this.props.summaryTabDetailsState.ISummaryTabDetailsResponse?.serialNumber ?? '',
        column3: "Comment",
        column4: this.props.summaryTabDetailsState.ISummaryTabDetailsResponse?.comments ?? ''
      }]
  }

  public render(): JSX.Element {
    return (
      <>
        <div className={classNames.headerAndFooter}>
          <div className={classNames.headerTitle}>{this.props.session.queryParam?.name}</div>
        </div>
        {
          <ShimmeredDetailsList
            columns={this.getColumns()}
            items={this.getShimmeredList()}
            selectionMode={SelectionMode.none}
            isHeaderVisible={false}
            shimmerLines={6}
            enableShimmer={this.props.summaryTabDetailsState?.isSummaryTabDetailsLoading}
            onRenderItemColumn={this.onRenderItemColumn}
          />
        }
      </>
    );
  }
}

export const mapStateToProps = (state: DeepReadonly<IRootState>): DeepReadonly<ISummaryTabMapState> => ({
  summaryTabDetailsState: state.nmsDeviceDetailsState,
  session: state.session,
  microUI: state.microUI,
  router: state.router
});

const mapDispatchToProps = {
  fetchSummaryTabDetails: Actions.fetchSummaryTabDetails,
};

export default compose<React.ComponentType>(
  injectReducer({
    key: 'nmsDeviceDetailsState',//Keep this same in IRoot, and props interface
    reducer: deviceReducer as React.Reducer<DeepReadonly<InmsDeviceDetailsState>, DeepReadonly<Action>>,
  }),
  injectSaga({ key: 'nmsDeviceDetailsState', saga: devicedetailsSagas }),
  connect(mapStateToProps, mapDispatchToProps),
)
  (SummaryTab);