import { Icon } from "@fluentui/react";
import React from "react";
import { Resources } from "../../../../locales/resources";
import { ErrorBar } from "../../error";
import { IPieChartProps } from "./pie.types";
import { PieChartVisual } from "./visuals/pieChartVisual";
import { ShimmerPieChart } from "./visuals/shimmerPieChart";

export class PieChart extends React.Component<IPieChartProps> {
  render(): JSX.Element {
    return (
      <>
        {
          this.props.isLoading ?
            <ShimmerPieChart {...this.props.chart} />
            :
            this.props.error || !this.props.chart?.data ?
              <div style={{ height: this.props.chart.height }}>
                <ErrorBar error={this.props.showDefaultErrorMessage ? Resources.GenericErrorMessage : this.props.error} className='Pie' /></div> :
              !this.props.chart?.data || this.props.chart?.data?.length == 0 || !this.props.chart?.labels || this.props.chart?.labels?.length == 0 ?
                <div style={{ height: this.props.chart.height, textAlign: 'center', justifyContent: 'center', justifyItems: 'center',marginBottom:'15px' }}><Icon iconName='Info' styles={{ root: { fontSize: '1rem' } }} />{" No data available"}</div> :
                <><PieChartVisual {...this.props.chart} /></>
        }
      </>
    )
  }




}