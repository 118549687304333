import { Action, compose } from '@reduxjs/toolkit';
import React from "react";
import { Helmet } from "react-helmet-async";
import { Resources } from "../../locales/resources";
import { IStyleSet, Label, ILabelStyles, IShimmeredDetailsListProps, IColumn, ICommandBarItemProps, Panel, Stack, PrimaryButton, TextField, Selection, BaseButton, Button, Dropdown, IDropdownStyles, DropdownMenuItemType, IDropdownOption, EdgeChromiumHighContrastSelector, MessageBarType, MessageBar, mergeStyles, Icon, values, getTheme, mergeStyleSets, DialogType, Dialog, DialogFooter, DefaultButton, PanelType, List, AutoScroll, IDialogStyles } from '@fluentui/react';
import { Grid } from '../common/grid';
import { IDataCentersDetails, IDeviceMapState, IDeviceTypeDetails, IEnvironmentsDetails, INetworkDevice, INMSDeviceDetailsEditRequest, INMSDeviceDetailsProps, INMSDeviceDetailsUpdateRequest, INMSDeviceFilterTypes, INMSMultiDeviceEditRequest, IStateDetails } from './store/types/nmsDeviceInventory.types';
import { DeepReadonly, Maybe } from '../../types/base-types';
import { IRootState } from '../../types/rootState';
import { injectReducer, injectSaga } from 'redux-injectors';
import { deviceReducer } from './store/reducers';
import { IDeviceState } from './store/state';
import { devicedetailsSagas } from './store/sagas';
import { connect } from 'react-redux';
import * as Actions from './store/action';
import Loader from '../common/Loader/Loader';
import { IFilter } from '../common/grid/grid.types';
import AppContext from '../../utility/AppContext';
import { inherits } from 'util';


interface INMSinventoryDetailLocalState {
    showFilters: boolean,
    top?: number,
    skip?: number,
    countPerPage?: number,
    filters?: IFilter[],
    deviceAdded: INetworkDevice[],
    deviceRemoved: INetworkDevice[],
    deviceRemovedId: Maybe<string>,
    deviceEdited: INetworkDevice[],
    device?: INetworkDevice[];
    selectedDevice: INetworkDevice[],
    editdData,
    isFilterDevicePanelOpen: boolean,
    isAddDevicePanelOpen: boolean,
    isEditDevicePanelOpen: boolean,
    isDeleteDevicePanelHidden: boolean,
    noDeleteDeviceDialogHidden: boolean,
    filterDevicePaneData: INMSDeviceFilterTypes, //This will be updated for Filter
    addDevicePaneData: INMSDeviceDetailsUpdateRequest,
    editDevicePaneData: INMSDeviceDetailsEditRequest,
    editMultiDevicePaneData: INMSDeviceDetailsEditRequest[],
    isFilterButtonDisabled: boolean,
    isAddButtonDisabled: boolean,
    isEditButtonDisabled: boolean,
    isFilterApplied: boolean,
    selectedFilter: IFilter[],
    isAzureDcSelected: boolean,
    searchText: Maybe<string>,
    dcSearchText: Maybe<string>,
    enSearchText: Maybe<string>
}


const theme = getTheme();
const classNames = mergeStyleSets({
    headerAndFooter: {
        //borderTop: `${1}px solid ${theme.palette.neutralQuaternary}`,
        //borderBottom: `${1}px solid ${theme.palette.neutralQuaternary}`,
        padding: 4,
        margin: `${1}px 0`,
        marginTop: 10,
        //background: theme.palette.neutralLighterAlt,
        backgroundColor: '#edebe9',
        // Overlay the sizer bars
        position: 'relative',
        zIndex: 100,
    },
    headerTitle: [
        theme.fonts.medium,
        {
            padding: '1px 0',
            marginLeft: '20px',
            marginRight: '20px',
            fontWeight: 'bold',

        },
    ],

    headersubTitle: [
        theme.fonts.medium,
        {
            padding: '1px 0',
        },
    ],
    headerLinkSet: {
        margin: '4px -8px',
    },
    headerLink: {
        margin: '0 8px',
    },
});



export class InventoryGrid extends React.Component<INMSDeviceDetailsProps, INMSinventoryDetailLocalState>{
    private readonly DeviceSelection: Selection;

    private readonly initialFilterData: INMSDeviceFilterTypes = {
        dataCenter: '',
        enclave: ''
    };

    private readonly initialAddDevice: INMSDeviceDetailsUpdateRequest = {
        deviceName: '',
        deviceAddressIPv4: '',
        dataCenterId: undefined,
        deviceTypeId: undefined,
        environmentId: undefined,
        enclave: '',
        azureSubscriptionId: 'Null',
        azureResourceGroupName: 'Null',
        serviceTreeId: this.props.session.serviceTree?.serviceTreeId ?? '',
        comment: 'Null',
        azureTenantId: 'Null',
        state: '',
    };

    private readonly initialEditDevice: INMSDeviceDetailsEditRequest = {
        deviceName: '',
        ipAddress: '',
        dataCenterId: undefined,
        deviceTypeId: undefined,
        environmentId: undefined,
        enclave: '',
        azureSubscriptionId: 'Null',
        azureResourceGroupName: 'Null',
        serviceTreeId: this.props.session.serviceTree?.serviceTreeId ?? '',
        deviceId: '',
        comment: 'Null',
        azureTenantId: 'Null',
        state: '',
    };



    constructor(props: INMSDeviceDetailsProps) {
        super(props)
        this.state = {
            showFilters: false,
            top: 25,
            skip: 0,
            countPerPage: 25,
            filters: [],
            deviceEdited: [],
            device: [],
            deviceAdded: [],
            deviceRemoved: [],
            deviceRemovedId: '',
            selectedDevice: [],
            editdData: [],
            isFilterDevicePanelOpen: false,
            isAddDevicePanelOpen: false,
            isEditDevicePanelOpen: false,
            isDeleteDevicePanelHidden: true,
            noDeleteDeviceDialogHidden: true,
            filterDevicePaneData: this.initialFilterData, //This will be updated for filter
            addDevicePaneData: this.initialAddDevice,
            editDevicePaneData: this.initialEditDevice,
            editMultiDevicePaneData: this.initialEditDevice[0],
            isFilterButtonDisabled: true,
            isAddButtonDisabled: true,
            isEditButtonDisabled: true,
            isFilterApplied: false,
            selectedFilter: [],
            isAzureDcSelected: false,
            searchText: undefined,
            dcSearchText: undefined,
            enSearchText: undefined
        };
        this.DeviceSelection = new Selection({
            onSelectionChanged: (): void => this.getDeviceSelectionDetails(),
        });
    }


    private getDeviceSelectionDetails(): void {
        const deviceSelectionDetails = this.DeviceSelection.getSelection();

        //this.getSelectedDeviceToEdit();
        this.setState((prevState) => {
            return { selectedDevice: deviceSelectionDetails as INetworkDevice[] }
        })
    }


    componentDidMount() {
        this.props.fetchNMSDeviceInventory({
            top: this.state.top,
            skip: this.state.skip,
            serviceTreeId: this.props.session.serviceTree?.serviceTreeId ?? '',
            searchText: this.state.searchText,
            dcSearchText: this.state.dcSearchText,
            enSearchText: this.state.enSearchText
        })

    }


    public componentDidUpdate(prevProps: INMSDeviceDetailsProps) {
        if (prevProps.deviceInventoryDetailsState.INMSDevInentoryResponse !== this.props.deviceInventoryDetailsState.INMSDevInentoryResponse) {
            this.setState((prevState) => {
                return {
                    device: [...this.props.deviceInventoryDetailsState.INMSDevInentoryResponse?.deviceInventory ?? []],
                    deviceAdded: [],
                    deviceEdited: [],
                    deviceRemoved: [],
                    selectedDevice: [],
                    searchText: prevState.searchText,
                    filters: this.props.deviceInventoryDetailsState.isDeviceInventoryDataSaved ? [] : [...prevState.filters ?? []]
                }
            })
        }
        //console.log(this.state)

    }

    private getMessageBar = (messageType: MessageBarType, message: string): React.ReactElement => (
        <MessageBar
            messageBarType={messageType}
            isMultiline={false}
        >
            {message}
        </MessageBar>
    );



    //Grid the Columns
    public getColumns = (): IColumn[] => {
        let cols: IColumn[] = [{
            key: "deviceName",
            name: "Device Name",
            fieldName: "deviceName",
            minWidth: 50,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true
        },
        {
            key: "ipAddress",
            name: "IP Address",
            fieldName: "ipAddress",
            minWidth: 50,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true
        },
        {
            key: "dataCenter",
            name: "Data Center",
            fieldName: "dataCenter",
            minWidth: 50,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true
        },
        {
            key: "enclave",
            name: "Enclave",
            fieldName: "enclave",
            minWidth: 50,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true
        },
        {
            key: "deviceType",
            name: "Device Type",
            fieldName: "deviceType",
            minWidth: 50,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true
        },
        {
            key: "deviceVendor",
            name: "Device Vendor",
            fieldName: "deviceVendor",
            minWidth: 50,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true
        },
        {
            key: "osVersion",
            name: "OS Version",
            fieldName: "osVersion",
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            isRowHeader: true
        },
        {
            key: "serialNumber",
            name: "Serial Number",
            fieldName: "serialNumber",
            minWidth: 100,
            maxWidth: 120,
            isResizable: true,
            isRowHeader: true
        },
        {
            key: "deviceStatus",
            name: "Device Status",
            fieldName: "deviceStatus",
            isMultiline: true,
            minWidth: 70,
            maxWidth: 120,
            isResizable: true,
            isRowHeader: true

        },
        {
            key: "goldCodeCompliance",
            name: "Gold Code Compliance",
            fieldName: "goldCodeCompliance",
            isMultiline: true,
            minWidth: 30,
            maxWidth: 100,
            isResizable: true,
            isRowHeader: true

        },
        {
            key: "dashboard",
            name: "Dashboard",
            fieldName: "dashboard",
            isMultiline: true,
            minWidth: 30,
            maxWidth: 100,
            isResizable: true,
            isRowHeader: true

        }]
        return cols;
    }


    //Filter Columns for Filter Device. 
    public getFilterColumns = (): IColumn[] => {
        let azcols: IColumn[] = [
            // {
            //     key: "deviceTypeId",
            //     name: "Device Type",
            //     fieldName: "deviceTypeId",
            //     minWidth: 50,
            //     maxWidth: 150,
            //     isRowHeader: true,
            //     isResizable: true
            // },        
            {
                key: "dataCenter",
                name: "Data Center",
                fieldName: "dataCenter",
                minWidth: 50,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true
            },
            // {
            //     key: "environmentId",
            //     name: "Environment",
            //     fieldName: "environmentId",
            //     minWidth: 50,
            //     maxWidth: 150,
            //     isRowHeader: true,
            //     isResizable: true
            // },
            {
                key: "enclave",
                name: "Enclave",
                fieldName: "enclave",
                minWidth: 50,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true
            },
            // {
            //     key: "state",
            //     name: "State",
            //     fieldName: "state",
            //     minWidth: 50,
            //     maxWidth: 150,
            //     isRowHeader: true,
            //     isResizable: true
            // }
        ]
        return azcols;
    }


    //Additional Columns for Add device, including Azure devices.
    public getAzColumns = (): IColumn[] => {
        let azcols: IColumn[] = [{
            key: "deviceName",
            name: "Device Name",
            fieldName: "deviceName",
            minWidth: 50,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true
        },
        {
            key: "deviceTypeId",
            name: "Device Type",
            fieldName: "deviceTypeId",
            minWidth: 50,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true
        },
        {
            key: "deviceAddressIPv4",
            name: "IP Address",
            fieldName: "deviceAddressIPv4",
            minWidth: 50,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true
        },
        {
            key: "dataCenterId",
            name: "Data Center",
            fieldName: "dataCenterId",
            minWidth: 50,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true
        },
        {
            key: "environmentId",
            name: "Environment",
            fieldName: "environmentId",
            minWidth: 50,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true
        },
        {
            key: "enclave",
            name: "Enclave",
            fieldName: "enclave",
            minWidth: 50,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true
        },
        {
            key: "state",
            name: "State",
            fieldName: "state",
            minWidth: 50,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true
        },
        {
            key: "comment",
            name: "comment",
            fieldName: "comment",
            minWidth: 50,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true

        }]
        return azcols;
    }


    //Columns for Edit Device. Later this will be used every where.
    public getEditColumns = (): IColumn[] => {
        let azcols: IColumn[] = [{
            key: "deviceName",
            name: "Device Name",
            fieldName: "deviceName",
            minWidth: 50,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true
        },
        {
            key: "deviceTypeId",
            name: "Device Type",
            fieldName: "deviceTypeId",
            minWidth: 50,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true
        },
        {
            key: "ipAddress",
            name: "IP Address",
            fieldName: "ipAddress",
            minWidth: 50,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true
        },
        {
            key: "dataCenterId",
            name: "Data Center",
            fieldName: "dataCenterId",
            minWidth: 50,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true
        },
        {
            key: "environmentId",
            name: "Environment",
            fieldName: "environmentId",
            minWidth: 50,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true
        },
        {
            key: "enclave",
            name: "Enclave",
            fieldName: "enclave",
            minWidth: 50,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true
        },
        {
            key: "state",
            name: "State",
            fieldName: "state",
            minWidth: 50,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true
        },
        {
            key: "comment",
            name: "comment",
            fieldName: "comment",
            minWidth: 50,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true

        }]
        return azcols;
    }


    //Columns for Multiple Edit Device. Later this will be used every where.
    public getMultiDeviceEditColumns = (): IColumn[] => {
        let azcols: IColumn[] = [
            {
                key: "deviceTypeId",
                name: "Device Type",
                fieldName: "deviceTypeId",
                minWidth: 50,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true
            },
            {
                key: "dataCenterId",
                name: "Data Center",
                fieldName: "dataCenterId",
                minWidth: 50,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true
            },
            {
                key: "environmentId",
                name: "Environment",
                fieldName: "environmentId",
                minWidth: 50,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true
            },
            {
                key: "enclave",
                name: "Enclave",
                fieldName: "enclave",
                minWidth: 50,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true
            },
            {
                key: "state",
                name: "State",
                fieldName: "state",
                minWidth: 50,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true
            },
            {
                key: "comment",
                name: "comment",
                fieldName: "comment",
                minWidth: 50,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true

            }]
        return azcols;
    }

    public getAzureDcColumns = (): IColumn[] => {
        let azcols: IColumn[] = [{
            key: "azureSubscriptionId",
            name: "Azure Subscription Id",
            fieldName: "azureSubscriptionId",
            minWidth: 50,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true
        },
        {
            key: "azureResourceGroupName",
            name: "AzureResource Group Name",
            fieldName: "azureResourceGroupName",
            minWidth: 50,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true
        },
        {
            key: "azureTenantId",
            name: "Azure Tenant Id",
            fieldName: "azureTenantId",
            minWidth: 50,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true
        }]
        return azcols;
    }


    public updateDisplayPerPage(key: number) {
        this.setState({ top: key, skip: 0, countPerPage: key }, () => {
            this.props.fetchNMSDeviceInventory({
                top: this.state.top,
                skip: this.state.skip,
                serviceTreeId: this.props.session.serviceTree?.serviceTreeId ?? '',
                searchText: this.state.searchText,
                dcSearchText: this.state.dcSearchText,
                enSearchText: this.state.enSearchText
            })
        });
    }

    public pageChangeEvent(pageNumber: number) {

    }


    public activeItemChanged = (item: INetworkDevice) => {
        this.setState((prevState) => {
            let selectedItems = prevState.selectedDevice;
            const index = prevState.selectedDevice.findIndex(t => t.deviceName === item.deviceName);
            if (index === -1) {
                selectedItems.push(item);
                return { selectedDevice: selectedItems }
            }
            return { selectedDevice: prevState.selectedDevice.filter(t => t.deviceName !== item.deviceName) }
        })
    }

    filterDeviceEvent = (ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>): void => {
        if (ev?.detail === 1) {
            this.setState(prev => {
                return {
                    isFilterDevicePanelOpen: true,

                }
            })
        }
        this.props.fetchDataCentersList({ serviceTreeId: this.props.session.serviceTree?.serviceTreeId ?? '' })
        // this.props.fetchDeviceTypeList({ serviceTreeId: this.props.session.serviceTree?.serviceTreeId ?? '' })
        // this.props.fetchEnvironmentsList({ serviceTreeId: this.props.session.serviceTree?.serviceTreeId ?? '' })
    };


    addDeviceEvent = (ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>): void => {
        if (ev?.detail === 1) {
            this.setState(prev => {
                return { isAddDevicePanelOpen: true }
            })
        }
        this.props.fetchDataCentersList({ serviceTreeId: this.props.session.serviceTree?.serviceTreeId ?? '' })
        this.props.fetchDeviceTypeList({ serviceTreeId: this.props.session.serviceTree?.serviceTreeId ?? '' })
        this.props.fetchEnvironmentsList({ serviceTreeId: this.props.session.serviceTree?.serviceTreeId ?? '' })
        this.props.fetchStateList({ serviceTreeId: this.props.session.serviceTree?.serviceTreeId ?? '' })
    };


    editDeviceEvent = (ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>): void => {
        if (ev?.detail === 1) {
            this.setState(prev => {
                return { isEditDevicePanelOpen: true }
            })
        }
        this.props.fetchDataCentersList({ serviceTreeId: this.props.session.serviceTree?.serviceTreeId ?? '' })
        this.props.fetchDeviceTypeList({ serviceTreeId: this.props.session.serviceTree?.serviceTreeId ?? '' })
        this.props.fetchEnvironmentsList({ serviceTreeId: this.props.session.serviceTree?.serviceTreeId ?? '' })
        this.props.fetchStateList({ serviceTreeId: this.props.session.serviceTree?.serviceTreeId ?? '' })
    };


    private showErrorMessageForAddPane = (value: string, key?: string): string => {
        if (key === "deviceAddressIPv4") {
            const updatedDevice = { ...this.state.addDevicePaneData };
            if (updatedDevice.deviceAddressIPv4 != undefined && updatedDevice.deviceAddressIPv4 != '' && updatedDevice.deviceAddressIPv4 && (!/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(updatedDevice.deviceAddressIPv4) && updatedDevice.deviceAddressIPv4 !== "*"))
                return "Source must be valid IP Address or *"
        }
        return ""
    }


    deleteDeviceEvent = (ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>): void => {
        this.state.selectedDevice.length > 0 ?
            this.setState((prevState) => {
                return { isDeleteDevicePanelHidden: false }
            })

            // : this.getDeleteDialog
            : this.setState((prevState) => {
                return { noDeleteDeviceDialogHidden: false }
            })

    };

    private onDismiss = (): void => {
        this.setState((prev) => {
            return { isFilterDevicePanelOpen: false, isAddDevicePanelOpen: false, isEditDevicePanelOpen: false, isFilterButtonDisabled: true, isAddButtonDisabled: true, isEditButtonDisabled: true, isAzureDcSelected: false, filterDevicePaneData: this.initialFilterData, editDevicePaneData: this.initialEditDevice, editMultiDevicePaneData: this.initialEditDevice[0], addDevicePaneData: this.initialAddDevice }
        })
    }

    private onDismissDialog = (): void => {
        this.setState((prev) => {
            return { noDeleteDeviceDialogHidden: true, isDeleteDevicePanelHidden: true }
        })
    }


    private getDeleteDialog(): JSX.Element {
        const modelProps = {
            isBlocking: true,
        };
        const dialogContentProps = {
            type: DialogType.largeHeader,
            title: Resources.DeviceDeleteNoDeviceSelectedTitle,
            subText: Resources.DeviceDeleteNoDeviceSelectedMessage,
        };
        return (
            <>
                <Dialog
                    hidden={this.state.noDeleteDeviceDialogHidden}
                    onDismiss={this.onDismissDialog}
                    dialogContentProps={dialogContentProps}
                    modalProps={modelProps}
                    styles={():IDialogStyles=>{
                        const style:IDialogStyles={
                          root:{
                           position: 'relative'
                          },
                          main:{
                            top:'90px'
                          }
                        }
                        return style
                      }}
                >
                    <DialogFooter>
                        <PrimaryButton onClick={this.onDismissDialog} text="OK" />
                        {/* <DefaultButton onClick={this.onDismissDialog} text="Cancel" /> */}
                    </DialogFooter>
                </Dialog>
            </>
        );
    }


    private getBeforeDeleteDialog(): JSX.Element {
        const modelProps = {
            isBlocking: true,
        };
        const deviceList = this.state.selectedDevice.map(t => t.deviceName)
        const messageText = `${Resources.DeviceDeleteCheckMessage} \ ${deviceList.map(t => `\ ${t}`)} ?`
        const dialogContentProps = {
            type: DialogType.largeHeader,
            title: Resources.DeviceDeleteCheckTitle,
            subText: messageText
        };


        const deviceListcount = this.state.selectedDevice.length
        const messageTextcount = `${Resources.DeviceDeleteMultipleCheckMessage} \ ${deviceListcount} devices?`
        const dialogContentPropsCount = {
            type: DialogType.largeHeader,
            title: Resources.DeviceDeleteMultipleCheckTitle,
            subText: messageText
        };

        return (
            <>
                {this.state.selectedDevice.length > 1 ? <>
                    <Dialog
                        hidden={this.state.isDeleteDevicePanelHidden}
                        onDismiss={this.onDismissDialog}
                        dialogContentProps={dialogContentPropsCount}
                        modalProps={modelProps}
                        styles={():IDialogStyles=>{
                            const style:IDialogStyles={
                              root:{
                               position: 'relative'
                              },
                              main:{
                                top:'90px'
                              }
                            }
                            return style
                          }}
                    >
                        <DialogFooter>
                            <PrimaryButton onClick={this.deleteDevice} text="OK" />
                            <DefaultButton onClick={this.onDismissDialog} text="Cancel" />
                        </DialogFooter>
                    </Dialog>
                </>
                    :
                    this.state.selectedDevice.length > 0 ? <>
                        <Dialog
                            hidden={this.state.isDeleteDevicePanelHidden}
                            onDismiss={this.onDismissDialog}
                            dialogContentProps={dialogContentProps}
                            modalProps={modelProps}
                            styles={():IDialogStyles=>{
                                const style:IDialogStyles={
                                  root:{
                                   position: 'relative'
                                  },
                                  main:{
                                    top:'90px'
                                  }
                                }
                                return style
                              }}
                        >
                            <DialogFooter>
                                <PrimaryButton onClick={this.deleteDevice} text="OK" />
                                <DefaultButton onClick={this.onDismissDialog} text="Cancel" />
                            </DialogFooter>
                        </Dialog>
                    </>
                        : <> </>}
            </>
        );
    }


    private deleteDevice = (): void => {
        // const filteredDevice: any = this.state.selectedDevice[0].deviceId
        // this.props.deleteNMSDeviceDetails({ deviceId: filteredDevice, serviceTreeId: this.props.session.serviceTree?.serviceTreeId ?? '' })
        // const item = this.DeviceSelection.getItems()
        //this.DeviceSelection.setItems(items: item, shouldClear: true)
        this.setState((prevState) => {
            const selDevice: any = prevState.selectedDevice.map(t => t.deviceId)
            this.props.deleteNMSDeviceDetails({ deviceIds: selDevice, serviceTreeId: this.props.session.serviceTree?.serviceTreeId ?? '' })
            return { isDeleteDevicePanelHidden: true, skip: 0, }
        },

            () => {
                this.props.fetchNMSDeviceInventory({
                    top: this.state.top,
                    skip: this.state.skip,
                    serviceTreeId: this.props.session.serviceTree?.serviceTreeId ?? '',
                    searchText: this.state.searchText,
                    dcSearchText: this.state.dcSearchText,
                    enSearchText: this.state.enSearchText
                })
            }
        )
        // this.reloadInventory()
        // this.setState((prevState) => {
        //     return { noDeleteDeviceDialogHidden: true }
        // })
    };

    private getFilterDevicePanel(): React.ReactElement[] {
        const items: React.ReactElement[] = [];
        this.getFilterColumns().map((t, index) => {
            if (t.name == 'Data Center') {
                items.push(
                    <Stack.Item key={`stack-item-add-${index}`}>
                        <>
                            {this.getDataCenterDropDownFilter()}
                        </>
                    </Stack.Item>)
            }

            else {
                items.push(
                    <Stack.Item key={`stack-item-add-${index}`}>
                        <TextField key={`text-field-item-add-${index}`} label={t.name} onChange={(event: Maybe<React.FormEvent<HTMLInputElement | HTMLTextAreaElement>>): void => this.onChangeFilterDeviceDetail(event, t.fieldName,)}
                            onGetErrorMessage={(value: string): string => this.showErrorMessageForAddPane(value, t.fieldName)}
                            defaultValue={t.fieldName != undefined ? this.getFilterDeviceData()[t.fieldName] : ''}
                        />
                    </Stack.Item>)
            }
        })
        return items;
    }


    //this is display in pannel
    private getFilterDeviceData = (): INMSDeviceFilterTypes => {
        //this.setState((prevState) => {
        const seldDevice = this.state.filterDevicePaneData;
        const editData: INMSDeviceFilterTypes = {
            dataCenter: seldDevice.dataCenter,
            enclave: seldDevice.enclave,
        }
        return editData

    }


    private getAddDevicePanel(): React.ReactElement[] {
        const items: React.ReactElement[] = [];
        this.getAzColumns().map((t, index) => {
            if (t.name == 'Data Center') {
                items.push(
                    <Stack.Item key={`stack-item-add-${index}`}>
                        <>
                            {this.getDataCenterDropDown()}
                        </>
                    </Stack.Item>)
            }
            else if (t.name == 'Device Type') {
                items.push(
                    <Stack.Item key={`stack-item-add-${index}`}>
                        <>
                            {this.getDeviceTypeDropDown()}
                        </>
                    </Stack.Item>)
            }
            else if (t.name == 'Environment') {
                items.push(
                    <Stack.Item key={`stack-item-add-${index}`}>
                        <>
                            {this.getEnvironmentsDropDown()}
                        </>
                    </Stack.Item>)
            }
            else if (t.name == 'State') {
                items.push(
                    <Stack.Item key={`stack-item-add-${index}`}>
                        <>
                            {this.getStateDropDownAddOption()}
                        </>
                    </Stack.Item>)
            }
            else if (t.name == 'comment') {
                items.push(
                    <Stack.Item key={`stack-item-add-${index}`}>
                        <>
                            <TextField key={`text-field-item-add-${index}`} label={t.name} onChange={(event: Maybe<React.FormEvent<HTMLInputElement | HTMLTextAreaElement>>): void => this.onChangeUpdateDeviceDetail(event, t.fieldName,)}
                                onGetErrorMessage={(value: string): string => this.showErrorMessageForAddPane(value, t.fieldName)} />
                        </>
                    </Stack.Item>)
            }
            else {
                items.push(
                    <Stack.Item key={`stack-item-add-${index}`}>
                        <TextField key={`text-field-item-add-${index}`} label={t.name} required onChange={(event: Maybe<React.FormEvent<HTMLInputElement | HTMLTextAreaElement>>): void => this.onChangeUpdateDeviceDetail(event, t.fieldName,)}
                            onGetErrorMessage={(value: string): string => this.showErrorMessageForAddPane(value, t.fieldName)} />
                    </Stack.Item>)
            }
        })

        if (this.state.isAzureDcSelected == true) {
            this.getAzureDcColumns().map((t, index) => {
                {
                    items.push(
                        <Stack.Item key={`stack-item-add-${index}`}>
                            <TextField key={`text-field-item-add-${index}`} label={t.name} onChange={(event: Maybe<React.FormEvent<HTMLInputElement | HTMLTextAreaElement>>): void => this.onChangeUpdateDeviceDetail(event, t.fieldName,)} />
                        </Stack.Item>)
                }
            })
        }
        return items;
    }

    //This is for the actual Edit. Adding value in State
    private onEditPaneOpened = (): void => {
        this.setState((prevState) => {
            const selectDevice: INetworkDevice = { ...prevState.selectedDevice[0] };
            const editData: INMSDeviceDetailsEditRequest = {
                deviceName: selectDevice.deviceName,
                deviceTypeId: selectDevice.deviceTypeId,
                dataCenterId: selectDevice.dataCenterId,
                ipAddress: selectDevice.ipAddress,
                environmentId: selectDevice.environmentId,
                enclave: selectDevice.enclave,
                azureSubscriptionId: selectDevice.azureSubscriptionId,
                azureResourceGroupName: selectDevice.azureResourceGroupName,
                serviceTreeId: this.props.session.serviceTree?.serviceTreeId ?? '',
                deviceId: selectDevice.deviceId,
                comment: selectDevice.comment,
                azureTenantId: selectDevice.azureTenantId,
                state: selectDevice.state,
            }
            if (editData.azureResourceGroupName == ('' || null)) { editData.azureResourceGroupName = 'Null' };
            if (editData.azureResourceGroupName?.length == 0) { editData.azureResourceGroupName = 'Null' };
            if (editData.azureSubscriptionId == ('' || null)) { editData.azureSubscriptionId = 'Null' };
            if (editData.azureSubscriptionId?.length == 0) { editData.azureSubscriptionId = 'Null' };
            if (editData.azureTenantId == ('' || null)) { editData.azureTenantId = 'Null' };
            if (editData.azureTenantId?.length == 0) { editData.azureTenantId = 'Null' };
            if (editData.comment == ('' || null || ' ')) { editData.comment = 'Null' };
            if (editData.comment?.length == 0) { editData.comment = 'Null' };
            if (editData.dataCenterId == "66461099-3647-45b3-136c-08da97f3d861") { this.setState({ isAzureDcSelected: true }) };

            return { editDevicePaneData: editData }
        })
        this.onMultiEditPaneOpened()
    }

    //this is display in pannel
    private getSelectedDeviceToEdit = (): INMSDeviceDetailsEditRequest => {
        //this.setState((prevState) => {
        const seldDevice: INetworkDevice = this.state.selectedDevice[0];
        const editData: INMSDeviceDetailsEditRequest = {
            deviceName: seldDevice.deviceName,
            deviceTypeId: seldDevice.deviceTypeId,
            dataCenterId: seldDevice.dataCenterId,
            ipAddress: seldDevice.ipAddress,
            environmentId: seldDevice.environmentId,
            enclave: seldDevice.enclave,
            azureSubscriptionId: seldDevice.azureSubscriptionId,
            azureResourceGroupName: seldDevice.azureResourceGroupName,
            serviceTreeId: this.props.session.serviceTree?.serviceTreeId ?? '',
            deviceId: seldDevice.deviceId,
            comment: seldDevice.comment,
            azureTenantId: seldDevice.azureTenantId,
            state: seldDevice.state,
        }
        return editData

    }


    private getEditDevicePanel(): React.ReactElement[] {
        const items: React.ReactElement[] = [];
        this.state.editDevicePaneData.deviceName.length > 0 &&
            this.getEditColumns().map((t, index) => {
                if (t.name == 'Data Center') {
                    items.push(
                        <Stack.Item key={`stack-item-add-${index}`}>
                            <>
                                {/* {selectDevice = this.state.selectedDevice[0].dataCenterId} */}
                                {this.getDataCenterDropDown()}
                            </>
                        </Stack.Item>)
                }
                else if (t.name == 'Device Type') {
                    items.push(
                        <Stack.Item key={`stack-item-add-${index}`}>
                            <>

                                {this.getDeviceTypeDropDown()} {/* //Generate the Selected device with key value pair?? this.state.selectedDevice[0].deviceType.S */}
                            </>
                        </Stack.Item>)
                }
                else if (t.name == 'Environment') {
                    items.push(
                        <Stack.Item key={`stack-item-add-${index}`}>
                            <>
                                {this.getEnvironmentsDropDown()}
                            </>
                        </Stack.Item>)
                }
                else if (t.name == 'State') {
                    items.push(
                        <Stack.Item key={`stack-item-add-${index}`}>
                            <>
                                {this.getStateDropDown()}
                            </>
                        </Stack.Item>)
                }
                else if (t.name == 'comment') {
                    items.push(
                        <Stack.Item key={`stack-item-add-${index}`}>
                            <TextField key={`text-field-item-add-${index}`} label={t.name} onChange={(event: Maybe<React.FormEvent<HTMLInputElement | HTMLTextAreaElement>>): void =>
                                this.onChangeMultiEditDeviceDetail(event, t.fieldName,)}
                                defaultValue={t.fieldName != undefined ? this.getSelectedDeviceToEdit()[t.fieldName] : ''}
                                onGetErrorMessage={(value: string): string => this.showErrorMessageForAddPane(value, t.fieldName)}
                            />
                        </Stack.Item>)
                }
                else {
                    items.push(
                        <Stack.Item key={`stack-item-add-${index}`}>
                            <TextField key={`text-field-item-add-${index}`} label={t.name} required onChange={(event: Maybe<React.FormEvent<HTMLInputElement | HTMLTextAreaElement>>): void =>
                                this.onChangeMultiEditDeviceDetail(event, t.fieldName,)}
                                defaultValue={t.fieldName != undefined ? this.getSelectedDeviceToEdit()[t.fieldName] : ''}
                                onGetErrorMessage={(value: string): string => this.showErrorMessageForAddPane(value, t.fieldName)}
                            />
                        </Stack.Item>)

                }
            })

        if (this.state.isAzureDcSelected == true) {
            this.getAzureDcColumns().map((t, index) => {
                {
                    items.push(
                        <Stack.Item key={`stack-item-add-${index}`}>
                            <TextField key={`text-field-item-add-${index}`} label={t.name} required onChange={(event: Maybe<React.FormEvent<HTMLInputElement | HTMLTextAreaElement>>): void =>
                                this.onChangeMultiEditDeviceDetail(event, t.fieldName,)}
                                defaultValue={t.fieldName != undefined ? this.getSelectedDeviceToEdit()[t.fieldName] : ''}
                                onGetErrorMessage={(value: string): string => this.showErrorMessageForAddPane(value, t.fieldName)}
                            />
                        </Stack.Item>)
                }
            })
        }
        return items;
    }


    private onMultiEditPaneOpened = (): void => {
        // this.onEditPaneOpened
        this.setState((prevState) => {
            const selectDevice: INetworkDevice[] = this.state.selectedDevice;
            const editData: INMSDeviceDetailsEditRequest[] = selectDevice.map(t => {
                const editedData = {
                    deviceName: t.deviceName,
                    deviceTypeId: t.deviceTypeId,
                    dataCenterId: t.dataCenterId,
                    ipAddress: t.ipAddress,
                    environmentId: t.environmentId,
                    enclave: t.enclave,
                    azureSubscriptionId: t.azureSubscriptionId,
                    azureResourceGroupName: t.azureResourceGroupName,
                    serviceTreeId: this.props.session.serviceTree?.serviceTreeId ?? '',
                    deviceId: t.deviceId,
                    comment: t.comment,
                    azureTenantId: t.azureTenantId,
                    state: t.state,
                }
                return editedData
            })

            return { editMultiDevicePaneData: editData }
        })
    }


    private getMultiEditDevicePanel(): React.ReactElement[] {
        const items: React.ReactElement[] = [];
        this.state.editDevicePaneData.deviceName.length > 0 &&
            // this.state.editMultiDevicePaneData.length > 0 &&
            this.getMultiDeviceEditColumns().map((t, index) => {
                if (t.name == 'Data Center') {
                    items.push(
                        <Stack.Item key={`stack-item-add-${index}`}>
                            <>
                                {/* {this.getDataCenterDropDown()} */}
                                {this.getDataCenterDropDownMultiEdit()}
                            </>
                        </Stack.Item>)
                }
                else if (t.name == 'Device Type') {
                    items.push(
                        <Stack.Item key={`stack-item-add-${index}`}>
                            <>
                                {this.getDeviceTypeDropDownMultiEdit()}
                            </>
                        </Stack.Item>)
                }
                else if (t.name == 'Environment') {
                    items.push(
                        <Stack.Item key={`stack-item-add-${index}`}>
                            <>
                                {/* {this.getEnvironmentsDropDown()} */}
                                {this.getEnvironmentsDropDownMultiEdit()}
                            </>
                        </Stack.Item>)
                }
                else if (t.name == 'State') {
                    items.push(
                        <Stack.Item key={`stack-item-add-${index}`}>
                            <>
                                {/* {this.getEnvironmentsDropDown()} */}
                                {this.getStateDropDownMultiEdit()}
                            </>
                        </Stack.Item>)
                }
                else if (t.name == 'comment') {
                    items.push(
                        <Stack.Item key={`stack-item-add-${index}`}>
                            <TextField key={`text-field-item-add-${index}`} label={t.name} onChange={(event: Maybe<React.FormEvent<HTMLInputElement | HTMLTextAreaElement>>): void =>
                                this.onChangeMultiEditDeviceDetail(event, t.fieldName,)}
                                onGetErrorMessage={(value: string): string => this.showErrorMessageForAddPane(value, t.fieldName)}
                            />
                        </Stack.Item>)
                }
                else {
                    items.push(
                        <Stack.Item key={`stack-item-add-${index}`}>
                            <TextField key={`text-field-item-add-${index}`} label={t.name} onChange={(event: Maybe<React.FormEvent<HTMLInputElement | HTMLTextAreaElement>>): void =>
                                this.onChangeMultiEditDeviceDetail(event, t.fieldName,)}
                                onGetErrorMessage={(value: string): string => this.showErrorMessageForAddPane(value, t.fieldName)}
                            />
                        </Stack.Item>)

                }
            })

        // if (this.state.isAzureDcSelected == true) {
        //     this.getAzureDcColumns().map((t, index) => {
        //         {
        //             items.push(
        //                 <Stack.Item key={`stack-item-add-${index}`}>
        //                     <TextField key={`text-field-item-add-${index}`} label={t.name} required onChange={(event: Maybe<React.FormEvent<HTMLInputElement | HTMLTextAreaElement>>): void =>
        //                         this.onChangeEditDeviceDetail(event, t.fieldName,)}
        //                         defaultValue={t.fieldName != undefined ? this.getMultiSelectedDeviceToEdit()[t.fieldName] : ''}
        //                         onGetErrorMessage={(value: string): string => this.showErrorMessageForAddPane(value, t.fieldName)}
        //                     />
        //                 </Stack.Item>)
        //         }
        //     })
        // }
        return items;
    }


    private getData(top?: number, skip?: number): void {
        this.setState((prevState) => {
            return { top: top, skip: skip }
        }
            , () => {
                this.props.fetchNMSDeviceInventory({
                    top: this.state.top,
                    skip: this.state.skip,
                    serviceTreeId: this.props.session.serviceTree?.serviceTreeId ?? '',
                    searchText: this.state.searchText,
                    dcSearchText: this.state.dcSearchText,
                    enSearchText: this.state.enSearchText
                })
            }
        );
    }


    private onChangeFilterDeviceDetail = (event: Maybe<React.FormEvent<HTMLInputElement | HTMLTextAreaElement | HTMLDivElement>>, key?: string, item?: IDropdownOption): void => {
        if (key) {
            const filterDeviceDetail = this.state.filterDevicePaneData; //?? {...this.state.filterdDevicePaneData}; 
            if (item) {
                filterDeviceDetail[key] = item?.text;
            }
            else {
                filterDeviceDetail[key] = (event?.target as HTMLTextAreaElement).value;
            }
            const isFilterButtonDisabled = Object.keys(this.state.filterDevicePaneData).some(t =>
                this.state.filterDevicePaneData[t] > 0);
            this.setState({ filterDevicePaneData: filterDeviceDetail, isFilterButtonDisabled: isFilterButtonDisabled });
        }
    }


    private onChangeUpdateDeviceDetail = (event: Maybe<React.FormEvent<HTMLInputElement | HTMLTextAreaElement | HTMLDivElement>>, key?: string, item?: IDropdownOption): void => {
        if (key) {
            const updatedDeviceDetail = this.state.addDevicePaneData; //?? {...this.state.addDevicePaneData}; 
            if (item) {
                updatedDeviceDetail[key] = item?.key;
            }
            else {
                updatedDeviceDetail[key] = (event?.target as HTMLTextAreaElement).value;
            }
            const isAddButtonDisabled = Object.keys(this.state.addDevicePaneData).some(t =>
                this.state.addDevicePaneData[t] == '');
            this.setState({ addDevicePaneData: updatedDeviceDetail, isAddButtonDisabled: isAddButtonDisabled });
        }
    }


    private onChangeEditDeviceDetail = (event: Maybe<React.FormEvent<HTMLInputElement | HTMLTextAreaElement | HTMLDivElement>>, key?: string, item?: IDropdownOption): void => {
        if (key) {
            const editedDeviceDetail = this.state.editDevicePaneData; //?? {...this.state.addDevicePaneData}; 
            if (item) {
                editedDeviceDetail[key] = item?.key;
            }
            else {
                editedDeviceDetail[key] = (event?.target as HTMLTextAreaElement).value;
            }
            const isEditButtonDisabled = Object.keys(this.state.editDevicePaneData).some(t =>
                this.state.editDevicePaneData[t] == '');
            this.setState({ editDevicePaneData: editedDeviceDetail, isEditButtonDisabled: isEditButtonDisabled });
        }
    }


    private onChangeMultiEditDeviceDetail = (event: Maybe<React.FormEvent<HTMLInputElement | HTMLTextAreaElement | HTMLDivElement>>, key?: string, item?: IDropdownOption): void => {
        if (key) {
            const editedDeviceDetail = this.state.editMultiDevicePaneData; //?? {...this.state.addDevicePaneData}; 
            if (item) {
                editedDeviceDetail[key] = editedDeviceDetail.map(t => t[key] = item?.key);
            }
            else {
                editedDeviceDetail[key] = editedDeviceDetail.map(t => t[key] = (event?.target as HTMLTextAreaElement).value);
            }
            const isEditButtonDisabled = Object.keys(this.state.editDevicePaneData).some(t =>
                this.state.editDevicePaneData[t] == '');
            this.setState({ editMultiDevicePaneData: editedDeviceDetail, isEditButtonDisabled: isEditButtonDisabled });
        }
    }


    private getCommandBarItems(): ICommandBarItemProps[] {
        return [
            {
                id: 'inventoryPage-filterDevice',
                key: 'filter',
                role: 'menuitem',
                // ariaLabel: filterLabel,
                title: "Filter",
                iconProps: { iconName: 'Filter' },
                buttonStyles: { root: { backgroundColor: 'transparent' } },
                // disabled: disabledFirewall || !((this.state.filters?.length ?? -1) > 0),
                onClick: this.filterDeviceEvent,
            },
            {
                id: 'inventoryPage-addDevice',
                key: 'addDevice',
                role: 'menuitem',
                // ariaLabel: filterLabel,
                title: "Add",
                iconProps: { iconName: 'BoxAdditionSolid' },
                buttonStyles: { root: { backgroundColor: 'transparent' } },
                // disabled: disabledFirewall || !((this.state.filters?.length ?? -1) > 0),
                onClick: this.addDeviceEvent,
            },
            {
                id: 'inventoryPage-editDevice',
                key: 'editDevice',
                role: 'menuitem',
                // ariaLabel: filterLabel,
                title: "Edit",
                iconProps: { iconName: 'Edit' },
                buttonStyles: { root: { backgroundColor: 'transparent' } },
                // disabled: disabledFirewall || !((this.state.filters?.length ?? -1) > 0),
                onClick: this.editDeviceEvent,
            },
            {
                id: 'inventoryPage-deleteDevice',
                key: 'deletePolicy',
                role: 'menuitem',
                //ariaLabel: "filterLabel",
                title: "Delete",
                buttonStyles: { root: { backgroundColor: 'transparent' } },
                iconProps: { iconName: 'Delete' },
                // disabled: disabledFirewall || !((this.state.filters?.length ?? -1) > 0) || !(this.state.selectedPolicy.length > 0),
                onClick: this.deleteDeviceEvent,
            },
            // {
            //     id: 'inventoryPage-home',
            //     key: 'home',
            //     role: 'menuitem',
            //     // ariaLabel: filterLabel,
            //     title: "Home",
            //     iconProps: { iconName: 'home' },
            //     buttonStyles: { root: { backgroundColor: 'transparent' } },
            //     // disabled: disabledFirewall || !((this.state.filters?.length ?? -1) > 0),
            //     onClick: this.clearFilter,

            // },
        ];
    }

    private getDataCenterDropDown = (): React.ReactElement[] => {
        const filterItems: React.ReactElement[] = [];
        const filterDetails = this.props.deviceInventoryDetailsState?.dataCentersList?.map((t: IDataCentersDetails) => {
            return ({
                key: t.dataCenterId,
                text: t.dataCenterShortName,
            })
        })
        filterItems.push(
            <Dropdown
                label={'Data Center'}
                key={'Data Center'}
                styles={{
                    root: { width: '150' }
                }}
                options={filterDetails ?? []}
                required onChange={(event: Maybe<React.FormEvent<HTMLDivElement>>, item?: IDropdownOption): void => {
                    if (this.state.isAddDevicePanelOpen == true) {
                        this.onChangeUpdateDeviceDetail(event, 'dataCenterId', item)
                        if (item?.text == "AZURE") {
                            this.setState({ isAzureDcSelected: true })
                        }
                        else {
                            this.setState({ isAzureDcSelected: false })
                        }
                    }
                    else if (this.state.isEditDevicePanelOpen == true) {
                        this.onChangeEditDeviceDetail(event, 'dataCenterId', item)
                        this.onChangeMultiEditDeviceDetail(event, 'dataCenterId', item)
                        if (item?.text == "AZURE") {
                            this.setState({ isAzureDcSelected: true })
                        }
                        else {
                            this.setState({ isAzureDcSelected: false })
                        }
                    }
                }}

                defaultSelectedKey={this.state.editDevicePaneData?.dataCenterId}
                role="combobox"
            />
        )
        return filterItems;
    }

    //For Multi Edit
    private getDataCenterDropDownMultiEdit = (): React.ReactElement[] => {
        const filterItems: React.ReactElement[] = [];
        const filterDetails = this.props.deviceInventoryDetailsState?.dataCentersList?.map((t: IDataCentersDetails) => {
            return ({
                key: t.dataCenterId,
                text: t.dataCenterShortName,
            })
        })
        filterItems.push(
            <Dropdown
                label={'Data Center'}
                key={'Data Center'}
                styles={{
                    root: { width: '150' }
                }}
                options={filterDetails ?? []}
                onChange={(event: Maybe<React.FormEvent<HTMLDivElement>>, item?: IDropdownOption): void => {
                    this.onChangeMultiEditDeviceDetail(event, 'dataCenterId', item)
                }}
                role="combobox"
            />
        )
        return filterItems;
    }


    private getDataCenterDropDownFilter = (): React.ReactElement[] => {
        const filterItems: React.ReactElement[] = [];
        const filterDetails = this.props.deviceInventoryDetailsState?.dataCentersList?.map((t: IDataCentersDetails) => {
            return ({
                key: t.dataCenterId,
                text: t.dataCenterShortName,
            })
        })
        filterItems.push(
            <Dropdown
                label={'Data Center'}
                key={'Data Center'}
                styles={{ root: { width: '150' } }}
                options={filterDetails ?? []}
                onChange={(event: Maybe<React.FormEvent<HTMLDivElement>>, item?: IDropdownOption): void => {
                    this.onChangeFilterDeviceDetail(event, 'dataCenter', item)
                }}

                role="combobox"
            />
        )
        return filterItems;
    }


    private getDeviceTypeDropDown = (): React.ReactElement[] => {
        const filterItems: React.ReactElement[] = [];
        const filterDetails = this.props.deviceInventoryDetailsState?.deviceTypeList?.map((t: IDeviceTypeDetails) => {
            return ({
                key: t.deviceTypeId,
                text: t.deviceTypeName,
            })
        })
        filterItems.push(
            <Dropdown
                label={'Device Type'}
                styles={{
                    root: { width: '150' }
                }}
                key={'Device Type'}
                options={filterDetails ?? []}
                required onChange={(event: Maybe<React.FormEvent<HTMLDivElement>>, item?: IDropdownOption): void => {
                    if (this.state.isAddDevicePanelOpen == true) {
                        this.onChangeUpdateDeviceDetail(event, 'deviceTypeId', item)
                    }
                    else if (this.state.isEditDevicePanelOpen == true) {
                        this.onChangeEditDeviceDetail(event, 'deviceTypeId', item)
                        this.onChangeMultiEditDeviceDetail(event, 'deviceTypeId', item)
                    }
                }}
                defaultSelectedKey={this.state.editDevicePaneData.deviceTypeId}
                role="combobox"
            />
        )
        return filterItems;
    }

    //This is for MUlti Edit:
    private getDeviceTypeDropDownMultiEdit = (): React.ReactElement[] => {
        const filterItems: React.ReactElement[] = [];
        const filterDetails = this.props.deviceInventoryDetailsState?.deviceTypeList?.map((t: IDeviceTypeDetails) => {
            return ({
                key: t.deviceTypeId,
                text: t.deviceTypeName,
            })
        })
        filterItems.push(
            <Dropdown
                label={'Device Type'}
                styles={{
                    root: { width: '150' }
                }}
                key={'Device Type'}
                options={filterDetails ?? []}
                onChange={(event: Maybe<React.FormEvent<HTMLDivElement>>, item?: IDropdownOption): void => {
                    this.onChangeMultiEditDeviceDetail(event, 'deviceTypeId', item)
                }}
                role="combobox"
            />
        )
        return filterItems;
    }


    private getEnvironmentsDropDown = (): React.ReactElement[] => {
        const filterItems: React.ReactElement[] = [];
        const filterDetails = this.props.deviceInventoryDetailsState?.environmentsList?.map((t: IEnvironmentsDetails) => {
            return ({
                key: t.environmentId,
                text: t.environmentName,
            })
        })
        filterItems.push(
            <Dropdown
                label={'Environment'}
                key={'Environment'}
                styles={{
                    root: { width: '150' }
                }}
                options={filterDetails ?? []}
                required onChange={(event: Maybe<React.FormEvent<HTMLDivElement>>, item?: IDropdownOption): void => {
                    if (this.state.isAddDevicePanelOpen == true) {
                        this.onChangeUpdateDeviceDetail(event, 'environmentId', item)
                    }
                    else if (this.state.isEditDevicePanelOpen == true) {
                        this.onChangeEditDeviceDetail(event, 'environmentId', item)
                        this.onChangeMultiEditDeviceDetail(event, 'environmentId', item)
                    }
                }}
                defaultSelectedKey={this.state.editDevicePaneData.environmentId}
                role="combobox"
            />
        )
        return filterItems;
    }

    //Multi Edit Device
    private getEnvironmentsDropDownMultiEdit = (): React.ReactElement[] => {
        const filterItems: React.ReactElement[] = [];
        const filterDetails = this.props.deviceInventoryDetailsState?.environmentsList?.map((t: IEnvironmentsDetails) => {
            return ({
                key: t.environmentId,
                text: t.environmentName,
            })
        })
        filterItems.push(
            <Dropdown
                label={'Environment'}
                key={'Environment'}
                styles={{
                    root: { width: '150' }
                }}
                options={filterDetails ?? []}
                onChange={(event: Maybe<React.FormEvent<HTMLDivElement>>, item?: IDropdownOption): void => {

                    this.onChangeMultiEditDeviceDetail(event, 'environmentId', item)
                }}
                role="combobox"
            />
        )
        return filterItems;
    }


    //State Dropdown for add.
    private getStateDropDownAddOption = (): React.ReactElement[] => {
        const filterItems: React.ReactElement[] = [];
        const filterDetails = this.props.deviceInventoryDetailsState?.stateList?.map((t: IStateDetails) => {
            return ({
                key: t.stateName,
                text: t.stateName,
            })
        })
        filterItems.push(
            <Dropdown
                label={'State'}
                key={'State'}
                styles={{
                    root: { width: '150' }
                }}
                options={filterDetails ?? []}
                required onChange={(event: Maybe<React.FormEvent<HTMLDivElement>>, item?: IDropdownOption): void => {
                    this.onChangeUpdateDeviceDetail(event, 'state', item)  //Send name instead of ID                  
                }}
                role="combobox"
            />
        )
        return filterItems;
    }

    //
    private getStateDropDown = (): React.ReactElement[] => {
        const filterItems: React.ReactElement[] = [];
        const filterDetails = this.props.deviceInventoryDetailsState?.stateList?.map((t: IStateDetails) => {
            return ({
                key: t.stateName,
                text: t.stateName,
            })
        })
        filterItems.push(
            <Dropdown
                label={'State'}
                key={'State'}
                styles={{
                    root: { width: '150' }
                }}
                options={filterDetails ?? []}
                required onChange={(event: Maybe<React.FormEvent<HTMLDivElement>>, item?: IDropdownOption): void => {
                    if (this.state.isAddDevicePanelOpen == true) {
                        this.onChangeUpdateDeviceDetail(event, 'state', item)  //Send name instead of ID
                    }
                    else if (this.state.isEditDevicePanelOpen == true) {
                        this.onChangeEditDeviceDetail(event, 'state', item) //Send name instead of ID
                        this.onChangeMultiEditDeviceDetail(event, 'state', item) //Send name instead of ID
                    }
                }}
                defaultSelectedKey={this.state.editDevicePaneData.state}
                role="combobox"
            />
        )
        return filterItems;
    }

    //Multi Edit Device
    private getStateDropDownMultiEdit = (): React.ReactElement[] => {
        const filterItems: React.ReactElement[] = [];
        const filterDetails = this.props.deviceInventoryDetailsState?.stateList?.map((t: IStateDetails) => {
            return ({
                key: t.stateName,
                text: t.stateName,
            })
        })
        filterItems.push(
            <Dropdown
                label={'State'}
                key={'State'}
                styles={{
                    root: { width: '150' }
                }}
                options={filterDetails ?? []}
                onChange={(event: Maybe<React.FormEvent<HTMLDivElement>>, item?: IDropdownOption): void => {

                    this.onChangeMultiEditDeviceDetail(event, 'stateName', item)
                }}
                role="combobox"
            />
        )
        return filterItems;
    }


    //Filter Items based on the input provided in Filter Panel
    private filterItem = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement, MouseEvent>): void => {
        const filterRequest: INMSDeviceFilterTypes = {
            dataCenter: this.state.filterDevicePaneData.dataCenter,
            enclave: this.state.filterDevicePaneData.enclave,
        }

        this.setState({
            deviceAdded: [],
            deviceEdited: [],
            deviceRemoved: [],
            device: [],
            filterDevicePaneData: this.initialFilterData,
            isAzureDcSelected: false,
            isFilterDevicePanelOpen: false,
            isFilterButtonDisabled: true,
            isFilterApplied: true,
            // filters: [{key: 'Data Center' , values: [{key: 'Data Center Key', displayName: 'fil'} ]}],
            searchText: undefined,
            dcSearchText: filterRequest.dataCenter,
            enSearchText: filterRequest.enclave

        },
            () => this.props.fetchNMSDeviceInventory({
                top: this.state.top,
                skip: this.state.skip,
                serviceTreeId: this.props.session.serviceTree?.serviceTreeId ?? '',
                searchText: this.state.searchText,
                dcSearchText: this.state.dcSearchText,
                enSearchText: this.state.enSearchText
            })
        )
        //*****Trigger One function to show the applied filter */
    }

    public getFilterTag = (): JSX.Element => {
        const filterValue = () => {
            let val = `DC: ${this.state.dcSearchText} & Enclave: ${this.state.enSearchText}`
            return (val)
        }
        return (
            <div>
                <Icon iconName='FilterIcon' style={{ fontSize: '1.5rem' }}></Icon>
                {filterValue}
            </div>
        )
    }


    private clearFilter = (): void => {
        this.setState({
            deviceAdded: [],
            deviceEdited: [],
            deviceRemoved: [],
            device: [],
            isAzureDcSelected: false,
            isFilterDevicePanelOpen: false,
            isFilterButtonDisabled: true,
            isFilterApplied: false,
            searchText: undefined,
            dcSearchText: '',
            enSearchText: '',
            filterDevicePaneData: this.initialFilterData,
            skip: 0,
        },

            () => this.props.fetchNMSDeviceInventory({
                top: this.state.top,
                skip: this.state.skip,
                serviceTreeId: this.props.session.serviceTree?.serviceTreeId ?? '',
                searchText: this.state.searchText,
                dcSearchText: this.state.dcSearchText,
                enSearchText: this.state.enSearchText
            })
        )
        //*****Trigger One function to show the applied filter */        
    }


    private saveItem = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement, MouseEvent>): void => {
        const updateRequest: INMSDeviceDetailsUpdateRequest = {
            deviceName: this.state.addDevicePaneData.deviceName,
            deviceAddressIPv4: this.state.addDevicePaneData.deviceAddressIPv4,
            deviceTypeId: this.state.addDevicePaneData.deviceTypeId,
            dataCenterId: this.state.addDevicePaneData.dataCenterId,
            environmentId: this.state.addDevicePaneData.environmentId,
            enclave: this.state.addDevicePaneData.enclave,
            azureSubscriptionId: this.state.addDevicePaneData.azureSubscriptionId,
            azureResourceGroupName: this.state.addDevicePaneData.azureResourceGroupName,
            serviceTreeId: this.props.session.serviceTree?.serviceTreeId ?? '',
            comment: this.state.addDevicePaneData.comment,
            azureTenantId: this.state.addDevicePaneData.azureTenantId,
            state: this.state.addDevicePaneData.state,
        }
        this.props.updateNMSDeviceDetails({ ...updateRequest });

        this.setState({
            deviceAdded: [],
            deviceEdited: [],
            deviceRemoved: [],
            device: [],
            addDevicePaneData: this.initialAddDevice,
            isAzureDcSelected: false,
            isAddDevicePanelOpen: false,
            isAddButtonDisabled: true,
            filters: [],
            searchText: undefined
        },
            () => this.props.fetchNMSDeviceInventory({
                top: this.state.top,
                skip: this.state.skip,
                serviceTreeId: this.props.session.serviceTree?.serviceTreeId ?? '',
                searchText: this.state.searchText,
                dcSearchText: this.state.dcSearchText,
                enSearchText: this.state.enSearchText
            })
        )
    }


    private editSaveItem = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement, MouseEvent>): void => {
        const editRequest: INMSDeviceDetailsEditRequest = {
            deviceName: this.state.editDevicePaneData.deviceName,
            ipAddress: this.state.editDevicePaneData.ipAddress,
            deviceTypeId: this.state.editDevicePaneData.deviceTypeId,
            dataCenterId: this.state.editDevicePaneData.dataCenterId,
            environmentId: this.state.editDevicePaneData.environmentId,
            enclave: this.state.editDevicePaneData.enclave,
            azureSubscriptionId: this.state.editDevicePaneData.azureSubscriptionId,
            azureResourceGroupName: this.state.editDevicePaneData.azureResourceGroupName,
            serviceTreeId: this.props.session.serviceTree?.serviceTreeId ?? '',
            deviceId: this.state.editDevicePaneData.deviceId,
            comment: this.state.editDevicePaneData.comment,
            azureTenantId: this.state.editDevicePaneData.azureTenantId,
            state: this.state.editDevicePaneData.state,
        }

        const editRequestData: INMSMultiDeviceEditRequest = { deviceRequests: editRequest[0], serviceTreeId: this.props.session.serviceTree?.serviceTreeId ?? '', }

        this.props.editNMSDeviceDetails(editRequestData);


        this.setState({
            deviceAdded: [],
            deviceEdited: [],
            deviceRemoved: [],
            device: [],
            editDevicePaneData: this.initialEditDevice,
            isAzureDcSelected: false,
            isAddDevicePanelOpen: false,
            isEditDevicePanelOpen: false,
            isAddButtonDisabled: true,
            isEditButtonDisabled: true,
            filters: [],
            searchText: undefined
        },
            () => this.props.fetchNMSDeviceInventory({
                top: this.state.top,
                skip: this.state.skip,
                serviceTreeId: this.props.session.serviceTree?.serviceTreeId ?? '',
                searchText: this.state.searchText,
                dcSearchText: this.state.dcSearchText,
                enSearchText: this.state.enSearchText
            })
        )
    }

    private multiEditSaveItem = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement, MouseEvent>): void => {
        const editRequest: INMSDeviceDetailsEditRequest[] = this.state.editMultiDevicePaneData

        const editRequestData: INMSMultiDeviceEditRequest = { deviceRequests: this.state.editMultiDevicePaneData, serviceTreeId: this.props.session.serviceTree?.serviceTreeId ?? '', }

        this.props.editNMSDeviceDetails(editRequestData);

        this.setState({
            deviceAdded: [],
            deviceEdited: [],
            deviceRemoved: [],
            device: [],
            editDevicePaneData: this.initialEditDevice,
            isAzureDcSelected: false,
            isAddDevicePanelOpen: false,
            isEditDevicePanelOpen: false,
            isAddButtonDisabled: true,
            isEditButtonDisabled: true,
            filters: [],
            searchText: undefined
        },
            () => this.props.fetchNMSDeviceInventory({
                top: this.state.top,
                skip: this.state.skip,
                serviceTreeId: this.props.session.serviceTree?.serviceTreeId ?? '',
                searchText: this.state.searchText,
                dcSearchText: this.state.dcSearchText,
                enSearchText: this.state.enSearchText
            })
        )
    }

    public onRenderItemColumn = (item: any, index?: number, column?: IColumn): JSX.Element | string | number => {
        if (!column) return "";
        if (column && column.key === 'deviceName') {
            return <span onClick={() => this.redirect(item["deviceId"], item["deviceName"])} style={{ cursor: 'pointer' }}>{item["deviceName"]} </span>
        }

        else if (column && column.key == 'deviceStatus') {

            return <div>
                {item[column?.key] == 0 ?
                    <div>
                        <Icon iconName='CircleShapeSolid' style={{ fontSize: '1.2rem', color: '#FF0000' }} title='Offline' />
                    </div>
                    :
                    (item[column?.key] == 1 ?
                        <div>
                            <Icon iconName='CircleShapeSolid' style={{ fontSize: '1.2rem', color: '#008000' }} title='Online' />
                        </div>
                        :
                        <div>
                            <Icon iconName='CircleShapeSolid' style={{ fontSize: '1.2rem', color: '#8B837E' }} title='Unknown' />
                        </div>
                    )
                }
            </div>
        }

        else if (column && column.key == 'goldCodeCompliance') {

            return <div>
                {item[column?.key] == 0 ?
                    <div>
                        <Icon iconName='IncidentTriangle' style={{ fontSize: '1.5rem', color: '#8B0000' }} title='Non-Compliant' />
                    </div>
                    :
                    (item[column?.key] == 1 ?
                        <div>
                            <Icon iconName='CompletedSolid' style={{ fontSize: '1.5rem', color: '#006400' }} title='Compliant' />
                        </div>
                        :
                        <div>
                            <Icon iconName='UnknownSolid' style={{ fontSize: '1.5rem', color: '#8B837E' }} title='Unknown' />
                        </div>
                    )
                }
            </div>
        }
        else if (column && column.key === 'dashboard') {
            return <div>
                <a role="link" target="_blank" href={`https://nmsedgnetsec-g7g8c0fweth8csgb.wus3.grafana.azure.com/d/BCMseyYVk/device-overview?orgId=1&var-dc=${item["dataCenter"]}&var-enclave=All&var-device=${item["deviceName"]}&var-device_id=${item["deviceId"]}`} > View </a>
            </div>
        }
        return item[column?.key];
    };


    private redirect(deviceId: string, deviceName: string): void {
        const appContext: AppContext = window.appContext;
        appContext.microUIRedirect(`nmsdevicedetails?id=${deviceId}&name=${deviceName}`, false, true);
    }

    private searchBarCallback = (searchPhrase: string): void => {

        this.setState({ searchText: searchPhrase }, () => {
            //console.log(this.state)
            this.props.fetchNMSDeviceInventory({
                top: this.state.top,
                skip: this.state.skip,
                serviceTreeId: this.props.session.serviceTree?.serviceTreeId ?? '',
                searchText: this.state.searchText,
                dcSearchText: this.state.dcSearchText,
                enSearchText: this.state.enSearchText
            })
        });
    }

    public getGridProps(): IShimmeredDetailsListProps {
        const gridProp: IShimmeredDetailsListProps = {
            setKey: "set",
            items: this.props.deviceInventoryDetailsState.INMSDevInentoryResponse?.deviceInventory ?? [],
            columns: this.getColumns(),
            selection: this.DeviceSelection,
            useReducedRowRenderer: true,
            isSelectedOnFocus: false,
            onRenderItemColumn: this.onRenderItemColumn,
        }
        return gridProp;
    }

    private expandedDivStyle = mergeStyles({
        minHeight: '640px !important'
    })

    private collapsedDivStyle = mergeStyles({
        minHeight: '400px !important'
    })

    render(): JSX.Element {
        const labelStyles: Partial<IStyleSet<ILabelStyles>> = {
            root: { marginTop: 10 },
        };
        return (
            <div className={this.state.isAddDevicePanelOpen || this.state.isEditDevicePanelOpen ? this.expandedDivStyle : this.collapsedDivStyle}>
                <Helmet
                    defaultTitle={Resources.NMSInventory}
                    titleTemplate={`%s - ${Resources.NMSInventory}`}>
                    <meta content={Resources.NMSInventory} name="description" />
                </Helmet>
                {
                    this.props.deviceInventoryDetailsState.isDeviceIventoryLoading ?
                        <div className="app-loading-icon" style={{ minHeight: '400px' }}>
                            <Loader />
                        </div> :
                        <>
                            {
                                this.props.deviceInventoryDetailsState.isUpdateDeviceDetailsDataSaved ?
                                    this.getMessageBar(MessageBarType.success, Resources.DeviceUpdateSuccessMessage)
                                    : <></>
                            }

                            {
                                this.props.deviceInventoryDetailsState.isEditDeviceDetailsDataSaved ?
                                    this.getMessageBar(MessageBarType.success, Resources.DeviceEditSuccessMessage)
                                    : <></>
                            }

                            {
                                this.props.deviceInventoryDetailsState.isDeleteDeviceDetailsDataSaved ?
                                    this.getMessageBar(MessageBarType.success, Resources.DeviceDeleteSuccessMessage)
                                    : <></>
                            }
                            <h1>{Resources.NMSLandingPageTitle}</h1>
                            <p>{Resources.NMSLandingPageDescription}</p>

                            <Panel
                                isOpen={this.state.isFilterDevicePanelOpen}
                                onDismiss={this.onDismiss}
                                headerText={Resources.FilterDevicePanelText}
                                closeButtonAriaLabel="Close"
                                isLightDismiss={true}
                            >
                                <Stack>
                                    {
                                        this.props.deviceInventoryDetailsState?.isDataCentersListLoading ?
                                            <Loader /> :
                                            <>
                                                {this.getFilterDevicePanel()}

                                                <Stack.Item>
                                                    <PrimaryButton
                                                        text={Resources.ApplyButtonText}
                                                        disabled={this.state.isFilterButtonDisabled}
                                                        onClick={(event) => { this.filterItem(event) }}
                                                        styles={{ root: { marginTop: '15px' } }} />
                                                </Stack.Item>
                                            </>
                                    }
                                </Stack>
                            </Panel>

                            <Panel
                                isOpen={this.state.isAddDevicePanelOpen}
                                onDismiss={this.onDismiss}
                                headerText={Resources.AddDevicePanelText}
                                closeButtonAriaLabel="Close"
                                isLightDismiss={true}
                            >
                                <Stack>
                                    {
                                        this.props.deviceInventoryDetailsState?.isDataCentersListLoading
                                            || this.props.deviceInventoryDetailsState?.isDeviceTypeListLoading
                                            || this.props.deviceInventoryDetailsState?.isEnvironmentsListLoading ?
                                            <Loader /> :
                                            <>
                                                {this.getAddDevicePanel()}

                                                <Stack.Item>
                                                    <PrimaryButton
                                                        text={Resources.SaveButtonText}
                                                        disabled={this.state.isAddButtonDisabled}
                                                        onClick={(event) => { this.saveItem(event) }}
                                                        styles={{ root: { marginTop: '15px' } }} />
                                                </Stack.Item>
                                            </>
                                    }
                                </Stack>
                            </Panel>

                            <Panel
                                isOpen={this.state.isEditDevicePanelOpen}
                                onDismiss={this.onDismiss}
                                headerText={Resources.EditDevicePanelText}
                                closeButtonAriaLabel="Close"
                                isLightDismiss={true}
                                onOpen={this.onEditPaneOpened} // ENABLE IT LATER. DISABLED FOR TESTING
                            >
                                <Stack>
                                    {
                                        this.props.deviceInventoryDetailsState?.isDataCentersListLoading
                                            || this.props.deviceInventoryDetailsState?.isDeviceTypeListLoading
                                            || this.props.deviceInventoryDetailsState?.isEnvironmentsListLoading ?
                                            <Loader /> :
                                            <>
                                                {this.state.selectedDevice.length > 1 ? <>

                                                    {/* {this.getMessageBar(MessageBarType.info, 'Please select a single Device')} */}
                                                    {this.getMultiEditDevicePanel()}
                                                    <Stack.Item>
                                                        <PrimaryButton
                                                            text={Resources.SaveButtonText}
                                                            disabled={this.state.isEditButtonDisabled}
                                                            onClick={(event) => { this.multiEditSaveItem(event) }}
                                                            styles={{ root: { marginTop: '15px' } }} />
                                                    </Stack.Item>
                                                </>
                                                    :
                                                    this.state.selectedDevice.length > 0 ? <> {this.getEditDevicePanel()}
                                                        <Stack.Item>
                                                            <PrimaryButton
                                                                text={Resources.SaveButtonText}
                                                                disabled={this.state.isEditButtonDisabled}
                                                                onClick={(event) => { this.multiEditSaveItem(event) }}
                                                                styles={{ root: { marginTop: '15px' } }} />
                                                        </Stack.Item>
                                                    </>
                                                        : <> {this.getMessageBar(MessageBarType.info, 'Please select a Device')} </>}
                                            </>
                                    }
                                </Stack>
                            </Panel>

                            {this.state.isFilterApplied ? <>

                                <div className={classNames.headerAndFooter}>

                                    {/* <div className={classNames.headerTitle}>Applied Filter<div className={classNames.headersubTitle}>DC: {this.state.dcSearchText} Enclave: {this.state.enSearchText}</div></div> */}
                                    <div className={classNames.headerTitle}>
                                        <Icon onClick={this.clearFilter} iconName='ChromeClose' style={{ fontSize: '0.9rem', textDecorationColor: 'blue', cursor: 'pointer' }}></Icon>
                                        <Icon iconName='Filter' style={{ fontSize: '0.9rem', marginLeft: '10px', marginRight: '10px' }}></Icon>
                                        {this.state.dcSearchText} |
                                        {this.state.enSearchText}
                                        {/* <Icon onClick={this.clearFilter} iconName='ChromeClose' style={{ fontSize: '0.9rem' }}></Icon> */}
                                    </div>
                                </div>
                            </> : <></>
                            }
                            {this.getDeleteDialog()}
                            {this.getBeforeDeleteDialog()}
                            <Grid className="nms-details-grid"
                                grid={this.getGridProps()} //Grid items
                                top={this.state.top}
                                skip={this.state.skip}
                                countPerPage={this.state.countPerPage}
                                maxPagestoShow={3}
                                updateCountPerPage={this.updateDisplayPerPage.bind(this)}
                                total={this.props.deviceInventoryDetailsState.INMSDevInentoryResponse?.count ?? 0}
                                //onPageChangeEvent= {this.pageChangeEvent}
                                isLoading={false}
                                actionBarItem={this.getCommandBarItems()}
                                isFilterVisible={this.state.showFilters}
                                //filters={this.getFilterData()}   
                                noDataMessage={Resources.PolicyScreenNoDataMessage}
                                fetchData={this.getData.bind(this)}
                                selectedFilter={this.state.filters}
                                //isGridVisible={this.isGridVisible}  
                                onActiveItemChanged={this.activeItemChanged}
                                // isSearchBarEnabled={(this.props.deviceInventoryDetailsState.INMSDevInentoryResponse?.deviceInventory != undefined && this.props.deviceInventoryDetailsState.INMSDevInentoryResponse?.deviceInventory.length > 0) ?? false}
                                isSearchBarEnabled={true}
                                searchBarSearchCallback={this.searchBarCallback}
                                isSearchBarActive={true}
                                searchBoxText={this.state.searchText}
                                onClearSearchCallback={this.clearFilter.bind(this)}

                            />
                        </>
                }
            </div>
        );
    }
}

export const mapStateToProps = (state: DeepReadonly<IRootState>): DeepReadonly<IDeviceMapState> => ({
    deviceInventoryDetailsState: state.deviceInventoryDetailsState,
    session: state.session,
    microUI: state.microUI
});

const mapDispatchToProps = {
    fetchNMSDeviceInventory: Actions.fetchNMSDeviceInventory,
    updateNMSDeviceDetails: Actions.updateNMSDeviceDetails,
    editNMSDeviceDetails: Actions.editNMSDeviceDetails,
    deleteNMSDeviceDetails: Actions.deleteNMSDeviceDetails,
    fetchDataCentersList: Actions.fetchDataCentersList,
    fetchDeviceTypeList: Actions.fetchDeviceTypeList,
    fetchEnvironmentsList: Actions.fetchEnvironmentsList,
    fetchStateList: Actions.fetchStateList,
};

export default compose<React.ComponentType>(
    injectReducer({
        key: 'deviceInventoryDetailsState',//Keep this same in IRoot, and props interface
        reducer: deviceReducer as React.Reducer<DeepReadonly<IDeviceState>, DeepReadonly<Action>>,
    }),
    injectSaga({ key: 'deviceInventoryDetailsState', saga: devicedetailsSagas }),
    connect(mapStateToProps, mapDispatchToProps),
)(InventoryGrid);