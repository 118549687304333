import { DeepReadonly } from "../../../../types/base-types";
import { IColumnData } from "../../../common/charts/column/column.types";
import { IPieData } from "../../../common/charts/pie/pie.types";
import * as types from './action.types';
import { INS1DirectionChartRequest, INS1DirectionChartResponse, INS1ScoreChartRequest, INS1ScoreChartResponse, INSGDetailsRequest, INSGDetailsResponse, INSGRuleDetailsResponse, INSGRulesDetailsRequest } from "./types/ns1.types";

export const fetchScoreDistribution = (request: INS1ScoreChartRequest): types.IFetchScoreDistributionAction => {
  const action: types.IFetchScoreDistributionAction = {
    type: types.FetchScoreDistribution,
    request: request
  };
  return action;
};

export const fetchScoreDistributionSuccess = (
  payload: DeepReadonly<INS1ScoreChartResponse>,
): DeepReadonly<types.IFetchScoreDistributionSuccessAction> => {
  const action: DeepReadonly<types.IFetchScoreDistributionSuccessAction> = {
    type: types.FetchScoreDistributionSuccess,
    payload,
  };
  return action;
};

export const fetchScoreDistributionFailure = (
  error: DeepReadonly<Error>,
): types.IFetchScoreDistributionFailureAction => {
  const action: types.IFetchScoreDistributionFailureAction = {
    type: types.FetchScoreDistributionFailure,
    payload: error,
  };

  return action;
};

export const fetchServiceTreeInboundData = (request: INS1DirectionChartRequest): types.IFetchServiceTreeInboundDataAction => {
  const action: types.IFetchServiceTreeInboundDataAction = {
    type: types.FetchServiceTreeInboundData,
    request: request
  };
  return action;
};

export const fetchServiceTreeInboundDataSuccess = (
  payload: DeepReadonly<INS1DirectionChartResponse>,
): DeepReadonly<types.IFetchServiceTreeInboundDataSuccessAction> => {
  const action: DeepReadonly<types.IFetchServiceTreeInboundDataSuccessAction> = {
    type: types.FetchServiceTreeInboundDataSuccess,
    payload,
  };
  return action;
};

export const fetchServiceTreeInboundDataFailure = (
  error: DeepReadonly<Error>,
): types.IFetchServiceTreeInboundDataFailureAction => {
  const action: types.IFetchServiceTreeInboundDataFailureAction = {
    type: types.FetchServiceTreeInboundDataFailure,
    payload: error,
  };

  return action;
};

export const fetchServiceTreeOutboundData = (request: INS1DirectionChartRequest): types.IFetchServiceTreeOutboundDataAction => {
  const action: types.IFetchServiceTreeOutboundDataAction = {
    type: types.FetchServiceTreeOutboundData,
    request: request
  };
  return action;
};

export const fetchServiceTreeOutboundDataSuccess = (
  payload: DeepReadonly<INS1DirectionChartResponse>,
): DeepReadonly<types.IFetchServiceTreeOutboundDataSuccessAction> => {
  const action: DeepReadonly<types.IFetchServiceTreeOutboundDataSuccessAction> = {
    type: types.FetchServiceTreeOutboundDataSuccess,
    payload,
  };
  return action;
};

export const fetchServiceTreeOutboundDataFailure = (
  error: DeepReadonly<Error>,
): types.IFetchServiceTreeOutboundDataFailureAction => {
  const action: types.IFetchServiceTreeOutboundDataFailureAction = {
    type: types.FetchServiceTreeOutboundDataFailure,
    payload: error,
  };

  return action;
};

export const fetchServiceTreeBothData = (request: INS1DirectionChartRequest): types.IFetchServiceTreeBothDataAction => {
  const action: types.IFetchServiceTreeBothDataAction = {
    type: types.FetchServiceTreeBothData,
    request: request
  };
  return action;
};

export const fetchServiceTreeBothDataSuccess = (
  payload: DeepReadonly<INS1DirectionChartResponse>,
): DeepReadonly<types.IFetchServiceTreeBothDataSuccessAction> => {
  const action: DeepReadonly<types.IFetchServiceTreeBothDataSuccessAction> = {
    type: types.FetchServiceTreeBothDataSuccess,
    payload,
  };
  return action;
};

export const fetchServiceTreeBothDataFailure = (
  error: DeepReadonly<Error>,
): types.IFetchServiceTreeBothDataFailureAction => {
  const action: types.IFetchServiceTreeBothDataFailureAction = {
    type: types.FetchServiceTreeBothDataFailure,
    payload: error,
  };

  return action;
};

//grid action 
export const fetchNSGDetails = (request: INSGDetailsRequest): types.IFetchNSGDetailsAction => {
  const action: types.IFetchNSGDetailsAction = {
    type: types.FetchNSGDetails,
    request: request
  };
  return action;
};

export const fetchNSGDetailsSuccess = (
  payload: DeepReadonly<INSGDetailsResponse>,
): DeepReadonly<types.IFetchNSGDetailsSuccessAction> => {
  const action: DeepReadonly<types.IFetchNSGDetailsSuccessAction> = {
    type: types.FetchNSGDetailsSuccess,
    payload,
  };
  return action;
};

export const fetchNSGDetailsFailure = (
  error: DeepReadonly<Error>,
): types.IFetchNSGDetailsFailureAction => {
  const action: types.IFetchNSGDetailsFailureAction = {
    type: types.FetchNSGDetailsFailure,
    payload: error,
  };
  return action;
};

export const fetchNSGRuleDetails = (request: INSGRulesDetailsRequest): types.IFetchNSGRulesDetailsAction => {
  const action: types.IFetchNSGRulesDetailsAction = {
    type: types.FetchNSGRulesDetails,
    request: request
  };
  return action;
};

export const fetchNSGRuleDetailsSuccess = (
  payload: DeepReadonly<INSGRuleDetailsResponse>,
): DeepReadonly<types.IFetchNSGRulesDetailsSuccessAction> => {
  const action: DeepReadonly<types.IFetchNSGRulesDetailsSuccessAction> = {
    type: types.FetchNSGRulesDetailsSuccess,
    payload,
  };
  return action;
};

export const fetchNSGRuleDetailsFailure = (
  error: DeepReadonly<Error>,
): types.IFetchNSGRulesDetailsFailureAction => {
  const action: types.IFetchNSGRulesDetailsFailureAction = {
    type: types.FetchNSGRulesDetailsFailure,
    payload: error,
  };
  return action;
};
