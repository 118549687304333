import { IShimmerElement, Shimmer, ShimmerElementType } from "@fluentui/react";
import React from "react";

export class ShimmerColumnChart extends React.Component {
  private shimmerVerticalElement = (): IShimmerElement[] => {
    return [
      { type: ShimmerElementType.line, verticalAlign: 'bottom', width: '7%', height: 330 },
      { type: ShimmerElementType.gap, width: '3%' },
      { type: ShimmerElementType.line, verticalAlign: 'bottom', width: '7%', height: 220 },
      { type: ShimmerElementType.gap, width: '3%' },
      { type: ShimmerElementType.line, verticalAlign: 'bottom', width: '7%', height: 210 },
      { type: ShimmerElementType.gap, width: '3%' },
      { type: ShimmerElementType.line, verticalAlign: 'bottom', width: '7%', height: 190 },
      { type: ShimmerElementType.gap, width: '3%' },
      { type: ShimmerElementType.line, verticalAlign: 'bottom', width: '7%', height: 220 },
      { type: ShimmerElementType.gap, width: '3%' },
      { type: ShimmerElementType.line, verticalAlign: 'bottom', width: '7%', height: 210 },
      { type: ShimmerElementType.gap, width: '3%' },
      { type: ShimmerElementType.line, verticalAlign: 'bottom', width: '7%', height: 180 },
      { type: ShimmerElementType.gap, width: '3%' },
      { type: ShimmerElementType.line, verticalAlign: 'bottom', width: '7%', height: 230 },
      { type: ShimmerElementType.gap, width: '3%' },
      { type: ShimmerElementType.line, verticalAlign: 'bottom', width: '7%', height: 220 },
      { type: ShimmerElementType.gap, width: '3%' },
      { type: ShimmerElementType.line, verticalAlign: 'bottom', width: '7%', height: 210 },
      { type: ShimmerElementType.gap, width: '3%' },
    ];
  }

  render(): JSX.Element {
    return (
      <Shimmer shimmerElements={this.shimmerVerticalElement()}
        styles={{ root: { padding: '10px', height:'355px'} }}
      />
    )
  }
}