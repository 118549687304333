import { createSelector, Selector } from 'reselect';
import { ISessionState } from './state';
import { IRootState } from '../../types';
import { DeepReadonly } from '../../types/base-types';

export const selectFeature: Selector<
  DeepReadonly<IRootState>,
  DeepReadonly<ISessionState>
> = state => state.session;

export const currentUser = createSelector(selectFeature, state => state.user);
