import { Action } from "@reduxjs/toolkit";
import { DeepReadonly } from "../../../../types/base-types";
import { IColumnData } from "../../../common/charts/column/column.types";
import { IPieData } from "../../../common/charts/pie/pie.types";
import { INS1DirectionChartRequest, INS1DirectionChartResponse, INS1ScoreChartRequest, INS1ScoreChartResponse, INSGDetailsRequest, INSGDetailsResponse, INSGRuleDetailsResponse, INSGRulesDetailsRequest } from "./types/ns1.types";

export const FetchScoreDistribution = 'FETCH_SCORE_DISTRIBUTION';
export const FetchScoreDistributionSuccess = 'FETCH_SCORE_DISTRIBUTION_SUCCESS';
export const FetchScoreDistributionFailure = 'FETCH_SCORE_DISTRIBUTION_FAILURE';

export const FetchServiceTreeInboundData = 'FETCH_SERVICE_TREE_INBOUND_DATA';
export const FetchServiceTreeInboundDataSuccess = 'FETCH_SERVICE_TREE_INBOUND_DATA_SUCCESS';
export const FetchServiceTreeInboundDataFailure = 'FETCH_SERVICE_TREE_INBOUND_DATA_FAILURE';

export const FetchServiceTreeOutboundData = 'FETCH_SERVICE_TREE_OUTBOUND_DATA';
export const FetchServiceTreeOutboundDataSuccess = 'FETCH_SERVICE_TREE_OUTBOUND_DATA_SUCCESS';
export const FetchServiceTreeOutboundDataFailure = 'FETCH_SERVICE_TREE_OUTBOUND_DATA_FAILURE';

export const FetchServiceTreeBothData = 'FETCH_SERVICE_TREE_BOTH_DATA';
export const FetchServiceTreeBothDataSuccess = 'FETCH_SERVICE_TREE_BOTH_DATA_SUCCESS';
export const FetchServiceTreeBothDataFailure = 'FETCH_SERVICE_TREE_BOTH_DATA_FAILURE';

//grid action 
export const FetchNSGDetails = 'FETCH_NSG_DETAILS';
export const FetchNSGDetailsSuccess = 'FETCH_NSG_DETAILS_SUCCESS';
export const FetchNSGDetailsFailure = 'FETCH_NSG_DETAILS_FAILURE';

export const FetchNSGRulesDetails = 'FETCH_NSG_RULES_DETAILS';
export const FetchNSGRulesDetailsSuccess = 'FETCH_NSG_RULES_DETAILS_SUCCESS';
export const FetchNSGRulesDetailsFailure = 'FETCH_NSG_RULES_DETAILS_FAILURE';

export const FetchAntiForgeryToken = 'FETCH_ANTI_FORGERY_TOKEN';
export const FetchAntiForgeryTokenSuccess = 'FETCH_ANTI_FORGERY_TOKEN_SUCCESS';
export const FetchAntiForgeryTokenFailed = 'FETCH_ANTI_FORGERY_TOKEN_FAILED';


export interface IFetchScoreDistributionAction extends Action<typeof FetchScoreDistribution> {
  request: DeepReadonly<INS1ScoreChartRequest>;
}

export interface IFetchScoreDistributionSuccessAction extends Action<typeof FetchScoreDistributionSuccess> {
  payload: DeepReadonly<INS1ScoreChartResponse>;
}

export interface IFetchScoreDistributionFailureAction extends Action<typeof FetchScoreDistributionFailure> {
  payload: DeepReadonly<Error>;
}

export interface IFetchServiceTreeInboundDataAction extends Action<typeof FetchServiceTreeInboundData> {
  request: DeepReadonly<INS1DirectionChartRequest>;
}

export interface IFetchServiceTreeInboundDataSuccessAction extends Action<typeof FetchServiceTreeInboundDataSuccess> {
  payload: DeepReadonly<INS1DirectionChartResponse>;
}

export interface IFetchServiceTreeInboundDataFailureAction extends Action<typeof FetchServiceTreeInboundDataFailure> {
  payload: DeepReadonly<Error>;
}

export interface IFetchServiceTreeOutboundDataAction extends Action<typeof FetchServiceTreeOutboundData> {
  request: DeepReadonly<INS1DirectionChartRequest>;
}

export interface IFetchServiceTreeOutboundDataSuccessAction extends Action<typeof FetchServiceTreeOutboundDataSuccess> {
  payload: DeepReadonly<INS1DirectionChartResponse>;
}

export interface IFetchServiceTreeOutboundDataFailureAction extends Action<typeof FetchServiceTreeOutboundDataFailure> {
  payload: DeepReadonly<Error>;
}

export interface IFetchServiceTreeBothDataAction extends Action<typeof FetchServiceTreeBothData> {
  request: DeepReadonly<INS1DirectionChartRequest>;
}

export interface IFetchServiceTreeBothDataSuccessAction extends Action<typeof FetchServiceTreeBothDataSuccess> {
  payload: DeepReadonly<INS1DirectionChartResponse>;
}

export interface IFetchServiceTreeBothDataFailureAction extends Action<typeof FetchServiceTreeBothDataFailure> {
  payload: DeepReadonly<Error>;
}

export interface IFetchServiceTreeBothDataAction extends Action<typeof FetchServiceTreeBothData> {
  request: DeepReadonly<INS1DirectionChartRequest>;
}

export interface IFetchServiceTreeBothDataSuccessAction extends Action<typeof FetchServiceTreeBothDataSuccess> {
  payload: DeepReadonly<INS1DirectionChartResponse>;
}

export interface IFetchServiceTreeBothDataFailureAction extends Action<typeof FetchServiceTreeBothDataFailure> {
  payload: DeepReadonly<Error>;
}

//grid action
export interface IFetchNSGDetailsAction extends Action<typeof FetchNSGDetails> {
  request: DeepReadonly<INSGDetailsRequest>;
}

export interface IFetchNSGDetailsSuccessAction extends Action<typeof FetchNSGDetailsSuccess> {
  payload: DeepReadonly<INSGDetailsResponse>;
}

export interface IFetchNSGDetailsFailureAction extends Action<typeof FetchNSGDetailsFailure> {
  payload: DeepReadonly<Error>;
}

export interface IFetchNSGRulesDetailsAction extends Action<typeof FetchNSGRulesDetails> {
  request: DeepReadonly<INSGRulesDetailsRequest>;
}

export interface IFetchNSGRulesDetailsSuccessAction extends Action<typeof FetchNSGRulesDetailsSuccess> {
  payload: DeepReadonly<INSGRuleDetailsResponse>;
}

export interface IFetchNSGRulesDetailsFailureAction extends Action<typeof FetchNSGRulesDetailsFailure> {
  payload: DeepReadonly<Error>;
}

export interface IFetchAntiForgeryTokenAction extends Action<typeof FetchAntiForgeryToken> {
}

export interface IFetchAntiForgeryTokenActionSuccess extends Action<typeof FetchAntiForgeryTokenSuccess> {
}

export interface IFetchAntiForgeryTokenActionFailed extends Action<typeof FetchAntiForgeryTokenFailed> {
  payload: Error;
}

export type NS1Actions = IFetchScoreDistributionAction
  | IFetchScoreDistributionSuccessAction
  | IFetchScoreDistributionFailureAction
  | IFetchServiceTreeBothDataAction
  | IFetchServiceTreeBothDataSuccessAction
  | IFetchServiceTreeBothDataFailureAction
  | IFetchServiceTreeInboundDataAction
  | IFetchServiceTreeInboundDataSuccessAction
  | IFetchServiceTreeInboundDataFailureAction
  | IFetchServiceTreeOutboundDataAction
  | IFetchServiceTreeOutboundDataSuccessAction
  | IFetchServiceTreeOutboundDataFailureAction
  | IFetchNSGDetailsAction
  | IFetchNSGDetailsSuccessAction
  | IFetchNSGDetailsFailureAction
  | IFetchNSGRulesDetailsAction
  | IFetchNSGRulesDetailsSuccessAction
  | IFetchNSGRulesDetailsFailureAction
  | IFetchAntiForgeryTokenAction
  | IFetchAntiForgeryTokenActionSuccess
  | IFetchAntiForgeryTokenActionFailed