import { IDataCentersDetails, IDataCentersRequest, IDeviceTypeDetails, IDeviceTypeRequest, IEnvironmentsDetails, IEnvironmentsRequest, INMSDeviceDetailsDeleteRequest, INMSDeviceDetailsEditRequest, INMSDeviceDetailsUpdateRequest, INMSDevInentoryRequest, INMSDevInentoryResponse, INMSMultiDeviceEditRequest, IStateDetails, IStateRequest } from "./types/nmsDeviceInventory.types";
import * as types from './actionTypes';
import { DeepReadonly } from "../../../types/base-types";



export const fetchNMSDeviceInventory = (request: INMSDevInentoryRequest): types.IFetchNMSDeviceInventoryAction => {
  const action: types.IFetchNMSDeviceInventoryAction = {
    type: types.FetchNMSDeviceInventory,
    request: request
  };
  return action;
};


export const fetchNMSDeviceInventorySuccess = (
  payload: DeepReadonly<INMSDevInentoryResponse>,
): DeepReadonly<types.IFetchNMSDeviceInventorySuccessAction> => {
  const action: DeepReadonly<types.IFetchNMSDeviceInventorySuccessAction> = {
    type: types.FetchNMSDeviceInventorySuccess,
    payload,
  };
  return action;
};


export const fetchNMSDeviceInventoryFailure = (
  error: DeepReadonly<Error>,
): types.IFetchNMSDeviceInventoryFailureAction => {
  const action: types.IFetchNMSDeviceInventoryFailureAction = {
    type: types.FetchNMSDeviceInventoryFailure,
    payload: error,
  };

  return action;
};

//Update NMS Device
export const updateNMSDeviceDetails = (request: INMSDeviceDetailsUpdateRequest): types.IUpdateNMSDeviceDetailsAction => {
  const action: types.IUpdateNMSDeviceDetailsAction = {
    type: types.UpdateNMSDeviceDetails,
    request: request
  };
  return action;
};

export const updateNMSDeviceDetailsSuccess = (
  payload: DeepReadonly<boolean>,
): DeepReadonly<types.IUpdateNMSDeviceDetailsSuccessAction> => {
  const action: DeepReadonly<types.IUpdateNMSDeviceDetailsSuccessAction> = {
    type: types.UpdateNMSDeviceDetailsSuccess,
    payload: payload,
  };
  return action;
};

export const updateNMSDeviceDetailsFailure = (
  error: DeepReadonly<Error>,
): types.IUpdateNMSDeviceDetailsFailureAction => {
  const action: types.IUpdateNMSDeviceDetailsFailureAction = {
    type: types.UpdateNMSDeviceDetailsFailure,
    payload: error,
  };
  return action;
};


//Edit NMS Device
export const editNMSDeviceDetails = (request: INMSMultiDeviceEditRequest): types.IEditNMSDeviceDetailsAction => {
  const action: types.IEditNMSDeviceDetailsAction = {
    type: types.EditNMSDeviceDetails,
    request: request
  };
  return action;
};

export const editNMSDeviceDetailsSuccess = (
  payload: DeepReadonly<boolean>,
): DeepReadonly<types.IEditNMSDeviceDetailsSuccessAction> => {
  const action: DeepReadonly<types.IEditNMSDeviceDetailsSuccessAction> = {
    type: types.EditNMSDeviceDetailsSuccess,
    payload: payload,
  };
  return action;
};

export const editNMSDeviceDetailsFailure = (
  error: DeepReadonly<Error>,
): types.IEditNMSDeviceDetailsFailureAction => {
  const action: types.IEditNMSDeviceDetailsFailureAction = {
    type: types.EditNMSDeviceDetailsFailure,
    payload: error,
  };
  return action;
};



//delete NMS Device
export const deleteNMSDeviceDetails = (request: INMSDeviceDetailsDeleteRequest): types.IDeleteNMSDeviceDetailsAction => {
  const action: types.IDeleteNMSDeviceDetailsAction = {
    type: types.DeleteNMSDeviceDetails,
    request: request
  };
  return action;
};

export const deleteNMSDeviceDetailsSuccess = (
  payload: DeepReadonly<boolean>,
): DeepReadonly<types.IDeleteNMSDeviceDetailsSuccessAction> => {
  const action: DeepReadonly<types.IDeleteNMSDeviceDetailsSuccessAction> = {
    type: types.DeleteNMSDeviceDetailsSuccess,
    payload: payload,
  };
  return action;
};

export const deleteNMSDeviceDetailsFailure = (
  error: DeepReadonly<Error>,
): types.IDeleteNMSDeviceDetailsFailureAction => {
  const action: types.IDeleteNMSDeviceDetailsFailureAction = {
    type: types.DeleteNMSDeviceDetailsFailure,
    payload: error,
  };
  return action;
};

//Fetch DataCenters List
export const fetchDataCentersList = (request: IDataCentersRequest): types.IFetchDataCentersListAction => {
  const action: types.IFetchDataCentersListAction = {
    type: types.FetchDataCentersList,
    request: request
  };
  return action;
};

export const fetchDataCentersListSuccess = (
  payload: DeepReadonly<IDataCentersDetails[]>,
): DeepReadonly<types.IFetchDataCentersListSuccessAction> => {
  const action: DeepReadonly<types.IFetchDataCentersListSuccessAction> = {
    type: types.FetchDataCentersListSuccess,
    payload: payload,
  };
  return action;
};

export const fetchDataCentersListFailure = (
  error: DeepReadonly<Error>,
): types.IFetchDataCentersListFailureAction => {
  const action: types.IFetchDataCentersListFailureAction = {
    type: types.FetchDataCentersListFailure,
    payload: error,
  };
  return action;
};

//Fetch DeviceType List
export const fetchDeviceTypeList = (request: IDeviceTypeRequest): types.IFetchDeviceTypeListAction => {
  const action: types.IFetchDeviceTypeListAction = {
    type: types.FetchDeviceTypeList,
    request: request
  };
  return action;
};

export const fetchDeviceTypeListSuccess = (
  payload: DeepReadonly<IDeviceTypeDetails[]>,
): DeepReadonly<types.IFetchDeviceTypeListSuccessAction> => {
  const action: DeepReadonly<types.IFetchDeviceTypeListSuccessAction> = {
    type: types.FetchDeviceTypeListSuccess,
    payload: payload,
  };
  return action;
};

export const fetchDeviceTypeListFailure = (
  error: DeepReadonly<Error>,
): types.IFetchDeviceTypeListFailureAction => {
  const action: types.IFetchDeviceTypeListFailureAction = {
    type: types.FetchDeviceTypeListFailure,
    payload: error,
  };
  return action;
};

//Fetch Environments List
export const fetchEnvironmentsList = (request: IEnvironmentsRequest): types.IFetchEnvironmentsListAction => {
  const action: types.IFetchEnvironmentsListAction = {
    type: types.FetchEnvironmentsList,
    request: request
  };
  return action;
};

export const fetchEnvironmentsListSuccess = (
  payload: DeepReadonly<IEnvironmentsDetails[]>,
): DeepReadonly<types.IFetchEnvironmentsListSuccessAction> => {
  const action: DeepReadonly<types.IFetchEnvironmentsListSuccessAction> = {
    type: types.FetchEnvironmentsListSuccess,
    payload: payload,
  };
  return action;
};

export const fetchEnvironmentsListFailure = (
  error: DeepReadonly<Error>,
): types.IFetchEnvironmentsListFailureAction => {
  const action: types.IFetchEnvironmentsListFailureAction = {
    type: types.FetchEnvironmentsListFailure,
    payload: error,
  };
  return action;
};


//Fetch State List
export const fetchStateList = (request: IStateRequest): types.IFetchStateListAction => {
  const action: types.IFetchStateListAction = {
    type: types.FetchStateList,
    request: request
  };
  return action;
};

export const fetchStateListSuccess = (
  payload: DeepReadonly<IStateDetails[]>,
): DeepReadonly<types.IFetchStateListSuccessAction> => {
  const action: DeepReadonly<types.IFetchStateListSuccessAction> = {
    type: types.FetchStateListSuccess,
    payload: payload,
  };
  return action;
};

export const fetchStateListFailure = (
  error: DeepReadonly<Error>,
): types.IFetchStateListFailureAction => {
  const action: types.IFetchStateListFailureAction = {
    type: types.FetchStateListFailure,
    payload: error,
  };
  return action;
};


//Fetch AntiforgeryToken
export const fetchAntiForgeryToken = (): types.IFetchAntiForgeryTokenAction => {
  const action: types.IFetchAntiForgeryTokenAction = {
    type: types.FetchAntiForgeryToken,
  };
  return action;
};

export const fetchAntiForgeryTokenSuccess = (): types.IFetchAntiForgeryTokenActionSuccess => {
  const action: types.IFetchAntiForgeryTokenActionSuccess = {
    type: types.FetchAntiForgeryTokenSuccess,
  };
  return action;
};

export const fetchAntiForgeryTokenFailed = (error: DeepReadonly<Error>): types.IFetchAntiForgeryTokenActionFailed => {
  const action: types.IFetchAntiForgeryTokenActionFailed = {
    type: types.FetchAntiForgeryTokenFailed,
    payload: error
  };
  return action;
};