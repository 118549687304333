import { IShimmerElement, Shimmer, ShimmerElementType } from "@fluentui/react";
import React from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from 'apexcharts';
import { IPieChart } from "../pie.types";
import { Maybe } from "../../../../../types/base-types";

export class PieChartVisual extends React.Component<IPieChart> {
  private colorSeries = [
    '#004E8C',
    '#8764B8',
    '#0099BC',
    '#77004D',
    '#0E7878',
    '#AE8C00',
    '#E43BA6',
    '#4F6BED',
    '#881798',
    '#9C663F',
    '#E6F69D',
    '#AADEA7',
    '#64C2A6',
    '#2D87BB',
    '#2900A5',
    '#9552EA',
    '#93F03B',
    '#F54F52',
    '#FFA32F',
    '#378AFF',
  ];

  private getSeries = (): Maybe<ApexAxisChartSeries> => {
    return ([{
      name: this.props.name,
      data: this.props.data ?? []
    }])
  }
  private getOptions = (): ApexOptions => {
    return ({
      colors: this.colorSeries,
      series: this.getSeries(),
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false
        },
        events: {
          dataPointSelection: this.props.dataPointSelection
        }
      },
      labels: this.props.labels
    })
  }

  render(): JSX.Element {
    return (
      <Chart
        type="pie"
        series={this.props.data}
        options={this.getOptions()}
        labels={['A', 'B', 'C', 'D', 'E']}
        height={this.props.height}
      />
    )
  }
}