import React from "react";
import { mergeStyles, CommandBar, TextField, Stack, IStackItemStyles, ICommandBarStyles, IStackStyles, SearchBox, Toggle } from '@fluentui/react';
import { IPagableGridActionHeaderProps } from "../grid.types";
import { Maybe } from "../../../../types/base-types";

const getGridActionHeaderStyle = (className): string => {
  return mergeStyles(
    {
      displayName: 'paginationContainer',
      alignItems: 'center',
      display: 'flex',
      margin: '4px 0px',
      padding: '4px 10px',
      width: 'auto',
      backgroundColor: '#edebe9'
    },
    className,
  );
};


const commandBarStyles: ICommandBarStyles = {
  root: {
    background: 'transparent',
    height: '30px',
    padding: '0',
  },
};

const stackStyles: IStackStyles = {
  root: {
    width: '100%'
  },
};

const searchBoxStyle: IStackItemStyles = {
  root: {
    display: "flex",
    alignItems: "center",
  }
}

export class GridActionHeader extends React.Component<IPagableGridActionHeaderProps> {
  render(): JSX.Element {
    return (
      <>
        {
          <div className={`${getGridActionHeaderStyle(this.props.className)}`}>
            <Stack
              className={"actionBarContainer"}
              horizontal
              horizontalAlign="end"
              tokens={{ childrenGap: 8 }}
              styles={stackStyles}>
              {
                this.props.toggle != undefined ?
                  <Stack.Item styles={{ root: { display: 'flex', alignItems: 'center', justifyContent: 'center' } }}>
                    <Toggle
                      label={this.props.toggle?.toggleHeader}
                      styles={{ root: { margin: '0px' } }}
                      defaultChecked={this.props.toggle.isChecked}
                      onText={this.props.toggle?.toggleOnText}
                      offText={this.props.toggle?.toggleOffText}
                      onChange={this.props.toggle.toggleCallback}
                      role="checkbox"
                    />
                  </Stack.Item> :
                  <></>
              }
              {
                this.props.isSearchBarEnabled ?
                  <Stack.Item styles={searchBoxStyle}>
                    <SearchBox
                      key={`${this.props.className}-grid-search-text-field`}
                      placeholder={"Enter the value and press enter to filter"}
                      onSearch={(value: string): void => {
                        if (this.props.searchBarSearchCallback)
                          this.props.searchBarSearchCallback(value)
                      }}
                      onClear={(ev?: any) => {
                        if (this.props.searchBarSearchCallback && this.props.searchBoxText)
                        if (this.props.onClearSearchCallback)
                          this.props.onClearSearchCallback(ev)
                      }}
                      style={{ minWidth: '290px' }}
                      defaultValue={this.props.searchBoxText}
                    />
                  </Stack.Item> :
                  <></>
              }
              <Stack.Item>
                {
                  this.props.item ?
                    <CommandBar
                      className="header-commandBar-desktop"
                      items={this.props.item}
                      styles={commandBarStyles}
                    />
                    :
                    <></>
                }
              </Stack.Item>
            </Stack>
          </div>
        }
      </>
    );
  }
}