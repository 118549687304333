export const MicroUIInitialized = 'MICROUI_INITIALIZED';

export interface IInitializePayload {
  isEnabled: boolean;
}

interface IInitializedAction {
  type: typeof MicroUIInitialized;
  payload: IInitializePayload;
}

export function initialize(payload: Readonly<IInitializePayload>): IInitializedAction {
  return {
    type: MicroUIInitialized,
    payload,
  };
}

export type MicroUIActions = IInitializedAction;
