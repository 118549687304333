import { Action } from '@reduxjs/toolkit';
import { DeepReadonly, Maybe } from '../../types/base-types';
import { IUserInfo } from './models';
import { IServiceTree } from './state';

export const UserLoaded = 'USER_LOADED';
export const ServiceTreeIdFetched = 'SERVICE_TREE_ID_FETCHED';
export const QueryParamFetched = 'QUERY_PARAM_FETCHED';

interface IUserLoadedAction extends Action<typeof UserLoaded> {
  payload: IUserInfo;
}

interface IServiceTreeIdFetchedAction extends Action<typeof ServiceTreeIdFetched> {
  payload: IServiceTree;
}

interface IQueryParamFetchedAction extends Action<typeof QueryParamFetched> {
  payload: Maybe<Record<string, string>>;
}


export function userLoaded(user: DeepReadonly<IUserInfo>): IUserLoadedAction {
  return {
    type: UserLoaded,
    payload: user,
  };
}

export function serviceTreeIdFetched(serviceTree: DeepReadonly<IServiceTree>): IServiceTreeIdFetchedAction {
  return {
    type: ServiceTreeIdFetched,
    payload: serviceTree,
  };
}

export function queryParamFetched(queryParam: Maybe<Record<string, string>>): IQueryParamFetchedAction {
  return {
    type: QueryParamFetched,
    payload: queryParam,
  };
}

export type SessionActions =
  | IUserLoadedAction
  | IServiceTreeIdFetchedAction
  | IQueryParamFetchedAction
