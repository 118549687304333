import { IColumn, IShimmeredDetailsListProps, getTheme, mergeStyleSets } from "@fluentui/react";
import { Action, compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { injectReducer, injectSaga } from "redux-injectors";
import { IRootState } from "../../../../types";
import { DeepReadonly, Maybe } from "../../../../types/base-types";
import { Grid } from "../../../common/grid";
import Loader from "../../../common/Loader/Loader";
import * as Actions from '../../store/action';
import { deviceReducer } from "../../store/reducers";
import { devicedetailsSagas } from "../../store/sagas";
import { InmsDeviceDetailsState } from "../../store/state";
import { IInterfaceMapState, IInterfaceProps } from "../../store/types/nmsInterfaceTab.types";


interface IPolicyLocalState {
  top?: number,
  skip?: number,
  countPerPage?: number,
}

const theme = getTheme();
const classNames = mergeStyleSets({
  headerAndFooter: {
    //borderTop: `${1}px solid ${theme.palette.neutralQuaternary}`,
    //borderBottom: `${1}px solid ${theme.palette.neutralQuaternary}`,
    padding: 4,
    margin: `${1}px 0`,
    marginTop: 10,
    //background: theme.palette.neutralLighterAlt,
    backgroundColor: '#edebe9',
    // Overlay the sizer bars
    position: 'relative',
    zIndex: 100,
  },
  headerTitle: [
    theme.fonts.medium,
    {
      padding: '1px 0',
      marginLeft: '10px',
      fontWeight: 'bold'
    },
  ],

  headersubTitle: [
    theme.fonts.medium,
    {
      padding: '1px 0',
    },
  ],
  headerLinkSet: {
    margin: '4px -8px',
  },
  headerLink: {
    margin: '0 8px',
  },
});

export class InterfaceGrid extends React.Component<IInterfaceProps, IPolicyLocalState> {
  constructor(props: IInterfaceProps) {
    super(props)
    this.state = {
      top: 25,
      skip: 0,
      countPerPage: 25,
    };
  }

  componentDidMount() {
    this.props.fetchInterfaceDetails({
      top: 25,
      skip: 0,
      deviceId: this.props.session.queryParam?.id ?? '',
      serviceTreeId: this.props.session?.serviceTree?.serviceTreeId ?? ''
    })
  }

  public getData(top?: number, skip?: number): void {
    this.setState((prevState) => {
      return { top: top, skip: skip }
    }
      , () => {
        this.props.fetchInterfaceDetails({
          top: this.state.top,
          skip: this.state.skip,
          deviceId: this.props.session.queryParam?.id ?? '',
          serviceTreeId: this.props.session?.serviceTree?.serviceTreeId ?? ''
        });
      }
    );
  }

  public getColumns = (): IColumn[] => {
    let cols: IColumn[] = [{
      key: "physicalInterface",
      name: "Physical Interface",
      fieldName: "physicalInterface",
      minWidth: 50,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true
    },
    {
      key: "interfaceDescription",
      name: "Interface Description",
      fieldName: "interfaceDescription",
      minWidth: 50,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true
    },
    {
      key: "portType",
      name: "Port Type",
      fieldName: "portType",
      minWidth: 50,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true
    },
    {
      key: "interfaceIP",
      name: "Interface IP",
      fieldName: "interfaceIP",
      minWidth: 50,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true
    }
      , {
      key: "opsStatus",
      name: "Ops Status",
      fieldName: "opsStatus",
      minWidth: 50,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true
    },
    ]
    return cols;
  }


  public getGridProps(): IShimmeredDetailsListProps {
    const gridProp: IShimmeredDetailsListProps = {
      setKey: "set",
      items: this.props.interfaceDetailsState.IInterfaceDetailsResponse?.deviceInterfaces ?? [], //this.state.policy ? [...this.state.policy] : [],
      columns: this.getColumns(),
      selectionMode: 0,
      onRenderItemColumn: this.onRenderItemColumn,
    }
    return gridProp;
  }

  public updateDisplayPerPage(key: number) {
    this.setState({ top: key, skip: 0, countPerPage: key }, () => {
      this.props.fetchInterfaceDetails({
        top: this.state.top,
        skip: this.state.skip,
        deviceId: this.props.session.queryParam?.id ?? '',
        serviceTreeId: this.props.session?.serviceTree?.serviceTreeId ?? ''
      });
    });
  }

  public onRenderItemColumn = (item: any, index?: number, column?: IColumn): JSX.Element | string | number => {
    if (!column) return "";
    if (column && column.key === 'physicalInterface') {
      //return <span onClick={() => this.redirect(item["deviceId"], item["physicalInterface"])} style={{ cursor: 'pointer' }}>{item["physicalInterface"]} </span>
      const deviceId = this.props.session.queryParam?.id ?? ''
      return <div>
        <a role="link" target="_blank" href={`https://nmsedgnetsec-g7g8c0fweth8csgb.wus3.grafana.azure.com/d/eJI0Ptl4k/device-interface-dashboard?orgId=1&var-device_id=${deviceId}&var-interface=${item["physicalInterface"]}`} > {item["physicalInterface"]} </a>
      </div>
    }
    return item[column?.key];
  };




  render(): JSX.Element {
    return (
      <>
        <div className={classNames.headerAndFooter}>
          <div className={classNames.headerTitle}>{this.props.session.queryParam?.name}</div>
        </div>
        {
          this.props.interfaceDetailsState?.isInterfaceDetailsLoading ?
            <div className="app-loading-icon" style={{ minHeight: '400px' }}>
              <Loader />
            </div> :
            <>
              <Grid className="nms-interface-grid"
                grid={this.getGridProps()} //Grid items
                top={this.state.top}
                skip={this.state.skip}
                countPerPage={this.state.countPerPage}
                maxPagestoShow={3}
                updateCountPerPage={this.updateDisplayPerPage.bind(this)}
                total={this.props.interfaceDetailsState?.IInterfaceDetailsResponse?.count ?? 0}
                //onPageChangeEvent= {this.pageChangeEvent}
                isLoading={false}
                // isFilterVisible={this.state.showFilters}
                //filters={this.getFilterData()}   
                fetchData={this.getData.bind(this)}
                //selectedFilter={this.state.filters}  
                //isGridVisible={this.isGridVisible}  
                //onActiveItemChanged={this.activeItemChanged} 
                isSearchBarEnabled={false}
                //searchBarSearchCallback={this.searchBarCallback}    
                isSearchBarActive={false}
              //customApplyEnableCheck={this.customApplyCheck}
              />
            </>
        }
      </>
    );
  }
}

export const mapStateToProps = (state: DeepReadonly<IRootState>): DeepReadonly<IInterfaceMapState> => ({
  interfaceDetailsState: state.nmsDeviceDetailsState,
  session: state.session,
  microUI: state.microUI
});

const mapDispatchToProps = {
  fetchInterfaceDetails: Actions.fetchInterfaceDetails,

};

export default compose<React.ComponentType>(
  injectReducer({
    key: 'nmsDeviceDetailsState',//Keep this same in IRoot, and props interface
    reducer: deviceReducer as React.Reducer<DeepReadonly<InmsDeviceDetailsState>, DeepReadonly<Action>>,
  }),
  injectSaga({ key: 'nmsDeviceDetailsState', saga: devicedetailsSagas }),
  connect(mapStateToProps, mapDispatchToProps),
)
  (InterfaceGrid);