import { BaseButton, Button, CommandBar, Dialog, DialogType, IColumn, ICommandBarItemProps, IDialogStyles, IShimmeredDetailsListProps, Modal, PrimaryButton, Stack } from "@fluentui/react";
import React from "react";
import { Resources } from "../../../../../locales/resources";
import { DeepReadonly, Maybe } from "../../../../../types/base-types";
import { Grid } from "../../../../common/grid";
import { IFilter, IPageableGridFilter } from "../../../../common/grid/grid.types";
import { Selection } from '@fluentui/react';
import { DetailsShimmeredGrid } from "./detailsShimmeredGrid";
import { INSGDetail, INSGDetailsGridProps, INSGRuleDetail, MCSBSecurityGridType } from "../../store/types/ns1.types";

export interface IMCSBGridState {
    top: number,
    skip: number,
    ruleTop: number,
    ruleSkip: number,
    countPerPage: number,
    ruleCountPerPage: number,
    showFilters: boolean,
    selectedNSGRule: Maybe<INSGRuleDetail>,
    selectedNSGSuggestion: Maybe<INSGDetail>,
    searchText: string,
    selectedGridType: MCSBSecurityGridType,
    selectedNSG: Maybe<INSGDetail>,
    isSuggestionVisible: boolean,
    isNSGSuggestionVisible: boolean,
}

export class NSGDetailsGrid extends React.Component<INSGDetailsGridProps, IMCSBGridState> {
    constructor(props: INSGDetailsGridProps) {
        super(props);
        this.state = {
            top: 25,
            skip: 0,
            ruleTop: 25,
            ruleSkip: 0,
            ruleCountPerPage: 25,
            countPerPage: 25,
            showFilters: true,
            searchText: '',
            selectedGridType: MCSBSecurityGridType.NSGDetailGrid,
            selectedNSG: undefined,
            selectedNSGRule: undefined,
            selectedNSGSuggestion: undefined,
            isSuggestionVisible: false,
            isNSGSuggestionVisible: false
        }
    }

    componentDidMount(): void {
        this.props.fetchNSGDetails({
            serviceTreeId: this.props.serviceTreeId,
            top: this.state.top,
            skip: this.state.skip,
            score: this.props.selectedScore
        })
    }

    private getGridSelectionDetails(ev: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement>, item: INSGDetail): void {

        // const gridSelectionDetails = this.gridSelection.getSelection();
        this.setState((prevState) => {
            return {
                selectedNSG: prevState.selectedGridType == MCSBSecurityGridType.NSGDetailGrid ? (item) : prevState.selectedNSG,
                selectedGridType: MCSBSecurityGridType.NSGRuleGrid,
                ruleTop: 25,
                ruleSkip: 0,
                ruleCountPerPage: 25
            }
        }, () => {
            if (this.state.selectedNSG)
                this.props.fetchNSGRuleDetails({
                    serviceTreeId: this.props.serviceTreeId,
                    top: this.state.ruleTop,
                    skip: this.state.ruleSkip,
                    nsgId: this.state.selectedNSG?.nsgId,
                    nsgServiceTreeId: this.state.selectedNSG?.serviceTreeId
                })
        })
    }

    public updateDisplayPerPage(key: number) {
        this.setState(
            {
                top: key,
                skip: 0,
                countPerPage: key
            },
            () => {
                this.props.fetchNSGDetails({
                    serviceTreeId: this.props.serviceTreeId ?? '',
                    top: this.state.top,
                    skip: this.state.skip,
                    score: this.props.selectedScore
                })
            });
    }

    public updateDisplayPerPageRuleGrid(key: number) {
        this.setState(
            {
                ruleTop: key,
                ruleSkip: 0,
                ruleCountPerPage: key
            },
            () => {
                if (this.props.serviceTreeId && this.state.selectedNSG?.nsgId)
                    this.props.fetchNSGRuleDetails({
                        serviceTreeId: this.props.serviceTreeId,
                        top: this.state.ruleTop,
                        skip: this.state.skip,
                        nsgId: this.state.selectedNSG?.nsgId,
                        nsgServiceTreeId: this.state.selectedNSG?.serviceTreeId
                    })
            });
    }

    getNSGDetailsColumn = (): IColumn[] => {
        let cols: IColumn[] = [
            {
                key: "nsgName",
                name: "NSG Name",
                fieldName: "nsgName",
                minWidth: 100,
                maxWidth: 150,
                isResizable: true,
                isRowHeader: true
            },
            {
                key: "serviceTreeId",
                name: "Service Tree Id",
                fieldName: "serviceTreeId",
                minWidth: 50,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true
            },
            {
                key: "serviceTreeName",
                name: "Service Tree Name",
                fieldName: "serviceTreeName",
                minWidth: 50,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true
            },
            {
                key: "subscriptionId",
                name: "Subscription Id",
                fieldName: "subscriptionId",
                minWidth: 50,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true
            },
            {
                key: "nicName",
                name: "NICs",
                fieldName: "nicName",
                minWidth: 50,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true
            },
            {
                key: "subnetName",
                name: "SubNets",
                fieldName: "subnetName",
                minWidth: 50,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true
            },
            {
                key: "score",
                name: "Score",
                fieldName: "score",
                minWidth: 100,
                maxWidth: 120,
                isResizable: true,
                isRowHeader: true
            },
            {
                key: "suggestion",
                name: "View Suggestions",
                fieldName: "suggestion",
                minWidth: 100,
                maxWidth: 120,
                isResizable: true,
                isRowHeader: true
            }]
        return cols;
    }

    getNSGRuleDetailsColumn = (): IColumn[] => {
        let cols: IColumn[] = [
            {
                key: "nsgRuleName",
                name: "NSG Rule Name",
                fieldName: "nsgRuleName",
                minWidth: 50,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true
            },
            {
                key: "priority",
                name: "Priority",
                fieldName: "priority",
                minWidth: 50,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true
            },
            {
                key: "access",
                name: "Access",
                fieldName: "access",
                minWidth: 50,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true
            },
            {
                key: "direction",
                name: "Direction",
                fieldName: "direction",
                minWidth: 50,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true
            },
            {
                key: "protocol",
                name: "Protocol",
                fieldName: "protocol",
                minWidth: 50,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true
            },
            {
                key: "sourceAddressPrefixes",
                name: "Source Address Prefixes",
                fieldName: "sourceAddressPrefixes",
                minWidth: 100,
                maxWidth: 150,
                isResizable: true,
                isRowHeader: true
            },
            {
                key: "sourcePortRanges",
                name: "Source Port Ranges",
                fieldName: "sourcePortRanges",
                minWidth: 100,
                maxWidth: 120,
                isResizable: true,
                isRowHeader: true
            },
            {
                key: "destinationAddressPrefixes",
                name: "Destination Address Prefixes",
                fieldName: "destinationAddressPrefixes",
                minWidth: 100,
                maxWidth: 150,
                isResizable: true,
                isRowHeader: true
            },
            {
                key: "destinationPortRanges",
                name: "Destination Port Ranges",
                fieldName: "destinationPortRanges",
                minWidth: 100,
                maxWidth: 120,
                isResizable: true,
                isRowHeader: true
            },
            {
                key: "totalScore",
                name: "Score",
                fieldName: "totalScore",
                minWidth: 50,
                maxWidth: 50,
                isResizable: true,
                isRowHeader: true
            },
            {
                key: "suggestion",
                name: "View Suggestions",
                fieldName: "suggestion",
                minWidth: 100,
                maxWidth: 120,
                isResizable: true,
                isRowHeader: true
            }

        ]
        return cols;
    }

    public getGridColumns() {
        switch (this.state.selectedGridType) {
            case MCSBSecurityGridType.NSGDetailGrid:
                return this.getNSGDetailsColumn();
            case MCSBSecurityGridType.NSGRuleGrid:
                return this.getNSGRuleDetailsColumn();
        }
    }

    public displaySuggestion = (ev: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement>, nsgRule: INSGRuleDetail) => {

        this.setState({
            isSuggestionVisible: true,
            selectedNSGRule: nsgRule
        })
    }

    public displaySuggestionNSG = (ev: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement>, nsgDetail: INSGDetail) => {

        this.setState({
            isNSGSuggestionVisible: true,
            selectedNSGSuggestion: nsgDetail
        })
    }

    public onDismissNSGRules = () => {
        this.setState({
            isSuggestionVisible: false,
            selectedNSGRule: undefined
        })
    }

    public onDismissNSGSuggestion = () => {
        this.setState({
            isNSGSuggestionVisible: false,
            selectedNSGSuggestion: undefined
        })
    }

    public onRenderItemColumnRuleGrid = (item: any, index?: number, column?: IColumn): JSX.Element | string | number => {
        if (!column) return "";
        if (column && column.key === 'suggestion') {
            return <PrimaryButton text="Suggestions"
                onClick={(ev) => {
                    this.displaySuggestion(ev, item)
                }}
            />;
        }
        return item[column?.key];
    };

    public onRenderItemColumnNSGGrid = (item: any, index?: number, column?: IColumn): JSX.Element | string | number => {
        if (!column) return "";
        if (column && column.key === 'suggestion') {
            return <PrimaryButton text="Suggestions"
                onClick={(ev) => {
                    this.displaySuggestionNSG(ev, item)
                }}
            />;
        }
        else if (column && column.key === 'nsgName') {
            return <div
                style={{ cursor: 'pointer' }}
                onClick={(ev) => {
                    this.getGridSelectionDetails(ev, item)
                }}
            >
                <u style={
                    {
                        color: '#0645AD'
                    }}
                >
                    {
                        item['nsgName']
                    }
                </u>
            </div>
        }
        else return item[column?.key];
    };

    public getGridProps(): IShimmeredDetailsListProps {
        const gridProp: IShimmeredDetailsListProps = {
            setKey: "set",
            items: this.props.nsgDetailsResponse?.nsgDetails ?? [],
            columns: this.getGridColumns(),
            selectionMode: 0,
            useReducedRowRenderer: true,
            isSelectedOnFocus: false,
            onRenderItemColumn: this.onRenderItemColumnNSGGrid,
        }
        return gridProp;
    }

    public getRuleGridProps(): IShimmeredDetailsListProps {
        const gridProp: IShimmeredDetailsListProps = {
            setKey: "set",
            items: this.props.nsgRuleDetails?.nsgRules ?? [],
            columns: this.getGridColumns(),
            useReducedRowRenderer: true,
            selectionMode: 0,
            isSelectedOnFocus: false,
            onRenderItemColumn: this.onRenderItemColumnRuleGrid,
        }
        return gridProp;
    }

    fetchData = (top: number, skip: number, filters?: IFilter[] | undefined, isFilterApplied?: boolean | undefined) => {
        this.setState({
            top: top,
            skip: skip
        }, () => {
            this.props.fetchNSGDetails({
                serviceTreeId: this.props.serviceTreeId,
                top: this.state.top,
                skip: this.state.skip,
                score: this.props.selectedScore
            })
        })
    }

    fetchRuleData = (top: number, skip: number, filters?: IFilter[] | undefined, isFilterApplied?: boolean | undefined) => {
        this.setState({
            ruleTop: top,
            ruleSkip: skip
        }, () => {
            if (this.state.selectedNSG)
                this.props.fetchNSGRuleDetails({
                    serviceTreeId: this.props.serviceTreeId,
                    top: this.state.ruleTop,
                    skip: this.state.skip,
                    nsgId: this.state.selectedNSG?.nsgId,
                    nsgServiceTreeId: this.state.selectedNSG?.serviceTreeId
                })
        })
    }

    onDismiss = () => {
        this.setState({
            ruleTop: 25,
            ruleSkip: 0,
            ruleCountPerPage: 25,
            selectedGridType: MCSBSecurityGridType.NSGDetailGrid,
            selectedNSG: undefined
        })
    }

    isDestinationPrefixSuggestionVisible = (): boolean => {
        if (this.state.selectedNSGRule?.direction == "Outbound") {
            if (this.state.selectedNSGRule.destinationAddressPrefixScore > 2)
                return true;
            return false;
        }
        else if (this.state.selectedNSGRule?.direction == "Both") {
            if (this.state.selectedNSGRule?.destinationAddressPrefixScore > 1)
                return true;
            return false;
        }
        return false;
    }

    isSourcePrefixSuggestionVisible = (): boolean => {
        if (this.state.selectedNSGRule?.direction == "Inbound") {
            if (this.state.selectedNSGRule.sourceAddressPrefixScore > 2)
                return true;
            return false;
        }
        else if (this.state.selectedNSGRule?.direction == "Both") {
            if (this.state.selectedNSGRule?.sourceAddressPrefixScore > 1)
                return true;
            return false;
        }
        return false;
    }
    getSuggestionPopup = (): JSX.Element => {
        return (
            <Dialog
                hidden={!this.state.isSuggestionVisible}
                onDismiss={this.onDismissNSGRules}
                styles={(): IDialogStyles => {
                    const style: IDialogStyles = {
                        root: {
                        },
                        main: {
                            top: '64px !important'
                        }
                    }
                    return style
                }}
                dialogContentProps={
                    {
                        type: DialogType.largeHeader,
                        title: ' Score Suggestions',
                        isMultiline: true,

                    }
                }
            >
                {
                    !((this.state.selectedNSGRule?.portScore ?? 0) > 3) && !this.isDestinationPrefixSuggestionVisible() && !this.isSourcePrefixSuggestionVisible() &&
                    <h5>{'Rule is compliant with the suggestions. No issues found'}</h5>
                }
                {(this.state.selectedNSGRule?.portScore ?? 0) > 3 &&
                    <>
                        <h5> Port score suggestion</h5>
                        <h6>Score: {this.state.selectedNSGRule?.portScore}</h6>
                        <p>{'The destination port contains one of the ports in \"*, 20, 21, 22, 23, 69, 111,119, 135, 161, 162, 445, 512, 514, 593, 873, 2049,3389, 5800, 5900, 11211\"'}</p>
                    </>
                }
                { 
                    this.isDestinationPrefixSuggestionVisible() &&
                    <>
                        <h5> Destination address prefix score suggestion</h5>
                        <h6>Score: {this.state.selectedNSGRule?.destinationAddressPrefixScore}</h6>
                        <p>{this.state.selectedNSGRule?.destinationAdressPrefixSuggestion}</p>
                    </>
                }
                {
                    this.isSourcePrefixSuggestionVisible() &&
                    (
                        <>
                            <h5> Source address prefix score suggestion</h5>
                            <h6>Score: {this.state.selectedNSGRule?.sourceAddressPrefixScore}</h6>
                            <p>{this.state.selectedNSGRule?.sourceAddressPrefixSuggestion}</p>
                        </>
                    )
                }
            </Dialog >
        )
    }

    getNSGSuggestionPopup = (): JSX.Element => {
        return (
            <Dialog
                hidden={!this.state.isNSGSuggestionVisible}
                onDismiss={this.onDismissNSGSuggestion}
                styles={(): IDialogStyles => {
                    const style: IDialogStyles = {
                        root: {
                        },
                        main: {
                            top: '64px !important'
                        }
                    }
                    return style
                }}
                dialogContentProps={
                    {
                        type: DialogType.largeHeader,
                        title: ' Score Suggestions',
                        isMultiline: true,

                    }
                }
            >
                {
                    !(this.state.selectedNSGSuggestion?.portScoreSuggestion && this.state.selectedNSGSuggestion?.portScoreSuggestion !== '')
                    && !(this.state.selectedNSGSuggestion?.destinationAdressPrefixSuggestion && this.state.selectedNSGSuggestion?.destinationAdressPrefixSuggestion !== '')
                    && !(this.state.selectedNSGSuggestion?.sourceAddressPrefixSuggestion && this.state.selectedNSGSuggestion?.sourceAddressPrefixSuggestion !== '') &&
                    <h5>{'Rule is compliant with the suggestions. No issues found'}</h5>
                }
                {
                    this.state.selectedNSGSuggestion?.portScoreSuggestion && this.state.selectedNSGSuggestion?.portScoreSuggestion !== '' &&
                    <>
                        <h5> Port score suggestion</h5>
                        <p>{this.state.selectedNSGSuggestion?.portScoreSuggestion}</p>
                    </>
                }
                {
                    this.state.selectedNSGSuggestion?.destinationAdressPrefixSuggestion && this.state.selectedNSGSuggestion?.destinationAdressPrefixSuggestion !== '' &&
                    <>
                        <h5> Destination address prefix score suggestion</h5>
                        <p>{this.state.selectedNSGSuggestion?.destinationAdressPrefixSuggestion}</p>
                    </>
                }
                {
                    this.state.selectedNSGSuggestion?.sourceAddressPrefixSuggestion && this.state.selectedNSGSuggestion?.sourceAddressPrefixSuggestion !== '' &&
                    (
                        <>
                            <h5> Source address prefix score suggestion</h5>
                            <p>{this.state.selectedNSGSuggestion?.sourceAddressPrefixSuggestion}</p>
                        </>
                    )
                }
            </Dialog >
        )
    }

    getGrid = (): JSX.Element => {
        return (
            this.state.selectedGridType == MCSBSecurityGridType.NSGDetailGrid ?
                <Grid className="mcsb-details-grid"
                    grid={this.getGridProps()} //Grid items
                    top={this.state.top}
                    skip={this.state.skip}
                    countPerPage={this.state.countPerPage}
                    maxPagestoShow={3}
                    updateCountPerPage={this.updateDisplayPerPage.bind(this)}
                    total={this.props.nsgDetailsResponse?.count}
                    isLoading={false}
                    isFilterVisible={this.state.showFilters}
                    noDataMessage={Resources.PolicyScreenNoDataMessage}
                    fetchData={this.fetchData.bind(this)}
                    isSearchBarEnabled={false}
                />
                :
                <Grid className="mcsb-rule-details-grid"
                    grid={this.getRuleGridProps()} //Grid items
                    top={this.state.ruleTop}
                    skip={this.state.ruleSkip}
                    countPerPage={this.state.ruleCountPerPage}
                    maxPagestoShow={3}
                    updateCountPerPage={this.updateDisplayPerPageRuleGrid.bind(this)}
                    total={this.props.nsgRuleDetails?.count}
                    isLoading={false}
                    isFilterVisible={this.state.showFilters}
                    noDataMessage={Resources.PolicyScreenNoDataMessage}
                    fetchData={this.fetchRuleData.bind(this)}
                    isSearchBarEnabled={false}
                />
        )
    }

    render(): JSX.Element {
        return (
            <>
                {this.state.isSuggestionVisible&&this.getSuggestionPopup()}
                {this.state.isNSGSuggestionVisible&&this.getNSGSuggestionPopup()}
                <Stack.Item>
                    <Stack horizontal horizontalAlign="space-between" >
                        <Stack.Item>
                            <div
                                style={{
                                    fontFamily: 'Segoe UI',
                                    fontSize: '18px',
                                    color: '#323130',
                                    padding: '13px',
                                    fontWeight: 600
                                }}>

                                {this.state.selectedGridType == MCSBSecurityGridType.NSGDetailGrid ?
                                    'Displaying NSG details for risk score ' + (this.props.selectedScore)
                                    : 'Displaying rule details for NSG ' + (this.state.selectedNSG?.nsgName)
                                }
                            </div>
                        </Stack.Item>
                        <Stack.Item>
                            <CommandBar
                                className="grid-chart-toggle"
                                items={[
                                    {
                                        id: 'ChartGridToggleButton',
                                        key: 'ChartGridToggleButton',
                                        role: 'menuitem',
                                        ariaLabel: 'Chart Grid Toggle Button',
                                        iconProps: { iconName: 'NavigateBack' },
                                        buttonStyles: { root: { backgroundColor: 'transparent' }, icon: { fontSize: '1.3rem' } },
                                        onClick: (ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
                                            this.state.selectedGridType == MCSBSecurityGridType.NSGDetailGrid ?
                                                this.props.onDismiss() :
                                                this.onDismiss()
                                        },
                                    }]}
                            />
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    {
                        this.props.isNSGDetailsLoading || this.props.isNSGRuleDetailsLoading ?
                            <DetailsShimmeredGrid />
                            :
                            this.getGrid()
                    }
                </Stack.Item>

            </>
        )
    }
}

