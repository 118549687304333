import { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { IColumnData } from "../../../common/charts/column/column.types";
import { IPieData } from "../../../common/charts/pie/pie.types";
import { fetchAntiForgeryTokenSuccess } from "../../../nmsInventory/store/action";
import AxiosInstance from "../../../services/interceptor";
import {fetchNSGDetailsFailure, fetchNSGDetailsSuccess, fetchNSGRuleDetailsFailure, fetchNSGRuleDetailsSuccess, fetchScoreDistributionFailure, fetchScoreDistributionSuccess, fetchServiceTreeBothDataFailure, fetchServiceTreeBothDataSuccess, fetchServiceTreeInboundDataFailure, fetchServiceTreeInboundDataSuccess, fetchServiceTreeOutboundDataFailure, fetchServiceTreeOutboundDataSuccess } from "./action";
import { FetchNSGDetails, FetchNSGRulesDetails, FetchScoreDistribution, FetchServiceTreeBothData, FetchServiceTreeInboundData, FetchServiceTreeOutboundData, IFetchNSGDetailsAction, IFetchNSGRulesDetailsAction, IFetchScoreDistributionAction, IFetchServiceTreeBothDataAction, IFetchServiceTreeInboundDataAction, IFetchServiceTreeOutboundDataAction } from "./action.types";
import { INS1DirectionChartResponse, INS1ScoreChartResponse, INSGDetailsResponse, INSGRuleDetailsResponse } from "./types/ns1.types";

export function* fetchScoreDistribution(action: Readonly<IFetchScoreDistributionAction>): Generator {
  try {
    const token = yield AxiosInstance.post<undefined>(`${window.location.origin}/api/antiforgery`);
    yield put(fetchAntiForgeryTokenSuccess());
    const result = (
      yield call(
        AxiosInstance.post,
        `${window.location.origin}/api/getscorechart`,
        {
          serviceTreeId: action.request.serviceTreeId
        }
      )) as AxiosResponse<INS1ScoreChartResponse>;
    yield put(fetchScoreDistributionSuccess(result.data));

  }
  catch (error) {
    yield put(
      fetchScoreDistributionFailure({
        name: 'fetchScoreDistributionFailure Error',
        message: `${'Internal error occured. Please try again.'}`,
      }),
    );
  }
}

export function* fetchServiceTreeInboundData(action: Readonly<IFetchServiceTreeInboundDataAction>): Generator {
  try {
    const token = yield AxiosInstance.post<undefined>(`${window.location.origin}/api/antiforgery`);
    yield put(fetchAntiForgeryTokenSuccess());
    const result = (
      yield call(
        AxiosInstance.post,
        `${window.location.origin}/api/getdirectionchart`,
        {
          serviceTreeId: action.request.serviceTreeId,
          direction: action.request.direction,
          top: action.request.top,
          skip: action.request.skip
        }
      )) as AxiosResponse<INS1DirectionChartResponse>;
    yield put(fetchServiceTreeInboundDataSuccess(result.data));
  }
  catch (error) {
    yield put(
      fetchServiceTreeInboundDataFailure({
        name: 'fetchServiceTreeInboundDataFailure Error',
        message: `${'Internal error occured. Please try again.'}`,
      }),
    );
  }
}

export function* fetchServiceTreeOutboundData(action: Readonly<IFetchServiceTreeOutboundDataAction>): Generator {
  try {
    const token = yield AxiosInstance.post<undefined>(`${window.location.origin}/api/antiforgery`);
    yield put(fetchAntiForgeryTokenSuccess());
    const result = (
      yield call(
        AxiosInstance.post,
        `${window.location.origin}/api/getdirectionchart`,
        {
          serviceTreeId: action.request.serviceTreeId,
          direction: action.request.direction,
          top: action.request.top,
          skip: action.request.skip
        }
      )) as AxiosResponse<INS1DirectionChartResponse>;
    yield put(fetchServiceTreeOutboundDataSuccess(result.data));
  }
  catch (error) {
    yield put(
      fetchServiceTreeOutboundDataFailure({
        name: 'fetchServiceTreeOutboundDataFailure Error',
        message: `${'Internal error occured. Please try again.'}`,
      }),
    );
  }
}

export function* fetchServiceTreeBothData(action: Readonly<IFetchServiceTreeBothDataAction>): Generator {
  try {
    const token = yield AxiosInstance.post<undefined>(`${window.location.origin}/api/antiforgery`);
    yield put(fetchAntiForgeryTokenSuccess());
    const result = (
      yield call(
        AxiosInstance.post,
        `${window.location.origin}/api/getdirectionchart`,
        {
          serviceTreeId: action.request.serviceTreeId,
          direction: action.request.direction,
          top: action.request.top,
          skip: action.request.skip
        }
      )) as AxiosResponse<INS1DirectionChartResponse>;
    yield put(fetchServiceTreeBothDataSuccess(result.data));
  }
  catch (error) {
    yield put(
      fetchServiceTreeBothDataFailure({
        name: 'fetchServiceTreeBothDataFailure Error',
        message: `${'Internal error occured. Please try again.'}`,
      }),
    );
  }
}

export function* fetchNSGDetails(action: Readonly<IFetchNSGDetailsAction>): Generator {
  try {
    const result = (
      yield call(
        AxiosInstance.post,
        `${window.location.origin}/api/getnsgdetails`,
        {
          serviceTreeId: action.request.serviceTreeId,
          top: action.request.top,
          skip: action.request.skip,
          score:action.request.score
        }
      )) as AxiosResponse<INSGDetailsResponse>;
    yield put(fetchNSGDetailsSuccess(result.data));
  }
  catch (error) {
    yield put(
      fetchNSGDetailsFailure({
        name: 'fetchNSGDetailsFailure Error',
        message: `${'Internal error occured. Please try again.'}`,
      }),
    );
  }
}

export function* fetchNSGRulesDetails(action: Readonly<IFetchNSGRulesDetailsAction>): Generator {
  try {
    const result = (
      yield call(
        AxiosInstance.post,
        `${window.location.origin}/api/nsgrulesdetails`,
        {
          serviceTreeId: action.request.serviceTreeId,
          top: action.request.top,
          skip: action.request.skip,
          nsgId: action.request.nsgId,
          nsgServiceTreeId: action.request.nsgServiceTreeId
        }
      )) as AxiosResponse<INSGRuleDetailsResponse>;
    yield put(fetchNSGRuleDetailsSuccess(result.data));
  }
  catch (error) {
    yield put(
      fetchNSGRuleDetailsFailure({
        name: 'fetchNSGRuleDetailsFailure Error',
        message: `${'Internal error occured. Please try again.'}`,
      }),
    );
  }
}


export function* fetchNS1Sagas(): Generator {
  yield takeLatest(FetchScoreDistribution, fetchScoreDistribution);
  yield takeLatest(FetchServiceTreeInboundData, fetchServiceTreeInboundData);
  yield takeLatest(FetchServiceTreeOutboundData, fetchServiceTreeOutboundData);
  yield takeLatest(FetchServiceTreeBothData, fetchServiceTreeBothData);
  yield takeLatest(FetchNSGDetails, fetchNSGDetails);
  yield takeLatest(FetchNSGRulesDetails, fetchNSGRulesDetails);
}