export const Resources = {
  //Title
  Title:"NMS",
  FWaaSPolicyTitle:"FWAAS Policy",
  
  //Header
  Logo: "EDGS",
  GenericErrorMessage: "We encountered an issue fetching this data. Please refresh the page",

  //Menu
  Services: "DIS Services",
  SecureEdge: "Secure Edge",
  Fwaas: "FWaaS",
  NMS: "NMS",

  //Secure Edge options
  SEOnboarding: "Secure Edge Onboarding",
  SEProvisioning: "Secure Edge Provisioning",
  Policies: "Policies",

  //FWaaS option
  FWDashboard: "FWaaS Dashboard",
  FWTraffic: "Traffic Monitor",
  FWPolicies: "Traffic Policies",

  //NMS
  NMSInventory: "Device Inventory",
  NMSDetails: "Device Details",

  //Home Page 
  WelcomeMessage: "Welcome, ",

  EDGSCardTitle: "About EDGS",
  EDGSCardBody: "Microsoft’s EDG Security (Edge + Platform, Devices and Gaming) takes care of some of Microsoft’s largest and most important online services including the Xbox LIVE, Microsoft Game Studios, and more. EDG Security DIS team looks after securing network, identity & isolation and manageability of high value infrastructure assets for Microsoft product groups such as Azure Edge, Operating Systems, Devices and Gaming.",

  MissionCardTitle: "Mission",
  MissionCardBody: "We secure our products and services and their supply chain, by building security solutions that are intelligent, integrated, scalable and extensible. We ensure and enable our products and offerings to be secure from birth and to stay secure",

  VisionCardTitle: "Vision",
  VisionCardBody: "Empower our partners to achieve best in class security for their customer offerings (across hardware, Software, and services) that are built on trust and rooted in a supply chain that is secure from code check-in to customer delivery. ",

  SecurityStrategyCardTitle: "Security Strategy",
  SecurityStrategyCardBody: "Ensure that our Products and Offerings are Trusted, Secure and Compliant",

  //Sign In Page
  SignInButton: "Sign In >",
  HelpText: "Need help signing in",
  Platform: "Microsoft EDGS Platform",


  //Footer
  Locale: "English (United States)",
  Contact: "Contact Microsoft",
  Privacy: "Privacy & Cookies",
  TermsOfUse: "Terms of use",
  Trademark: "Trademark",
  Safety: "Safety & Eco",
  AddPolicy: "About our ads",
  Copyright: "©Microsoft",
  ContactURL: "https://support.microsoft.com/en-US/contactus/",
  PrivacyURL: "https://go.microsoft.com/fwlink/?LinkId=521839",
  TermsOfUseURL: "https://www.microsoft.com/en-us/legal/intellectualproperty/copyright/default.aspx",
  TrademarkURL: "https://www.microsoft.com/en-us/legal/intellectualproperty/trademarks/en-us.aspx",
  SafetyURL: "https://www.microsoft.com/en-us/devices/safety-and-eco",
  AddPolicyURL: "https://account.microsoft.com/privacy/ad-settings/signedout?refd=www.microsoft.com&ru=https:%2F%2Faccount.microsoft.com%2Fprivacy%2Fad-settings%3Frefd%3Dwww.microsoft.com",
  PolicyUpdateSuccessMessage:"Policy update request submitted successfully",
  PolicyUpdateLockMessage:"There is an active request for policy update.",
  UpdateButtonText:"Update",
  SaveButtonText:"Save",
  ApplyButtonText:"Apply",
  EditPolicyPanelText:"Edit Policy",
  AddPolicyPanelText:"Add Policy",
  PolicyScreenNoDataMessage:"Please select a value from firewall to see the policies",
  HomeMessage:"Welcome to FWaaS policy page",
  ApprovalRequestSuccessMeassage:"Approved request: ",
  ApprovalRequestRejectMeassage:"Rejected request: ",
  FilterDevicePanelText:"Filter Devices",
  AddDevicePanelText:"Add Device",
  EditDevicePanelText:"Edit Device",

  //NMS Landing Page
  NMSLandingPageTitle: "NMS | Device Inventory",
  NMSLandingPageDescription: "Network Management solution for Fault/Configuration/Performance & Capacity management along with Visualization.  (Note: Any newly added devices details will be refreshed in next polling cycle)",
  NMSDetailPageTitle: "NMS | Device Details",
  DeviceUpdateSuccessMessage:"Device update request submitted successfully",
  DeviceEditSuccessMessage:"Device Edit request submitted successfully",
  DeviceDeleteSuccessMessage: "Device Delete request submitted successfully",
  DeviceDeleteNoDeviceSelectedTitle: "No Device Selected",
  DeviceDeleteNoDeviceSelectedMessage: "Please select a device to delete.",
  DeviceDeleteCheckTitle: "Device Delete",
  DeviceDeleteCheckMessage: "Are you sure you want to delete",
  DeviceDeleteMultipleCheckTitle: "Multiple Device Delete",
  DeviceDeleteMultipleCheckMessage: "Are you sure you want to delete these",


  //MCSB Securtity
  MCSBSecurityPageTitle: "MCSB | Network Security",
  MCSBSecurityPageDescription: "Details Network Security covers controls to secure and protect networks, including securing virtual networks, establishing private connections, preventing, and mitigating external attacks, and securing DNS.",
  MCSBNS1:"NS-1: Establish network segmentation boundaries",
  MCSBNS2:"NS-2: Secure cloud native services with network controls",
  MCSBNS3:"NS-3: Deploy firewall at the edge of enterprise network",
  MCSBNS4:"NS-4: Deploy intrusion detection/intrusion prevention systems (IDS/IPS)",
  MCSBNS5:"NS-5: Deploy DDOS protection",
  MCSBNS6:"NS-6: Deploy web application firewall",
  MCSBNS7:"NS-7: Simplify network security configuration",
  MCSBNS8:"NS-8: Detect and disable insecure services and protocols",
  MCSBNS9:"NS-9: Connect on-premises or cloud network privately",
  MCSBNS10:"NS-10: Ensure Domain Name System (DNS) security",
  MCSBNS1ShortHeader:"NS-1",
  MCSBNS2ShortHeader:"NS-2",
  MCSBNS3ShortHeader:"NS-3",
  MCSBNS4ShortHeader:"NS-4",
  MCSBNS5ShortHeader:"NS-5",
  MCSBNS6ShortHeader:"NS-6",
  MCSBNS7ShortHeader:"NS-7",
  MCSBNS8ShortHeader:"NS-8",
  MCSBNS9ShortHeader:"NS-9",
  MCSBNS10ShortHeader:"NS-10",
  MCSBScoreChartTitle:"risk score distribution \"Risk score on scale of 3 (lowest)-10 (highest)\"",
  MCSBSrviceTreeInboundChartTitle:"top 5 high risk Service tree for Inbound",
  MCSBSrviceTreeOutboundChartTitle:"top 5 high risk Service tree for Outbound",
  MCSBSrviceTreeBothChartTitle:"top 5 high risk Service tree for Both"
}