import { IColumn, IShimmeredDetailsListProps, getTheme, mergeStyleSets } from "@fluentui/react";
import { Action, compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { injectReducer, injectSaga } from "redux-injectors";
import { Resources } from "../../../../locales/resources";
import { IRootState } from "../../../../types";
import { DeepReadonly } from "../../../../types/base-types";
import { Grid } from "../../../common/grid";
import Loader from "../../../common/Loader/Loader";
import { deviceReducer } from "../../store/reducers";
import { devicedetailsSagas } from "../../store/sagas";
import { IVlansMapState, IVlansProps } from "../../store/types/nmsDeviceDetails.types";
import * as Actions from '../../store/action';
import { InmsDeviceDetailsState } from "../../store/state";

interface IVlansLocalState {
  top?: number,
  skip?: number,
  countPerPage?: number,

}


const theme = getTheme();
const classNames = mergeStyleSets({
  headerAndFooter: {
    //borderTop: `${1}px solid ${theme.palette.neutralQuaternary}`,
    //borderBottom: `${1}px solid ${theme.palette.neutralQuaternary}`,
    padding: 4,
    margin: `${1}px 0`,
    marginTop: 10,
    //background: theme.palette.neutralLighterAlt,
    backgroundColor: '#edebe9',
    // Overlay the sizer bars
    position: 'relative',
    zIndex: 100,
  },
  headerTitle: [
    theme.fonts.medium,
    {
      padding: '1px 0',
      marginLeft: '10px',
      fontWeight: 'bold'
    },
  ],

  headersubTitle: [
    theme.fonts.medium,
    {
      padding: '1px 0',
    },
  ],
  headerLinkSet: {
    margin: '4px -8px',
  },
  headerLink: {
    margin: '0 8px',
  },
});

export class VLansGrid extends React.Component<IVlansProps, IVlansLocalState> {
  constructor(props: IVlansProps) {
    super(props)
    this.state = {
      top: 25,
      skip: 0,
      countPerPage: 25,
    };
  }

  componentDidMount() {
    this.props.fetchVlansDetails({
      top: 25,
      skip: 0,
      deviceId: this.props.session.queryParam?.id ?? '',
      serviceTreeId: this.props.session?.serviceTree?.serviceTreeId ?? ''
    })
  }


  public getData(top?: number, skip?: number): void {
    this.setState((prevState) => {
      return { top: top, skip: skip }
    }
      , () => {
        this.props.fetchVlansDetails({
          top: this.state.top,
          skip: this.state.skip,
          deviceId: this.props.session.queryParam?.id ?? '',
          serviceTreeId: this.props.session?.serviceTree?.serviceTreeId ?? ''
        });
      }
    );
  }


  public componentDidUpdate(prevProps: IVlansProps) {

  }


  public getColumns = (): IColumn[] => {
    let cols: IColumn[] = [{
      key: "Vlan",
      name: "Vlan Name",
      fieldName: "vlan",
      minWidth: 50,
      maxWidth: 200,
      isRowHeader: true,
      isResizable: true
    },
    {
      key: "InterfaceDesc",
      name: "Vlan Description",
      fieldName: "interfaceDesc",
      minWidth: 50,
      maxWidth: 200,
      isRowHeader: true,
      isResizable: true
    },
    {
      key: "InterfaceIP",
      name: "Interface IP",
      fieldName: "interfaceIP",
      minWidth: 50,
      maxWidth: 200,
      isRowHeader: true,
      isResizable: true
    },
    {
      key: "OpsStatus",
      name: "Vlan Status",
      fieldName: "opsStatus",
      minWidth: 50,
      maxWidth: 200,
      isRowHeader: true,
      isResizable: true
    }]
    return cols;

  }


  public getGridProps(): IShimmeredDetailsListProps {
    const gridProp: IShimmeredDetailsListProps = {
      setKey: "set",
      items: this.props.vlansDetailsState?.IVlansDetailsResponse?.deviceVlans ?? [],  //this.getGridFilteredData(),//this.state.policy ? [...this.state.policy] : [],
      columns: this.getColumns(),

    }

    return gridProp
  }



  public updateDisplayPerPage(key: number) {
    this.setState({ top: key, skip: 0, countPerPage: key }, () => {
      this.props.fetchVlansDetails({
        top: this.state.top,
        skip: this.state.skip,
        deviceId: this.props.session.queryParam?.id ?? '',
        serviceTreeId: this.props.session?.serviceTree?.serviceTreeId ?? ''
      });
    });
  }


  render(): JSX.Element {

    return (
      <>
        <div className={classNames.headerAndFooter}>
          <div className={classNames.headerTitle}>{this.props.session.queryParam?.name}</div>
        </div>
        {
          this.props.vlansDetailsState?.isVlansDetailsLoading ?
            <div className="app-loading-icon" style={{ minHeight: '400px' }}>
              <Loader />
            </div> :
            <>

              <Grid className="nms-details-grid"
                grid={this.getGridProps()} //Grid items
                top={this.state.top}
                skip={this.state.skip}
                countPerPage={this.state.countPerPage}
                maxPagestoShow={3}
                updateCountPerPage={this.updateDisplayPerPage.bind(this)}
                total={this.props.vlansDetailsState?.IVlansDetailsResponse?.count ?? 0}
                isLoading={false}
                //actionBarItem={this.getCommandBarItems()} //top bars
                //isFilterVisible={this.state.showFilters} //Filter show hide
                //filters={this.getFilterData()}   //Filter options
                noDataMessage={Resources.PolicyScreenNoDataMessage}  //When no data
                fetchData={this.getData.bind(this)}  //to re-trigger the data fetch for filter. function to get data.
                //selectedFilter={this.state.filters}  //applied filters.
                //isGridVisible={this.isGridVisible}  //Grid fileter boolean
                //onActiveItemChanged={this.activeItemChanged} //radio button to call actions
                isSearchBarEnabled={false}   //filtered on visible items
                //searchBarSearchCallback={this.searchBarCallback}  // data filterration in seach bar
                isSearchBarActive={false}  // disabled when no data....
              />
            </>
        }
      </>
    );
  }
}

export const mapStateToProps = (state: DeepReadonly<IRootState>): DeepReadonly<IVlansMapState> => ({
  vlansDetailsState: state.nmsDeviceDetailsState,
  session: state.session,
  microUI: state.microUI
});

const mapDispatchToProps = {
  fetchVlansDetails: Actions.fetchVlansDetails,
};

export default compose<React.ComponentType>(
  injectReducer({
    key: 'nmsDeviceDetailsState',//Keep this same in IRoot, and props interface
    reducer: deviceReducer as React.Reducer<DeepReadonly<InmsDeviceDetailsState>, DeepReadonly<Action>>,
  }),
  injectSaga({ key: 'nmsDeviceDetailsState', saga: devicedetailsSagas }),
  connect(mapStateToProps, mapDispatchToProps),
)
  (VLansGrid);