import { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import AxiosInstance from "../../services/interceptor";
import { deleteNMSDeviceDetailsFailure, deleteNMSDeviceDetailsSuccess, editNMSDeviceDetailsFailure, editNMSDeviceDetailsSuccess, fetchAntiForgeryTokenSuccess, fetchDataCentersListFailure, fetchDataCentersListSuccess, fetchDeviceTypeListFailure, fetchDeviceTypeListSuccess, fetchEnvironmentsListFailure, fetchEnvironmentsListSuccess, fetchNMSDeviceInventoryFailure, fetchNMSDeviceInventorySuccess, fetchStateListFailure, fetchStateListSuccess, updateNMSDeviceDetailsFailure, updateNMSDeviceDetailsSuccess } from "./action";
import { DeleteNMSDeviceDetails, EditNMSDeviceDetails, FetchDataCentersList, FetchDeviceTypeList, FetchEnvironmentsList, FetchNMSDeviceInventory, FetchStateList, IDeleteNMSDeviceDetailsAction, IEditNMSDeviceDetailsAction, IFetchDataCentersListAction, IFetchDeviceTypeListAction, IFetchEnvironmentsListAction, IFetchNMSDeviceInventoryAction, IFetchStateListAction, IUpdateNMSDeviceDetailsAction, UpdateNMSDeviceDetails } from "./actionTypes";
import { IDataCentersDetails, IDeviceTypeDetails, IEnvironmentsDetails, INMSDevInentoryResponse, IStateDetails } from "./types/nmsDeviceInventory.types";


export function* loadNMSDeviceInventory(action: Readonly<IFetchNMSDeviceInventoryAction>): Generator {
  try {
    const token = yield AxiosInstance.post<undefined>(`${window.location.origin}/api/antiforgery`);
    yield put(fetchAntiForgeryTokenSuccess());
    const rawData = yield AxiosInstance.post<INMSDevInentoryResponse>(`${window.location.origin}/api/devinventorylist`,
      {
        top: action.request.top,
        skip: action.request.skip,
        filters: action.request.filters,
        //serviceTreeId: action.request.serviceTreeId,
        searchText:action.request.searchText,
        dcSearchText:action.request.dcSearchText,
        enSearchText:action.request.enSearchText
      });
    const result = rawData as AxiosResponse<INMSDevInentoryResponse>;
    yield put(fetchNMSDeviceInventorySuccess(result.data));
  }
  catch (error) {
    yield put(fetchNMSDeviceInventoryFailure(error as Error));
  }
}

//for add/update device
export function* updateNMSDeviceDetails(action: Readonly<IUpdateNMSDeviceDetailsAction>): Generator {
  try {
    const rawData = yield AxiosInstance.post<boolean>(`${window.location.origin}/api/adddevice`,  
      {
        ...action.request
      });
    const result = rawData as AxiosResponse<boolean>;
    yield put(updateNMSDeviceDetailsSuccess(result.data));
  }
  catch (error) {
    yield put(updateNMSDeviceDetailsFailure(error as Error));
  }
}


//for edit device
export function* editNMSDeviceDetails(action: Readonly<IEditNMSDeviceDetailsAction>): Generator {
  try {
    const rawData = yield AxiosInstance.post<boolean>(`${window.location.origin}/api/editdevice`,  
      {
        ...action.request
      });
    const result = rawData as AxiosResponse<boolean>;
    yield put(editNMSDeviceDetailsSuccess(result.data));
  }
  catch (error) {
    yield put(editNMSDeviceDetailsFailure(error as Error));
  }
}


//for Delete device
export function* deleteNMSDeviceDetails(action: Readonly<IDeleteNMSDeviceDetailsAction>): Generator {
  try {
    const rawData = yield AxiosInstance.post<boolean>(`${window.location.origin}/api/deletedevice`,

      {
        deviceIds: action.request.deviceIds,
        //serviceTreeId: action.request.serviceTreeId
      }
    );
    const result = rawData as AxiosResponse<boolean>;
    yield put(deleteNMSDeviceDetailsSuccess(result.data));
  }
  catch (error) {
    yield put(deleteNMSDeviceDetailsFailure(error as Error));
  }
}

//For DataCenters list
export function* loadDataCentersList(action: Readonly<IFetchDataCentersListAction>): Generator {
  try {
    const rawData = (yield call(AxiosInstance.get, `${window.location.origin}/api/getdatacenterslist`,
      {
        params: {
          //serviceTreeId: action.request.serviceTreeId??''
        }
      })) as AxiosResponse<IDataCentersDetails[]>;
    yield put(fetchDataCentersListSuccess(rawData.data));
  }
  catch (error) {
    yield put(fetchDataCentersListFailure(error as Error));
  }
}

//For DeviceType list
export function* loadDeviceTypeList(action: Readonly<IFetchDeviceTypeListAction>): Generator {
  try {
    const rawData = (yield call(AxiosInstance.get, `${window.location.origin}/api/getdeviceslist`,
      {
        params: {
          //serviceTreeId: action.request.serviceTreeId??''
        }
      })) as AxiosResponse<IDeviceTypeDetails[]>;
    yield put(fetchDeviceTypeListSuccess(rawData.data));
  }
  catch (error) {
    yield put(fetchDeviceTypeListFailure(error as Error));
  }
}

//For Environments list
export function* loadEnvironmentsList(action: Readonly<IFetchEnvironmentsListAction>): Generator {
  try {
    const rawData = (yield call(AxiosInstance.get, `${window.location.origin}/api/getenvironmentslist`,
      {
        params: {
          //serviceTreeId: action.request.serviceTreeId??''
        }
      })) as AxiosResponse<IEnvironmentsDetails[]>;
    yield put(fetchEnvironmentsListSuccess(rawData.data));
  }
  catch (error) {
    yield put(fetchEnvironmentsListFailure(error as Error));
  }
}


//For State list
export function* loadStateList(action: Readonly<IFetchStateListAction>): Generator {
  try {
    const rawData = (yield call(AxiosInstance.get, `${window.location.origin}/api/getdevicestatelist`,
      {
        params: {
          //serviceTreeId: action.request.serviceTreeId??''
        }
      })) as AxiosResponse<IStateDetails[]>;
    yield put(fetchStateListSuccess(rawData.data));
  }
  catch (error) {
    yield put(fetchStateListFailure(error as Error));
  }
}


//MUST validate this
export function* devicedetailsSagas(): Generator {
  yield takeLatest(FetchNMSDeviceInventory, loadNMSDeviceInventory);
  yield takeLatest(UpdateNMSDeviceDetails, updateNMSDeviceDetails);
  yield takeLatest(EditNMSDeviceDetails, editNMSDeviceDetails);
  yield takeLatest(DeleteNMSDeviceDetails, deleteNMSDeviceDetails);
  yield takeLatest(FetchDataCentersList, loadDataCentersList);
  yield takeLatest(FetchDeviceTypeList, loadDeviceTypeList);
  yield takeLatest(FetchEnvironmentsList, loadEnvironmentsList)
  yield takeLatest(FetchStateList, loadStateList)
}