import { MessageBar, MessageBarType } from "@fluentui/react";
import React from "react";
import { IErrorProps } from "./error.types";

export class ErrorBar extends React.Component<IErrorProps> {
  render(): JSX.Element {
    return (
      <div className={`error-message-bar-${this.props.className}`}>
        <MessageBar
          dismissButtonAriaLabel="Close"
          isMultiline={true}
          messageBarIconProps={{ iconName: 'ErrorBadge' }}
          messageBarType={MessageBarType.error}>
          <div aria-label={this.props.error}>
            {this.props.error}
          </div>
        </MessageBar>
      </div>
    )

  }
}