import { IVlansDetailsRequest, IVlansDetailsResponse } from "./types/nmsDeviceDetails.types";
import * as types from './actionTypes';
import { DeepReadonly } from "../../../types/base-types";
import { IInterfaceDetailsRequest, IInterfaceDetailsResponse } from "./types/nmsInterfaceTab.types";
import { ISummaryTabDetailsRequest, ISummaryTabDetailsResponse } from "./types/nmsSummaryTab.types";
import { IBGPDetailsRequest, IBGPDetailsResponse, IBGPPrefixListRequest, IBGPPrefixListResponse } from "./types/bgpTab.types";


//*****VlanTab*****
export const fetchVlansDetails = (request: IVlansDetailsRequest): types.IFetchVlansDetailsAction => {
  const action: types.IFetchVlansDetailsAction = {
    type: types.FetchVlansDetails,
    request: request
  };
  return action;
};

export const fetchVlansDetailsSuccess = (
  payload: DeepReadonly<IVlansDetailsResponse>,
): DeepReadonly<types.IFetchVlansDetailsSuccessAction> => {
  const action: DeepReadonly<types.IFetchVlansDetailsSuccessAction> = {
    type: types.FetchVlansDetailsSuccess,
    payload,
  };
  return action;
};

export const fetchVlansDetailsFailure = (
  error: DeepReadonly<Error>,
): types.IFetchVlansDetailsFailureAction => {
  const action: types.IFetchVlansDetailsFailureAction = {
    type: types.FetchVlansDetailsFailure,
    payload: error,
  };

  return action;
};


//****InterfaceTab*****
export const fetchInterfaceDetails = (request: IInterfaceDetailsRequest): types.IFetchInterfaceDetailsAction => {
  const action: types.IFetchInterfaceDetailsAction = {
    type: types.FetchInterfaceDetails,
    request: request
  };
  return action;
};

export const fetchInterfaceDetailsSuccess = (
  payload: DeepReadonly<IInterfaceDetailsResponse>,
): DeepReadonly<types.IFetchInterfaceDetailsSuccessAction> => {
  const action: DeepReadonly<types.IFetchInterfaceDetailsSuccessAction> = {
    type: types.FetchInterfaceDetailsSuccess,
    payload,
  };
  return action;
};

export const fetchInterfaceDetailsFailure = (
  error: DeepReadonly<Error>,
): types.IFetchInterfaceDetailsFailureAction => {
  const action: types.IFetchInterfaceDetailsFailureAction = {
    type: types.FetchInterfaceDetailsFailure,
    payload: error,
  };

  return action;
};


//****SummaryTab*****
export const fetchSummaryTabDetails = (request: ISummaryTabDetailsRequest): types.IFetchSummaryTabDetailsAction => {
  const action: types.IFetchSummaryTabDetailsAction = {
    type: types.FetchSummaryTabDetails,
    request: request
  };
  return action;
};

export const fetchSummaryTabDetailsSuccess = (
  payload: DeepReadonly<ISummaryTabDetailsResponse>,
): DeepReadonly<types.IFetchSummaryTabDetailsSuccessAction> => {
  const action: DeepReadonly<types.IFetchSummaryTabDetailsSuccessAction> = {
    type: types.FetchSummaryTabDetailsSuccess,
    payload,
  };
  return action;
};

export const fetchSummaryTabDetailsFailure = (
  error: DeepReadonly<Error>,
): types.IFetchSummaryTabDetailsFailureAction => {
  const action: types.IFetchSummaryTabDetailsFailureAction = {
    type: types.FetchSummaryTabDetailsFailure,
    payload: error,
  };

  return action;
};


//****BGP Details*****
export const fetchBGPDetails = (request: IBGPDetailsRequest): types.IFetchBGPDetailsAction => {
  const action: types.IFetchBGPDetailsAction = {
    type: types.FetchBGPDetails,
    request: request
  };
  return action;
};

export const fetchBGPDetailsSuccess = (
  payload: DeepReadonly<IBGPDetailsResponse[]>,
): DeepReadonly<types.IFetchBGPDetailsSuccessAction> => {
  const action: DeepReadonly<types.IFetchBGPDetailsSuccessAction> = {
    type: types.FetchBGPDetailsSuccess,
    payload,
  };
  return action;
};

export const fetchBGPDetailsFailure = (
  error: DeepReadonly<Error>,
): types.IFetchBGPDetailsFailureAction => {
  const action: types.IFetchBGPDetailsFailureAction = {
    type: types.FetchBGPDetailsFailure,
    payload: error,
  };

  return action;
};


//****BGP Prefix List*****
export const fetchBGPPrefixList = (request: IBGPPrefixListRequest): types.IFetchBGPPrefixListAction => {
  const action: types.IFetchBGPPrefixListAction = {
    type: types.FetchBGPPrefixList,
    request: request
  };
  return action;
};

export const fetchBGPPrefixListSuccess = (
  payload: DeepReadonly<IBGPPrefixListResponse>,
): DeepReadonly<types.IFetchBGPPrefixListSuccessAction> => {
  const action: DeepReadonly<types.IFetchBGPPrefixListSuccessAction> = {
    type: types.FetchBGPPrefixListSuccess,
    payload,
  };
  return action;
};

export const fetchBGPPrefixListFailure = (
  error: DeepReadonly<Error>,
): types.IFetchBGPPrefixListFailureAction => {
  const action: types.IFetchBGPPrefixListFailureAction = {
    type: types.FetchBGPPrefixListFailure,
    payload: error,
  };

  return action;
};