import { Maybe } from "../../../types/base-types";
import { IDataCentersDetails, IDeviceTypeDetails, IEnvironmentsDetails, INMSDevInentoryResponse, IStateDetails } from "./types/nmsDeviceInventory.types";


export interface IDeviceState {
  isDeviceIventoryLoading: boolean;
  deviceInventoryLoadError: Maybe<Error>
  INMSDevInentoryResponse: Maybe<INMSDevInentoryResponse>;
  isDeviceInventoryDataSaved: Maybe<boolean>;
  nmsDeviceDetailsUpdateError: Maybe<Error>;
  nmsDeviceDetailsEditError: Maybe<Error>;
  isUpdateDeviceDetailsDataSaved: Maybe<boolean>;
  isEditDeviceDetailsDataSaved: Maybe<boolean>;
  isDeleteDeviceDetailsDataSaved: Maybe<boolean>;
  isDataCentersListLoading: boolean;
  dataCentersList: Maybe<IDataCentersDetails[]>;
  dataCentersListLoadError: Maybe<Error>;
  isDeviceTypeListLoading: boolean;
  deviceTypeList: Maybe<IDeviceTypeDetails[]>;
  deviceTypeListLoadError: Maybe<Error>;
  isEnvironmentsListLoading: boolean;
  environmentsList: Maybe<IEnvironmentsDetails[]>;
  environmentsListLoadError: Maybe<Error>;
  isStateListLoading: boolean;
  stateList: Maybe<IStateDetails[]>;
  stateListLoadError: Maybe<Error>;
  isTokenLoading: boolean;
  istokenFetched:boolean;
  tokenLoadingError: Maybe<Error>
}

export const initialDeviceState: IDeviceState = {
  isDeviceIventoryLoading: false,
  deviceInventoryLoadError: undefined,
  INMSDevInentoryResponse: undefined,
  nmsDeviceDetailsUpdateError: undefined,
  nmsDeviceDetailsEditError: undefined,
  isDeviceInventoryDataSaved: undefined,
  isUpdateDeviceDetailsDataSaved: undefined,
  isEditDeviceDetailsDataSaved: undefined,
  isDeleteDeviceDetailsDataSaved: undefined,
  isDataCentersListLoading: false,
  dataCentersList: undefined,
  dataCentersListLoadError: undefined,
  isDeviceTypeListLoading: false,
  deviceTypeList: undefined,
  deviceTypeListLoadError: undefined,
  isEnvironmentsListLoading: false,
  environmentsList: undefined,
  environmentsListLoadError: undefined,
  isStateListLoading: false,
  stateList: undefined,
  stateListLoadError: undefined,
  isTokenLoading: false,
  istokenFetched:false,
  tokenLoadingError: undefined,
}


