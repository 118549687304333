import React from "react";
import { IPagableGridProps } from "./grid.types";
import { GridActionHeader } from "./gridActionHeader";
import { GridFooter } from "./gridFooter";
import { GridHeader } from "./gridHeader";
import { ShimmeredGrid } from "./shimmeredGrid";
import { GridFilter } from "./gridFilter";
export class Grid extends React.Component<IPagableGridProps> {

  state = { selectedPage: 1 };

  private getSelectedPageNumber = (): number => {
    if (this.props.skip && this.props.countPerPage)
      return (this.props.skip / this.props.countPerPage) + 1;
    return 1;
  }

  render(): JSX.Element {
    return (
      <>
        {
          this.props.actionBarItem ?
            <GridActionHeader
              className={this.props.className}
              isLoading={this.props.isLoading}
              item={this.props.actionBarItem}
              isSearchBarEnabled={this.props.isSearchBarEnabled}
              searchBarSearchCallback={this.props.searchBarSearchCallback}
              onClearSearchCallback={this.props.onClearSearchCallback}
              toggle={this.props.toggle}
              searchBoxText={this.props.searchBoxText}
            />
            :
            <></>
        }
        {
          this.props.filters && this.props.isFilterVisible ?
            <GridFilter
              className={this.props.className}
              filters={this.props.filters}
              fetchData={this.props.fetchData}
              fetchAllData={this.props.fetchAllData}
              countPerPage={this.props.countPerPage}
              selectedFilter={this.props.selectedFilter}
              customApplyEnableCheck={this.props.customApplyEnableCheck}

            />
            : <></>
        }

        {
          this.props.top !== undefined && this.props.skip !== undefined && this.props.countPerPage !== undefined && this.props.total !== undefined && this.props.updateCountPerPage !== undefined ?
            <GridFooter
              className={this.props.className}
              isLoading={this.props.isLoading}
              maxPagestoShow={this.props.maxPagestoShow ?? 3}
              countPerPage={this.props.countPerPage}
              total={this.props.total}
              selectedPageNumber={this.getSelectedPageNumber()}
              fetchData={this.props.fetchData}
            /> :
            <></>
        }

        {this.props.isGridVisible === undefined || this.props.isGridVisible() ?
          <>{
            this.props.top !== undefined && this.props.skip !== undefined && this.props.countPerPage !== undefined && this.props.total !== undefined && this.props.updateCountPerPage !== undefined ?
              <GridHeader
                className={this.props.className}
                isLoading={this.props.isLoading}
                skip={this.props.skip}
                countPerPage={this.props.countPerPage}
                total={this.props.total}
                updateCountPerPage={this.props.updateCountPerPage}
              /> : <></>
          }
            <ShimmeredGrid
              grid={this.props.grid}
              onActiveItemChangeCallback={this.props.onActiveItemChanged}
            />
            {
              this.props.top !== undefined && this.props.skip !== undefined && this.props.countPerPage !== undefined && this.props.total !== undefined && this.props.updateCountPerPage !== undefined ?
                <GridFooter
                  className={this.props.className}
                  isLoading={this.props.isLoading}
                  maxPagestoShow={this.props.maxPagestoShow ?? 3}
                  countPerPage={this.props.countPerPage}
                  total={this.props.total}
                  selectedPageNumber={this.getSelectedPageNumber()}
                  fetchData={this.props.fetchData}
                /> :
                <></>
            }
          </>
          : <>{this.props.noDataMessage ?? "No data available"}</>
        }
      </>
    );
  }
}