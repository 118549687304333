import {  mergeStyles, Shimmer, ShimmerElementType } from "@fluentui/react";
import React from "react";

const getGridHeaderStyle = (className): string => {
  return mergeStyles(
    {
      displayName: 'paginationContainer',
      alignItems: 'center',
      display: 'flex',
      margin: '20px 0px',
      padding: '14px 16px',
      width: 'auto',
      minHeight: '48px',
      marginTop: '4px',
      backgroundColor: '#edebe9',
      fontFamily: 'Segoe UI',
      fontSize: '15px'
    },
    className,
  );
};

const wrapperClass = mergeStyles({
  padding: 2,
  selectors: {
    '& > .ms-Shimmer-container': {
      margin: '10px 0',
    },
  },
});

const shimmerHeaderRow = [
  { type: ShimmerElementType.line, height: 20, width: '35%' },
  { type: ShimmerElementType.gap, width: '2%' },
  { type: ShimmerElementType.line, height: 20, width: '60%' },
  { type: ShimmerElementType.gap, width: '2%' },
  { type: ShimmerElementType.line, height: 20, width: '5%' },
];

export class DetailsShimmeredGrid extends React.Component {
  render(): JSX.Element {
    return (
      <div  className={wrapperClass}>
        <div className={`${getGridHeaderStyle('approval-grid-shimmer-header')}`}>
          
        </div>
        <Shimmer shimmerElements={shimmerHeaderRow} />
        <Shimmer shimmerElements={shimmerHeaderRow} />
        <Shimmer shimmerElements={shimmerHeaderRow} />
        <Shimmer shimmerElements={shimmerHeaderRow} />
        <Shimmer shimmerElements={shimmerHeaderRow} />
        <Shimmer shimmerElements={shimmerHeaderRow} />
        <Shimmer shimmerElements={shimmerHeaderRow} />
        <Shimmer shimmerElements={shimmerHeaderRow} />
        <Shimmer shimmerElements={shimmerHeaderRow} />
        <Shimmer shimmerElements={shimmerHeaderRow} />
        <Shimmer shimmerElements={shimmerHeaderRow} />
        <Shimmer shimmerElements={shimmerHeaderRow} />
      </div>
    )
  }
}