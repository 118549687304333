import { Action } from "@reduxjs/toolkit";
import { DeepReadonly } from "../../../types/base-types";
import { IBGPDetailsRequest, IBGPDetailsResponse, IBGPPrefixListRequest, IBGPPrefixListResponse } from "./types/bgpTab.types";
import { IVlansDetailsRequest, IVlansDetailsResponse } from "./types/nmsDeviceDetails.types";
import { IInterfaceDetailsRequest, IInterfaceDetailsResponse } from "./types/nmsInterfaceTab.types";
import { ISummaryTabDetailsRequest, ISummaryTabDetailsResponse } from "./types/nmsSummaryTab.types";

export const FetchVlansDetails = 'FETCH_VLANS_DETAILS_RULES';
export const FetchVlansDetailsSuccess = 'FETCH_VLANS_DETAILS_RULES_SUCCESS';
export const FetchVlansDetailsFailure = 'FETCH_VLANS_DETAILS_RULES_FAILURE';
export const FetchInterfaceDetails = 'FETCH_INTERFACE_DETAILS_RULES';
export const FetchInterfaceDetailsSuccess = 'FETCH_INTERFACE_DETAILS_RULES_SUCCESS';
export const FetchInterfaceDetailsFailure = 'FFETCH_INTERFACE_DETAILS_RULES_FAILURE';
export const FetchSummaryTabDetails = 'FETCH_SUMMARY_TAB_DETAILS_RULES';
export const FetchSummaryTabDetailsSuccess = 'FETCH_SUMMARY_TAB_DETAILS_RULES_SUCCESS';
export const FetchSummaryTabDetailsFailure = 'FFETCH_SUMMARY_TAB_DETAILS_RULES_FAILURE';
export const FetchBGPDetails = 'FETCH_BGP_DETAILS_RULES';
export const FetchBGPDetailsSuccess = 'FETCH_BGP_DETAILS_RULES_SUCCESS';
export const FetchBGPDetailsFailure = 'FFETCH_BGP_DETAILS_RULES_FAILURE';
export const FetchBGPPrefixList = 'FETCH_BGP_PREFIX_LIST_RULES';
export const FetchBGPPrefixListSuccess = 'FETCH_BGP_PREFIX_LIST_RULES_SUCCESS';
export const FetchBGPPrefixListFailure = 'FFETCH_BGP_PREFIX_LIST_RULES_FAILURE';

//Action Interface To Fetch VlanTab Device
export interface IFetchVlansDetailsAction extends Action<typeof FetchVlansDetails> {
  request: DeepReadonly<IVlansDetailsRequest>;
}

export interface IFetchVlansDetailsSuccessAction extends Action<typeof FetchVlansDetailsSuccess> {
  payload: DeepReadonly<IVlansDetailsResponse>;
}

export interface IFetchVlansDetailsFailureAction extends Action<typeof FetchVlansDetailsFailure> {
  payload: Error;
}


//Action Interface To Fetch InterfaceTab Device
export interface IFetchInterfaceDetailsAction extends Action<typeof FetchInterfaceDetails> {
  request: DeepReadonly<IInterfaceDetailsRequest>;
}

export interface IFetchInterfaceDetailsSuccessAction extends Action<typeof FetchInterfaceDetailsSuccess> {
  payload: DeepReadonly<IInterfaceDetailsResponse>;
}

export interface IFetchInterfaceDetailsFailureAction extends Action<typeof FetchInterfaceDetailsFailure> {
  payload: Error;
}


//Action Interface To Fetch Summary Device
export interface IFetchSummaryTabDetailsAction extends Action<typeof FetchSummaryTabDetails> {
  request: DeepReadonly<ISummaryTabDetailsRequest>;
}

export interface IFetchSummaryTabDetailsSuccessAction extends Action<typeof FetchSummaryTabDetailsSuccess> {
  payload: DeepReadonly<ISummaryTabDetailsResponse>;
}

export interface IFetchSummaryTabDetailsFailureAction extends Action<typeof FetchSummaryTabDetailsFailure> {
  payload: Error;
}


//Action Interface To Fetch BGP Device
export interface IFetchBGPDetailsAction extends Action<typeof FetchBGPDetails> {
  request: DeepReadonly<IBGPDetailsRequest>;
}

export interface IFetchBGPDetailsSuccessAction extends Action<typeof FetchBGPDetailsSuccess> {
  payload: DeepReadonly<IBGPDetailsResponse[]>;
}

export interface IFetchBGPDetailsFailureAction extends Action<typeof FetchBGPDetailsFailure> {
  payload: Error;
}


//Action Interface To Fetch BGP Prefix List
export interface IFetchBGPPrefixListAction extends Action<typeof FetchBGPPrefixList> {
  request: DeepReadonly<IBGPPrefixListRequest>;
}

export interface IFetchBGPPrefixListSuccessAction extends Action<typeof FetchBGPPrefixListSuccess> {
  payload: DeepReadonly<IBGPPrefixListResponse>;
}

export interface IFetchBGPPrefixListFailureAction extends Action<typeof FetchBGPPrefixListFailure> {
  payload: Error;
}


export type DeviceActions =
  | IFetchVlansDetailsAction
  | IFetchVlansDetailsSuccessAction
  | IFetchVlansDetailsFailureAction
  | IFetchInterfaceDetailsAction
  | IFetchInterfaceDetailsSuccessAction
  | IFetchInterfaceDetailsFailureAction
  | IFetchSummaryTabDetailsAction
  | IFetchSummaryTabDetailsSuccessAction
  | IFetchSummaryTabDetailsFailureAction
  | IFetchBGPDetailsAction
  | IFetchBGPDetailsSuccessAction
  | IFetchBGPDetailsFailureAction
  | IFetchBGPPrefixListAction
  | IFetchBGPPrefixListSuccessAction
  | IFetchBGPPrefixListFailureAction