import { IShimmerElement, Shimmer, ShimmerElementType } from "@fluentui/react";
import React from "react";
import { IColumnChart } from "../column.types";
import Chart from "react-apexcharts";
import { ApexOptions } from 'apexcharts';
import { Maybe } from "../../../../../types/base-types";

export class ColumnChartVisual extends React.Component<IColumnChart> {
  private colorSeries = [
    '#004E8C',
    '#8764B8',
    '#0099BC',
    '#77004D',
    '#0E7878',
    '#AE8C00',
    '#E43BA6',
    '#4F6BED',
    '#881798',
    '#9C663F',
    '#E6F69D',
    '#AADEA7',
    '#64C2A6',
    '#2D87BB',
    '#2900A5',
    '#9552EA',
    '#93F03B',
    '#F54F52',
    '#FFA32F',
    '#378AFF',
  ];

  private getOptions = (): ApexOptions => {
    return ({
      colors: this.colorSeries,
      chart: {
        id: "basic-bar",

        toolbar: {
          show: false
        }
      },
      xaxis: {
        categories: this.props.categories,
        labels:{
          trim:true,
          rotate:0,
          hideOverlappingLabels:false,
          showDuplicates:true
        }
      },
    })
  }

  private getSeries = (): Maybe<ApexAxisChartSeries> => {
    return ([{
      name: this.props.name,
      data: this.props.data??[]
    }])
  }

  render(): JSX.Element {
    return (
      <Chart
        type="bar"
        series={this.getSeries()}
        options={this.getOptions()}
        height={this.props.height}
      />
    )
  }
}