import { Reducer } from 'redux';
import { initialSessionState, ISessionState } from './state';
import {
  QueryParamFetched,
  ServiceTreeIdFetched,
  SessionActions,
  UserLoaded,
} from './actions';
import { DeepReadonly } from '../../types/base-types';


export const sessionReducer: Reducer<ISessionState, SessionActions> = (
  state: DeepReadonly<ISessionState> = initialSessionState,
  action: DeepReadonly<SessionActions>,
): DeepReadonly<ISessionState> => {
  switch (action.type) {
    case UserLoaded:
      return {
        ...state,
        user: action.payload,
      };
      case ServiceTreeIdFetched:
        return{
          ...state,
          serviceTree:action.payload
        }
        case QueryParamFetched:{
          return{
            ...state,
            queryParam:action.payload
          }
        }
    default:
      return state;
  }
};
