/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Router, Route, Switch } from 'react-router-dom';
import { history } from '../utility/history';
import Loader from './common/Loader/Loader';
import { IAppMapState, IAppProps } from './store/types/appMap';
import { IRootState } from '../types';
import { DeepReadonly } from '../types/base-types';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { Resources } from '../locales/resources';
import InventoryGrid from './nmsInventory/Index';
import { DevDetails } from './nmsDeviceDetails';
import { MCSBSecurity } from './mcsbSecurity';

export class App extends React.Component<IAppProps> {

  render(): JSX.Element {
    return (
      <>
        <Helmet
          defaultTitle={Resources.Title}
          titleTemplate={`%s - ${Resources.Title}`}>
          <meta content={Resources.Title} name="description" />
        </Helmet>

        {
          /*this.props.session?.serviceTree?.serviceTreeId && this.props.microUI && this.props.microUI.isEnabled ?*/
          this.props.microUI && this.props.microUI.isEnabled ?
            <div style={{ minHeight: '480px' }}>
              <Router history={history}>
                <Switch>
                  <Route component={() => (<InventoryGrid />)} path="/nmsdeviceinventory" />
                  <Route component={() => (<DevDetails />)} path="/nmsdevicedetails" />
                  <Route component={() => (<MCSBSecurity />)} path="/mcsbsecurity" />
                </Switch>
              </Router>
            </div>
            : <div className="app-loading-icon">
              {'Welcome to NMS'}
              <Loader />
            </div>
        }
      </>
    );
  }
}

export const mapStateToProps = (state: DeepReadonly<IRootState>): DeepReadonly<IAppMapState> => ({
  microUI: state.microUI,
  session: state.session
});

export default compose<React.ComponentType>(
  connect(mapStateToProps),
)(App);