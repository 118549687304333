import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { history } from './utility/history';
import ReactResizeDetector from 'react-resize-detector';
// Import root app
import App from './app/index';
import { HelmetProvider } from 'react-helmet-async';
import { configureAppStore } from './store/configureStore';
import AppContext from './utility/AppContext';

import {
  Fabric,
  IFabricProps,
  initializeIcons
} from '@fluentui/react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { StoreEnhancer } from 'redux';
import { MicroUIService } from './utility/@microUI/service';
import { DeepReadonly } from './types/base-types';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: () => StoreEnhancer<{}, {}>;
    appContext: AppContext;
  }
}

// Initialize icons
initializeIcons();

// Create redux store with history
const store = configureAppStore(history);

//  Initialize AppContext
window.appContext = new AppContext(
  new MicroUIService((window as unknown) as DeepReadonly<Window>, store),
);

const MountNode = document.getElementById('root') as HTMLElement;

const fabricProps: IFabricProps = {
  dir: 'ltr',
};


interface IProps {
  Component: typeof App;
}

function ConnectedApp({ Component }: IProps): JSX.Element {

  return (
    <Fabric {...fabricProps}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <HelmetProvider>
            <ReactResizeDetector
              handleHeight
              handleWidth
              onResize={(_w, _h): void => {
                window.appContext.microUIResize();
              }}
            />
            <Component />
          </HelmetProvider>
        </ConnectedRouter>
      </Provider>
    </Fabric>
  );
}

const render = (Component: typeof App): void => {
  ReactDOM.render(<ConnectedApp Component={Component} />, MountNode);
};

if (module.hot) {
  /*
   * Hot reloadable translation json files and app
   * modules.hot.accept does not accept dynamic dependencies,
   * have to be constants at compile-time
   */
  module.hot.accept(['./app'], () => {
    ReactDOM.unmountComponentAtNode(MountNode);
    const app = require('./app').default;
    render(app);
  });
}

render(App);