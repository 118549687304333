import React from "react";
import { mergeStyles,IDropdownOption, Dropdown } from '@fluentui/react';
import { IPagableGridHeaderProps } from "../grid.types";

const getGridHeaderStyle = (className): string => {
  return mergeStyles(
    {
      displayName: 'paginationContainer',
      alignItems: 'center',
      display: 'flex',
      margin: '4px 0px',
      padding: '4px 16px',
      width: 'auto',
      minHeight: '48px',
      marginTop: '4px',
      backgroundColor: '#edebe9',
      fontFamily: 'Segoe UI',
      fontSize: '15px'
    },
    className,
  );
};

export class GridHeader extends React.Component<IPagableGridHeaderProps> {
  private getDefaultFilterOptions(): IDropdownOption[] {
    return [
      {
        key: 25,
        text: `25 per page`,
      },
      {
        key: 50,
        text: `50 per page`,
      },
      {
        key: 75,
        text: `75 per page`,
      },
      {
        key: 100,
        text: `100 per page`
      }
    ];
  };

  private getdisplayMessage(): React.ReactElement {
    const endIndex = this.props.skip + this.props.countPerPage > this.props.total ? this.props.total : this.props.skip + this.props.countPerPage;
    return <>{`Showing ${this.props.skip + 1} - ${endIndex} of ${this.props.total} filtered results`}</>
  }

  private getDropDown=(): React.ReactElement =>{
    return (
      <Dropdown
        options={this.getDefaultFilterOptions()}
        onChange={(_event: React.FormEvent<HTMLDivElement>, newValue): void => {
          this.props.updateCountPerPage(newValue?.key as number)
        }}
        role="combobox"
        selectedKey={this.props.countPerPage}
      />)
  }

  render(): JSX.Element {
    return (
      <>
        {
          <div className={`${getGridHeaderStyle(this.props.className)}`}>
            <span style={{ width: '85%' }}>{this.getdisplayMessage()}</span>
            <span>{`Displaying: `}</span>
            <span>{this.getDropDown()}</span>
          </div>
        }
      </>
    );
  }
}