import { Icon, IShimmerElement, Shimmer, ShimmerElementsGroup, ShimmerElementType } from "@fluentui/react";
import React from "react";
import { Resources } from "../../../../locales/resources";
import { ErrorBar } from "../../error";
import { IColumnChartProps } from "./column.types";
import { ColumnChartVisual } from "./visuals/columnChartVisual";
import { ShimmerColumnChart } from "./visuals/shimmerColumnChart";

export class ColumnChart extends React.Component<IColumnChartProps> {

  render(): JSX.Element {
    return (
      <>
        {
          this.props.isLoading ?
            <ShimmerColumnChart />
            :
            this.props.error ?
              <div style={{ height: this.props.chart.height }}>
                <ErrorBar error={this.props.showDefaultErrorMessage ? Resources.GenericErrorMessage : this.props.error} className='Pie' /> </div> :

              <>
                {!this.props.chart?.data || this.props.chart?.data?.length == 0 || !this.props.chart?.categories || this.props.chart?.categories?.length == 0 ?
                  <div style={{ height: this.props.chart.height, textAlign: 'center', justifyContent: 'center', justifyItems: 'center',marginBottom:'15px' }}><Icon iconName='Info' styles={{ root: { fontSize: '1rem' } }} />{" No data available"}</div> :
                  <ColumnChartVisual {...this.props.chart} />
                }
              </>
        }
      </>
    )
  }
}