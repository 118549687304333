import { Reducer } from "@reduxjs/toolkit";
import { DeepReadonly } from "../../../types/base-types";
import { DeviceActions } from "./actionTypes";
import { initialnmsDeviceDetailsState, InmsDeviceDetailsState } from "./state";
import * as types from './actionTypes';


export const deviceReducer: Reducer<DeepReadonly<InmsDeviceDetailsState>, DeepReadonly<DeviceActions>> = (
  state: DeepReadonly<InmsDeviceDetailsState> = initialnmsDeviceDetailsState,
  action: DeepReadonly<DeviceActions>,
): DeepReadonly<InmsDeviceDetailsState> => {
  switch (action.type) {
    case types.FetchVlansDetails: {
      return {
        ...state,
        isVlansDetailsLoading: true,
        vlansDetailsLoadError: undefined,
        IVlansDetailsResponse: undefined

      };
    }
    case types.FetchVlansDetailsSuccess: {
      let a=
      {
        ...state,
        isVlansDetailsLoading: false,
        vlansDetailsLoadError: undefined,
        IVlansDetailsResponse: action.payload,
      };
      return a;
    }

    case types.FetchVlansDetailsFailure: {
      return {
        ...state,
        isVlansDetailsLoading: false,
        vlansDetailsLoadError: action.payload,
        IVlansDetailsResponse: undefined
      };
    };

    case types.FetchInterfaceDetails: {
      return {
        ...state,
        isInterfaceDetailsLoading: true,
        interfaceDetailsLoadError: undefined,
        IInterfaceDetailsResponse: undefined

      };
    }

    case types.FetchInterfaceDetailsSuccess: {
      let a=
      {
        ...state,
        isInterfaceDetailsLoading: false,
        interfaceDetailsLoadError: undefined,
        IInterfaceDetailsResponse: action.payload,
      };
      return a;
    }

    case types.FetchInterfaceDetailsFailure: {
      return {
        ...state,
        isInterfaceDetailsLoading: false,
        interfaceDetailsLoadError: action.payload,
        IInterfaceDetailsResponse: undefined
      };
    };

    case types.FetchSummaryTabDetails: {
      return {
        ...state,
        isSummaryTabDetailsLoading: true,
        summaryTabDetailsLoadError: undefined,
        ISummaryTabDetailsResponse: undefined,

      };
    }

    case types.FetchSummaryTabDetailsSuccess: {
      let a=
      {
        ...state,
        isSummaryTabDetailsLoading: false,
        summaryTabDetailsLoadError: undefined,
        ISummaryTabDetailsResponse: action.payload,
      };
      return a;
    }

    case types.FetchSummaryTabDetailsFailure: {
      return {
        ...state,
        isSummaryTabDetailsLoading: false,
        summaryTabDetailsLoadError: action.payload,
        ISummaryTabDetailsResponse: undefined
      };
    };

    case types.FetchBGPDetails: {
      return {
        ...state,
        isBGPDetailsLoading: true,
        bgpDetailsLoadError: undefined,
        IBGPDetailsResponse: undefined

      };
    }

    case types.FetchBGPDetailsSuccess: {
      let a=
      {
        ...state,
        isBGPDetailsLoading: false,
        bgpDetailsLoadError: undefined,
        IBGPDetailsResponse: action.payload,
      };
      return a;
    }

    case types.FetchBGPDetailsFailure: {
      return {
        ...state,
        isBGPDetailsLoading: false,
        bgpDetailsLoadError: action.payload,
        IBGPDetailsResponse: undefined
      };
    };


    case types.FetchBGPPrefixList: {
      return {
        ...state,
        isBGPPrefixListLoading: true,
        bgpPrefixListLoadError: undefined,
        IBGPPrefixListResponse: undefined

      };
    }

    case types.FetchBGPPrefixListSuccess: {
      let a=
      {
        ...state,
        isBGPPrefixListLoading: false,
        bgpPrefixListLoadError: undefined,
        IBGPPrefixListResponse: action.payload,
      };
      return a;
    }

    case types.FetchBGPPrefixListFailure: {
      return {
        ...state,
        isBGPPrefixListLoading: false,
        bgpPrefixListLoadError: action.payload,
        IBGPPrefixListResponse: undefined
      };
    };


    default:
    return state;
}
};


