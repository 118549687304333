import React from "react";
import { ShimmeredDetailsList, TooltipHost, TooltipOverflowMode } from '@fluentui/react';
import { IShimeredGridProps } from "../grid.types";

export class ShimmeredGrid extends React.Component<IShimeredGridProps> {

  customItemRender = (item, number, column) => {
    return (
      <TooltipHost id={item[column.fieldName]} content={item[column.fieldName]} overflowMode={TooltipOverflowMode.Parent}>
        <span aria-describedby={column.fieldName}>{item[column.fieldName]}</span>
      </TooltipHost>
    );
  }
  render(): JSX.Element {
    return (
      <>
        {
          <>
            <ShimmeredDetailsList
              {...this.props.grid}
              onActiveItemChanged={
                (item: any) => {
                  if (this.props.onActiveItemChangeCallback !== undefined)
                    this.props.onActiveItemChangeCallback(item);
                }}
                styles={{root:{userSelect: 'auto'}}}
              onRenderItemColumn={this.props.grid.onRenderItemColumn ?? this.customItemRender}
            />
          </>
        }
      </>
    );
  }
}