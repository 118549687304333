import { CommandBar, Dropdown, IBreadcrumbItem, IColumn, ICommandBarItemProps, Icon, IDropdownOption, IDropdownStyles, IShimmeredDetailsListProps, IStackTokens, MessageBar, MessageBarType, Panel, PrimaryButton, Stack, TextField } from '@fluentui/react';
import { Action, compose } from '@reduxjs/toolkit';
import React from "react";
import { Resources } from '../../../locales/resources';
import { IRootState } from '../../../types';
import { DeepReadonly, Maybe } from '../../../types/base-types';
import * as Actions from './store/action'
import { injectReducer, injectSaga } from 'redux-injectors';
import { connect } from 'react-redux';
import { INS1State } from './store/state';
import { fetchNS1Sagas } from './store/sagas';
import { ns1Reducer } from './store/reducer';
import { INS1MapState, INS1Props, MCSBSecurityGridType, NSGDirection } from './store/types/ns1.types';
import { PieChart } from '../../common/charts/pie';
import { ChartTile } from './visuals/chartTile';
import { NSGDetailsGrid } from './visuals/detailsGrid/nsgDetailsGrid';
import { IFilter } from '../../common/grid/grid.types';

export interface INS1LocalState {
  isGridSelected: boolean
  selectedScore: Maybe<string>
  top: number
  skip: number
}

export class NS1 extends React.Component<INS1Props, INS1LocalState> {
  private chartHeight: string;
  private itemStyles: React.CSSProperties = {
    alignItems: 'center',
    display: 'flex',
    height: 50,
    justifyContent: 'center',
    width: 50,
    margin: '20px'
  };

  constructor(props: INS1Props) {
    super(props);
    this.state = {
      isGridSelected: false,
      selectedScore: undefined,
      top: 25,
      skip: 0
    }
    this.chartHeight = '480px'
  }

  componentDidMount(): void {
    if (this.props.session.serviceTree?.serviceTreeId) {
      !this.props.ns1Sate.isSeverityPieChartLoading && this.props.fetchScoreDistribution
        (
          {
            serviceTreeId: this.props.session.serviceTree?.serviceTreeId
          }
        )
      !this.props.ns1Sate.isServiceTreeInboundDataLoading && this.props.fetchServiceTreeInboundData({
        serviceTreeId: this.props.session.serviceTree?.serviceTreeId,
        top: 5,
        skip: 0,
        direction: NSGDirection.Inbound
      })
      !this.props.ns1Sate.isServiceTreeOutboundDataLoading && this.props.fetchServiceTreeOutboundData({
        serviceTreeId: this.props.session.serviceTree?.serviceTreeId,
        top: 5,
        skip: 0,
        direction: NSGDirection.Outbound
      })
      !this.props.ns1Sate.isServiceTreeBothDataLoading && this.props.fetchServiceTreeBothData({
        serviceTreeId: this.props.session.serviceTree?.serviceTreeId,
        top: 5,
        skip: 0,
        direction: NSGDirection.Both
      })
    }
  }

  mediumStackTokens: IStackTokens = {
    childrenGap: 'l1',
    padding: 'l1',
  };


  onDismiss = () => {
    this.setState({
      isGridSelected: false
    })
  }

  dataPointSelection = (event, chartContext, config) => {
    this.setState({
      isGridSelected: true,
      selectedScore: config.dataPointIndex
    })
  }

  getCharts = (): JSX.Element => {
    return (
      <>
        <Stack.Item>
          <Stack
            horizontal
            styles={{
              root: {
                marginTop: '8px'
              }
            }}>
            {/* Pie chart */}
            <Stack.Item
              grow
              tokens={this.mediumStackTokens}
              styles={{
                root: {
                  marginRight: '4px',
                  maxWidth: '1000%'
                }
              }}>
              <ChartTile
                Title={(this.props.ns1Sate?.severityScoreChartData?.organization??'')+' '+Resources.MCSBScoreChartTitle}
                IsPieChart={true}
                dataPointSelection={(event, chartContext, config) => this.dataPointSelection(event, chartContext, config)}
                isLoading={this.props.ns1Sate.isSeverityPieChartLoading}
                pieData={this.props.ns1Sate.severityScoreChartData?.pieData}
                error={undefined}
              />
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Stack
            horizontal
            styles={{
              root: {
                marginTop: '8px'
              }
            }}>
            <Stack.Item
              grow
              styles={{
                root: {
                  marginLeft: '4px',
                  maxWidth: '100%'
                }
              }}>
              <ChartTile
                Title={(this.props.ns1Sate?.serviceTreeInboundChartData?.organization??'')+' '+Resources.MCSBSrviceTreeInboundChartTitle}
                IsPieChart={false}
                isLoading={this.props.ns1Sate.isServiceTreeInboundDataLoading}
                columnChartData={this.props.ns1Sate.serviceTreeInboundChartData?.columnChartData}
                error={this.props.ns1Sate.serviceTreeInboundChartError}
              />
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Stack
            horizontal
            styles={{
              root: {
                marginTop: '8px'
              }
            }}>
            {/* Pie chart */}
            <Stack.Item
              grow={3}
              styles={{
                root:
                {
                  marginRight: '4px',
                  maxWidth: '50%'
                }
              }}>
              <ChartTile
                Title={(this.props.ns1Sate?.serviceTreeOutboundChartData?.organization??'')+' '+Resources.MCSBSrviceTreeOutboundChartTitle}
                IsPieChart={false}
                isLoading={this.props.ns1Sate.isServiceTreeOutboundDataLoading}
                columnChartData={this.props.ns1Sate?.serviceTreeOutboundChartData?.columnChartData}
                error={this.props.ns1Sate.serviceTreeOutboundChartError}
              />
            </Stack.Item>
            <Stack.Item
              grow={3}
              styles={{
                root: {
                  marginLeft: '4px',
                  maxWidth: '50%'
                }
              }}>
              <ChartTile
                Title={(this.props.ns1Sate?.serviceTreeBothChartData?.organization??'')+' '+Resources.MCSBSrviceTreeBothChartTitle}
                IsPieChart={false}
                isLoading={this.props.ns1Sate.isServiceTreeBothDataLoading}
                columnChartData={this.props.ns1Sate.serviceTreeBothChartData?.columnChartData}
                error={this.props.ns1Sate.serviceTreeBothChartError}
              />
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </>
    )
  }

  getGrids = (): JSX.Element => {
    return (
      <>
        <Stack.Item>
          <NSGDetailsGrid
            onDismiss={this.onDismiss}
            selectedScore={(this.props.ns1Sate?.severityScoreChartData?.pieData?.labels && this.props.ns1Sate?.severityScoreChartData?.pieData?.labels[this.state.selectedScore ?? 0])}
            serviceTreeId={this.props.session.serviceTree?.serviceTreeId ?? ''}
            isNSGDetailsLoading={this.props.ns1Sate.isNSGDetailsLoading}
            isNSGRuleDetailsLoading={this.props.ns1Sate.isNSGRuleDetailsLoading}
            nsgDetailsError={this.props.ns1Sate.nsgDetailsError}
            nsgRuleDetailsError={this.props.ns1Sate.nsgRuleDetailsError}
            nsgDetailsResponse={this.props.ns1Sate.nsgDetailsResponse}
            nsgRuleDetails={this.props.ns1Sate.nsgRuleDetails}
            fetchNSGDetails={this.props.fetchNSGDetails}
            fetchNSGRuleDetails={this.props.fetchNSGRuleDetails}
          />
        </Stack.Item>
      </>
    )
  }

  render(): JSX.Element {
    return (
      <Stack>
        <Stack.Item
          styles={{
            root: {
              backgroundImage: 'linear-gradient(to right, #0064c1, #4c92d3,#dfe3e6)',
              padding: '20px 10px 10px 10px',
              color: '#fff',
              height: '200px'
            }
          }}>
          <Stack horizontal>
            <Stack.Item styles={{ root: { width: '85%' } }}>
              <span style={{ font: '18px Segoe UI' }}>{Resources.MCSBSecurityPageTitle}</span>
              <p style={{ font: '14px Segoe UI', paddingTop: '10px' }}>{Resources.MCSBSecurityPageDescription}</p>
            </Stack.Item>
            <Stack.Item>
              <Icon iconName='HardDriveLock' styles={{ root: { fontSize: '6rem' } }} />
            </Stack.Item>
          </Stack>
        </Stack.Item>
        {
          this.state.isGridSelected ?
            this.getGrids() :
            this.getCharts()
        }
      </Stack>
    );
  }
}

export const mapStateToProps = (state: DeepReadonly<IRootState>): DeepReadonly<INS1MapState> => ({
  session: state.session,
  ns1Sate: state.ns1State
});

const mapDispatchToProps = {
  fetchScoreDistribution: Actions.fetchScoreDistribution,
  fetchServiceTreeInboundData: Actions.fetchServiceTreeInboundData,
  fetchServiceTreeOutboundData: Actions.fetchServiceTreeOutboundData,
  fetchServiceTreeBothData: Actions.fetchServiceTreeBothData,
  fetchNSGDetails: Actions.fetchNSGDetails,
  fetchNSGRuleDetails: Actions.fetchNSGRuleDetails
};

export default compose<React.ComponentType>(
  injectReducer({
    key: 'ns1State',//Keep this same in IRoot, and props interface
    reducer: ns1Reducer as React.Reducer<DeepReadonly<INS1State>, DeepReadonly<Action>>,
  }),
  injectSaga({ key: 'ns1State', saga: fetchNS1Sagas }),
  connect(mapStateToProps, mapDispatchToProps),
)(NS1);