import { Action } from "@reduxjs/toolkit";
import { DeepReadonly } from "../../../types/base-types";
import { IDataCentersDetails, IDataCentersRequest, IDeviceTypeDetails, IDeviceTypeRequest, IEnvironmentsDetails, IEnvironmentsRequest, INMSDeviceDetailsDeleteRequest, INMSDeviceDetailsEditRequest, INMSDeviceDetailsUpdateRequest, INMSDevInentoryRequest, INMSDevInentoryResponse, INMSMultiDeviceEditRequest, IStateDetails, IStateRequest } from "./types/nmsDeviceInventory.types";




export const FetchNMSDeviceInventory = 'FETCH_NETWORK_POLICY_RULES';
export const FetchNMSDeviceInventorySuccess = 'FETCH_NETWORK_POLICY_RULES_SUCCESS';
export const FetchNMSDeviceInventoryFailure = 'FETCH_NETWORK_POLICY_RULES_FAILURE';
export const UpdateNMSDeviceDetails = 'UPDATE_NMS_DEVICE_DETAILS';
export const UpdateNMSDeviceDetailsSuccess = 'UPDATE_NMS_DEVICE_DETAILS_SUCCESS';
export const UpdateNMSDeviceDetailsFailure = 'UPDATE_NMS_DEVICE_DETAILS_FAILURE';
export const EditNMSDeviceDetails = 'EDIT_NMS_DEVICE_DETAILS';
export const EditNMSDeviceDetailsSuccess = 'EDIT_NMS_DEVICE_DETAILS_SUCCESS';
export const EditNMSDeviceDetailsFailure = 'EDIT_NMS_DEVICE_DETAILS_FAILURE';
export const DeleteNMSDeviceDetails = 'DELETE_NMS_DEVICE_DETAILS';
export const DeleteNMSDeviceDetailsSuccess = 'DELETE_NMS_DEVICE_DETAILS_SUCCESS';
export const DeleteNMSDeviceDetailsFailure = 'DELETE_NMS_DEVICE_DETAILS_FAILURE';
export const FetchDataCentersList = 'FETCH_DATA_CENTERS_LIST';
export const FetchDataCentersListSuccess = 'FETCH_DATA_CENTERS_LIST_SUCCESS';
export const FetchDataCentersListFailure = 'FETCH_DATA_CENTERS_LIST_FAILIURE';
export const FetchDeviceTypeList = 'FETCH_DEVICE_TYPE_LIST';
export const FetchDeviceTypeListSuccess = 'FETCH_DEVICE_TYPE_LIST_SUCCESS';
export const FetchDeviceTypeListFailure = 'FETCH_DEVICE_TYPE_LIST_FAILIURE';
export const FetchEnvironmentsList = 'FETCH_ENVIRONMENTS_LIST';
export const FetchEnvironmentsListSuccess = 'FETCH_ENVIRONMENTS_LIST_SUCCESS';
export const FetchEnvironmentsListFailure = 'FETCH_ENVIRONMENTS_LIST_FAILIURE';
export const FetchStateList = 'FETCH_STATE_LIST';
export const FetchStateListSuccess = 'FETCH_STATE_LIST_SUCCESS';
export const FetchStateListFailure = 'FETCH_STATE_LIST_FAILIURE';
export const FetchAntiForgeryToken = 'FETCH_ANTI_FORGERY_TOKEN';
export const FetchAntiForgeryTokenSuccess = 'FETCH_ANTI_FORGERY_TOKEN_SUCCESS';
export const FetchAntiForgeryTokenFailed = 'FETCH_ANTI_FORGERY_TOKEN_FAILED';

//Action Interface To Fetch NMS Device
export interface IFetchNMSDeviceInventoryAction extends Action<typeof FetchNMSDeviceInventory> {
  request: DeepReadonly<INMSDevInentoryRequest>;
}

export interface IFetchNMSDeviceInventorySuccessAction extends Action<typeof FetchNMSDeviceInventorySuccess> {
  payload: DeepReadonly<INMSDevInentoryResponse>;
}

export interface IFetchNMSDeviceInventoryFailureAction extends Action<typeof FetchNMSDeviceInventoryFailure> {
  payload: Error;
}

//Action Interface to update NMS Device Details
export interface IUpdateNMSDeviceDetailsAction extends Action<typeof UpdateNMSDeviceDetails> {
  request: DeepReadonly<INMSDeviceDetailsUpdateRequest>;
}

export interface IUpdateNMSDeviceDetailsSuccessAction extends Action<typeof UpdateNMSDeviceDetailsSuccess> {
  payload: DeepReadonly<boolean>;
}

export interface IUpdateNMSDeviceDetailsFailureAction extends Action<typeof UpdateNMSDeviceDetailsFailure> {
  payload: Error;
}

//Action Interface to Edit NMS Device Details
export interface IEditNMSDeviceDetailsAction extends Action<typeof EditNMSDeviceDetails> {
  request: DeepReadonly<INMSMultiDeviceEditRequest>;
}

export interface IEditNMSDeviceDetailsSuccessAction extends Action<typeof EditNMSDeviceDetailsSuccess> {
  payload: DeepReadonly<boolean>;
}

export interface IEditNMSDeviceDetailsFailureAction extends Action<typeof EditNMSDeviceDetailsFailure> {
  payload: Error;
}

//Action Interface to Delete NMS Device Details
export interface IDeleteNMSDeviceDetailsAction extends Action<typeof DeleteNMSDeviceDetails> {
  request: DeepReadonly<INMSDeviceDetailsDeleteRequest>;
}

export interface IDeleteNMSDeviceDetailsSuccessAction extends Action<typeof DeleteNMSDeviceDetailsSuccess> {
  payload: DeepReadonly<boolean>;
}

export interface IDeleteNMSDeviceDetailsFailureAction extends Action<typeof DeleteNMSDeviceDetailsFailure> {
  payload: Error;
}

//Action interface for fetch Data Centers list
export interface IFetchDataCentersListAction extends Action<typeof FetchDataCentersList> {
  request: DeepReadonly<IDataCentersRequest>;
}

export interface IFetchDataCentersListSuccessAction extends Action<typeof FetchDataCentersListSuccess> {
  payload: DeepReadonly<IDataCentersDetails[]>;
}

export interface IFetchDataCentersListFailureAction extends Action<typeof FetchDataCentersListFailure> {
  payload: Error;
}

//Action interface for fetch Device Type list
export interface IFetchDeviceTypeListAction extends Action<typeof FetchDeviceTypeList> {
  request: DeepReadonly<IDeviceTypeRequest>;
}

export interface IFetchDeviceTypeListSuccessAction extends Action<typeof FetchDeviceTypeListSuccess> {
  payload: DeepReadonly<IDeviceTypeDetails[]>;
}

export interface IFetchDeviceTypeListFailureAction extends Action<typeof FetchDeviceTypeListFailure> {
  payload: Error;
}

//Action interface for Environments list
export interface IFetchEnvironmentsListAction extends Action<typeof FetchEnvironmentsList> {
  request: DeepReadonly<IEnvironmentsRequest>;
}

export interface IFetchEnvironmentsListSuccessAction extends Action<typeof FetchEnvironmentsListSuccess> {
  payload: DeepReadonly<IEnvironmentsDetails[]>;
}

export interface IFetchEnvironmentsListFailureAction extends Action<typeof FetchEnvironmentsListFailure> {
  payload: Error;
}

//Action interface for State list
export interface IFetchStateListAction extends Action<typeof FetchStateList> {
  request: DeepReadonly<IStateRequest>;
}

export interface IFetchStateListSuccessAction extends Action<typeof FetchStateListSuccess> {
  payload: DeepReadonly<IStateDetails[]>;
}

export interface IFetchStateListFailureAction extends Action<typeof FetchStateListFailure> {
  payload: Error;
}

//Action interface for Anti Forgery list
export interface IFetchAntiForgeryTokenAction extends Action<typeof FetchAntiForgeryToken> {
}

export interface IFetchAntiForgeryTokenActionSuccess extends Action<typeof FetchAntiForgeryTokenSuccess> {
}

export interface IFetchAntiForgeryTokenActionFailed extends Action<typeof FetchAntiForgeryTokenFailed> {
  payload: Error;
}
export type DeviceActions =
  | IFetchNMSDeviceInventoryAction
  | IFetchNMSDeviceInventorySuccessAction
  | IFetchNMSDeviceInventoryFailureAction
  | IUpdateNMSDeviceDetailsAction
  | IUpdateNMSDeviceDetailsSuccessAction
  | IUpdateNMSDeviceDetailsFailureAction
  | IEditNMSDeviceDetailsAction
  | IEditNMSDeviceDetailsSuccessAction
  | IEditNMSDeviceDetailsFailureAction
  | IDeleteNMSDeviceDetailsAction
  | IDeleteNMSDeviceDetailsSuccessAction
  | IDeleteNMSDeviceDetailsFailureAction
  | IFetchDataCentersListAction
  | IFetchDataCentersListSuccessAction
  | IFetchDataCentersListFailureAction
  | IFetchDeviceTypeListAction
  | IFetchDeviceTypeListSuccessAction
  | IFetchDeviceTypeListFailureAction
  | IFetchEnvironmentsListAction
  | IFetchEnvironmentsListSuccessAction
  | IFetchEnvironmentsListFailureAction
  | IFetchStateListAction
  | IFetchStateListSuccessAction
  | IFetchStateListFailureAction
  | IFetchAntiForgeryTokenAction
  | IFetchAntiForgeryTokenActionSuccess
  | IFetchAntiForgeryTokenActionFailed