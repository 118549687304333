import { Maybe } from "../../../../../types/base-types";
import { ISessionState } from "../../../../../utility/@core";
import { IColumnData } from "../../../../common/charts/column/column.types";
import { IPieData } from "../../../../common/charts/pie/pie.types";
import { INS1State } from "../state";

export interface INS1MapState {
  ns1Sate: INS1State;
  session: ISessionState;
}

export interface INSGDetail {
  serviceTreeId: string,
  serviceTreeName: string,
  subscriptionId: string,
  nicName: string,
  subnetName: string,
  nsgName: string,
  nsgId: string,
  score: number,
  destinationAdressPrefixSuggestion: string
  sourceAddressPrefixSuggestion: string,
  portScoreSuggestion: string
}

export interface INSGRuleDetailsResponse {
  top: number,
  skip: number,
  nsgRules: INSGRuleDetail[]
  count: number
  selectedRule: string
}

export interface INSGRulesDetailsRequest {
  top: number,
  skip: number,
  serviceTreeId: string,
  nsgId: string
}

export interface INSGDetailsRequest {
  top: number,
  skip: number,
  serviceTreeId: string,
  score: string
}

export interface INSGDetailsGridProps {
  onDismiss: () => void;
  fetchNSGDetails: (request?: INSGDetailsRequest) => void;
  fetchNSGRuleDetails: (request?: INSGRulesDetailsRequest) => void;
  isNSGDetailsLoading: boolean,
  nsgDetailsResponse: Maybe<INSGDetailsResponse>
  nsgDetailsError: Maybe<Error>
  isNSGRuleDetailsLoading: boolean,
  nsgRuleDetails: Maybe<INSGRuleDetailsResponse>
  nsgRuleDetailsError: Maybe<Error>
  serviceTreeId: string
  selectedScore: string
}

export interface INSGDetailsResponse {
  top: number,
  skip: number,
  nsgDetails: INSGDetail[]
  count: number,
  selectedScore: string
}

export interface INSGRulesDetailsRequest {
  top: number,
  skip: number,
  serviceTreeId: string,
  nsgId: string,
  nsgServiceTreeId: string
}

export enum MCSBSecurityGridType {
  NSGDetailGrid,
  NSGRuleGrid
}


export interface INSGRuleDetail {
  nsgRuleName: string
  priority: number
  access: string
  direction: string
  protocol: string
  sourceAddressPrefixes: string
  sourcePortRanges: string
  destinationAddressPrefixes: string
  destinationPortRanges: string
  portScore: number
  sourceAddressPrefixScore: number
  destinationAddressPrefixScore: number
  totalScore: number
  destinationAdressPrefixSuggestion: string
  sourceAddressPrefixSuggestion: string
}

export interface INS1ScoreChartRequest {
  serviceTreeId: string
}

export interface INS1DirectionChartRequest {
  serviceTreeId: string
  direction: NSGDirection
  top: number
  skip: number
}

export interface INS1ScoreChartResponse {
  organization: string,
  pieData: IPieData
}

export interface INS1DirectionChartResponse {
  organization: string,
  columnChartData: IColumnData
}

export enum NSGDirection {
  Inbound = 0,
  Outbound,
  Both
}

export interface INS1ActionProps {
  fetchScoreDistribution: (request?: INS1ScoreChartRequest) => void;
  fetchServiceTreeInboundData: (request?: INS1DirectionChartRequest) => void;
  fetchServiceTreeOutboundData: (request?: INS1DirectionChartRequest) => void;
  fetchServiceTreeBothData: (request?: INS1DirectionChartRequest) => void;
  fetchNSGDetails: (request?: INSGDetailsRequest) => void;
  fetchNSGRuleDetails: (request?: INSGRulesDetailsRequest) => void;
}

export type INS1Props = INS1MapState & INS1ActionProps;