import { compose } from '@reduxjs/toolkit';
import React from "react";
import { Helmet } from "react-helmet-async";
import { Resources } from '../../locales/resources';
import { IStyleSet, ILabelStyles, Pivot, mergeStyles, Icon } from '@fluentui/react';
import { Label } from '@fluentui/react';
import { PivotItem } from '@fluentui/react';
import VLansGrid from './visualSection/vLansGrid';
import InterfaceGrid from './visualSection/interfaceGrid';
import SummaryTab from './visualSection/summaryTab';
import BGPTab from './visualSection/bgpTab';
import AppContext from '../../utility/AppContext';

const footerLeftStyle = mergeStyles({
  padding: '0 5px',
  paddingTop: '12px',
  marginLeft: '5px',
  fontFamily: 'Segoe UI',
  fontSize: '18px',
  textDecoration:'none',
  color: '#000'
})

export class DevDetails extends React.Component {
  private collapsedDivStyle = mergeStyles({
    minHeight: '400px !important'
  })

  public onRenderItemColumn = (): JSX.Element => {
    return <span onClick={() => this.redirect()} style={{ cursor: 'pointer' }}>
      <a className={footerLeftStyle}><Icon iconName='NavigateBack' style={{ fontSize: '1.1rem' }} /><span> Back to Device Inventory</span></a>
    </span>
  }

  private redirect(): void {
    const appContext: AppContext = window.appContext;
    appContext.microUIRedirect(`nmsdeviceinventory`, false, true);
  }


  render(): JSX.Element {

    const labelStyles: Partial<IStyleSet<ILabelStyles>> = {
      root: { marginTop: 10 },
    };

    return (
      <div className={this.collapsedDivStyle}>
        <Helmet
          defaultTitle={Resources.NMSDetailPageTitle}
          titleTemplate={`%s - ${Resources.NMSDetailPageTitle}`}>
          <meta content={Resources.NMSLandingPageDescription} name="description" />
        </Helmet>

        <h1>{Resources.NMSDetailPageTitle}</h1>
        <p>{Resources.NMSLandingPageDescription}</p>
        <div>
          {this.onRenderItemColumn()}
        </div>

        <Pivot aria-label="NMS Pivot">
          <PivotItem
            headerText="Summary"
            headerButtonProps={{
              'data-order': 1,
              'data-title': 'Summary',
            }}
          >
            <Label styles={labelStyles}><SummaryTab /></Label>
          </PivotItem>
          <PivotItem headerText="Interface">
            <Label styles={labelStyles}><InterfaceGrid /></Label>
          </PivotItem>
          <PivotItem headerText="Vlans">
            <Label styles={labelStyles}><VLansGrid /></Label>
          </PivotItem>
          <PivotItem headerText="BGP">
            <Label styles={labelStyles}><BGPTab /></Label>
          </PivotItem>

        </Pivot>
      </div>
    );
  }
}

export default compose<React.ComponentType>(
)(DevDetails);