/**
 * Combine all reducers in this file and export the combined reducers.
 */

 import { combineReducers, CombinedState, AnyAction, Reducer } from '@reduxjs/toolkit';
 import { connectRouter, RouterState } from 'connected-react-router';
 import { history } from '../utility/history';
 import { InjectedReducersType } from '../types/injector-typings';
 import * as Core from '../utility/@core';
 import * as MicroUI from '../utility/@microUI';
 import { DeepReadonly } from '../types/base-types'
 
 /**
  * Merges the main reducer with the router state and dynamically injected reducers
  */
 export function createReducer(
   injectedReducers: DeepReadonly<InjectedReducersType> = {},
 ): DeepReadonly<
   Reducer<
     CombinedState<{
       router: RouterState;
       microUI: MicroUI.IMicroUIState;
       session: DeepReadonly<Core.ISessionState>;
     }>,
     AnyAction
   >
 > {
   const rootReducer = combineReducers({
     ...injectedReducers,
     microUI: MicroUI.microUIReducer,
     session: Core.sessionReducer,
     router: connectRouter(history) as Reducer<RouterState, AnyAction>,
   }) as DeepReadonly<
     Reducer<
       CombinedState<{
         router: RouterState;
         microUI: MicroUI.IMicroUIState;
         session: DeepReadonly<Core.ISessionState>;
       }>,
       AnyAction
     >
   >;
 
   return rootReducer;
 }
 