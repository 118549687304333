import { Store } from 'redux';
import { WindowWrapper } from './window-wrapper';
import { WindowPostMessageProxy, IMessageHandler } from 'window-post-message-proxy';
import { initialize } from './actions';
import * as m from './messages';

import {
  UserLoaded,
  IUserInfo,
  ServiceTreeIdFetched,
  IServiceTree,
  QueryParamFetched,
} from '../@core';
import { accessToken, accessTokenExpiry } from '../../app/constants';
import { DeepReadonly } from '../../types/base-types';
import { cwd } from 'process';

export class MicroUIService implements IMessageHandler {
  public readonly isEnabled: boolean;
  private isUserSet: boolean;
  private readonly proxy: WindowPostMessageProxy;

  constructor(
    private readonly window: DeepReadonly<WindowWrapper>,
    private readonly store: DeepReadonly<Store>,
  ) {
    this.isEnabled = this.window !== this.window.parent && !this.window.opener;
    this.proxy = new WindowPostMessageProxy({ suppressWarnings: true });
    this.isUserSet = false;
    if (this.isEnabled) {
      this.proxy.addHandler(this);
      this.proxy.start();
    }

    store.dispatch(initialize({ isEnabled: this.isEnabled }));
  }

  test(message: Readonly<m.IMessage>): boolean {
    switch (message.type) {
      case m.INIT:
      case m.SetUser:
      case m.SetAccessToken:
      case m.SetServiceTreeId:
      case m.SetQueryParams:
        return true;
    }

    return false;
  }

  handle(message: Readonly<m.IMessage>): void {
    switch (message.type) {
      case m.INIT:
        this.resize();
        break;
      case m.SetUser:
        this.isUserSet = true;
        this.handleUser(message as m.SetUserMessage);
        break;
      case m.SetAccessToken:
        this.handleAccessToken(message as m.SetAccessTokenMessage);
        break;
      case m.SetServiceTreeId:
        this.handleServiceTree(message as m.SetServiceTreeIdMessage)
        break;
        case m.SetQueryParams:
        this.handleQueryParams(message as m.SetQueryParamsMessage)
        break;
    }
  }

  send(message: DeepReadonly<m.IMessage>): void {
    this.proxy.postMessage((this.window.parent as unknown) as Window, message);
  }

  resize(): void {
    this.send(this.createRequestResizeMessage());
  }

  redirect(urlPathName: string, isExternalLink: boolean, openInNewTab: boolean): void {
    this.send(new m.RequestURLRedirectMessage(urlPathName, isExternalLink, openInNewTab));
  }

  updateView(viewKey: string): void {
    this.send(new m.RequestViewUpdateMessage(viewKey));
  }

  requestAccessToken(): Promise<void> {
    const error = new Error("proxy not set")
    if (!this.isUserSet) return Promise.reject(error);
    return this.proxy.postMessage(
      (this.window.parent as unknown) as Window,
      this.creatRequestAccessTokenMessage(),
    );
  }

  private creatRequestAccessTokenMessage(): m.RequestAccessTokenMessage {
    const message = new m.RequestAccessTokenMessage();
    return message;
  }

  private createRequestResizeMessage(): m.RequestResizeMessage {
    return new m.RequestResizeMessage(this.window.document.documentElement.offsetHeight);
  }

  private handleUser(message: m.SetUserMessage): void {
    const payload: IUserInfo = { id: message.id, email: message.alias, firstName: message.firstName, lastName: message.lastName, alias: message.alias };
    this.store.dispatch({
      type: UserLoaded,
      payload: payload,
    });
  }

  private handleServiceTree(message: m.SetServiceTreeIdMessage): void {
    const payload: IServiceTree = { serviceTreeId: message.serviceTreeId, serviceTreeName: message.serviceTreeName }
    this.store.dispatch({
      type: ServiceTreeIdFetched,
      payload: payload,
    });
  }

  private handleQueryParams(message: m.SetQueryParamsMessage): void {
    this.store.dispatch({
      type: QueryParamFetched,
      payload: message.params,
    });
  }

  private handleAccessToken(message: m.SetAccessTokenMessage): void {
    sessionStorage.setItem(accessToken, message.token);
    sessionStorage.setItem(accessTokenExpiry, message.expiresOn);
  }
}
