import React from 'react';
import './Loader.scss';

export default class Loader extends React.Component {
  render(): JSX.Element {
    return (
      <div className="page-loader">
        <div
          aria-busy="true"
          aria-label="page loading"
          aria-live="assertive"
          className="c-progress f-indeterminate-local f-progress-small">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }
}
