// eslint-disable-next-line
/// <reference path="../../react-app-env.d.ts" />

import * as React from 'react';
import { DeepReadonly } from '../../types/base-types';

/**
 * An abstract base class for components in Base Solution. The class gives access to the
 * AppContext singleton through this.appContext, access to strings through
 * this.strings and implements a rendering error boundary with componentDidCatch.
 */
export abstract class BaseComponent<P = {}, S = {}> extends React.Component<DeepReadonly<P>, S> {
  
}
