import React from "react";
import { mergeStyles, CommandButton, IButtonStyles } from '@fluentui/react';
import { IPagableGridFooterProps } from "../grid.types";

const getGridFooterStyle = (className): string => {
  return mergeStyles(
    {
      displayName: 'paginationContainer',
      alignItems: 'center',
      display: 'flex',
      margin: '4px 0px',
      padding: '4px 16px',
      width: 'auto',
      backgroundColor: '#edebe9'
    },
    className,
  );
};

const getButtonSelectedStyle: IButtonStyles = {
  root: {
    border: '2px solid',
    borderColor: '#201f1e',
    padding: '0px 4px',
    minWidth: '50px;',

  },
  flexContainer: {
    justifyContent: 'center'
  }

};

const getUnselectedButtonSelectedStyle: IButtonStyles = {
  root: {
    padding: '0px 4px',
    minWidth: '50px',
  },
  flexContainer: {
    justifyContent: 'center'
  }
};

export class GridFooter extends React.Component<IPagableGridFooterProps> {
  private getPrevious = (): React.ReactElement => {
    return (
      <CommandButton
        ariaLabel={`${this.props.className}-grid-page-prev-button`}
        disabled={this.props.selectedPageNumber === 1 || this.props.isLoading}
        iconProps={{ iconName: 'ChevronLeft', styles: { root: { lineHeight: '20px' } } }}
        id={`${this.props.className}-grid-prev-button`}
        key={`${this.props.className}-grid-prev-button`}
        onClick={(): void => {
          this.onPrevClick();
        }}
      />
    );
  }

  private getNext = (): React.ReactElement => {
    return (
      <CommandButton
        ariaLabel={`${this.props.className}-grid-page-next-button`}
        disabled={this.props.selectedPageNumber === this.getPageCount() || this.props.isLoading}
        iconProps={{ iconName: 'ChevronRight', styles: { root: { lineHeight: '20px' } } }}
        id={`${this.props.className}-grid-next-button`}
        key={`${this.props.className}-grid-next-button`}
        onClick={(): void => {
          this.onNextClick();
        }}
      />
    );
  }

  private getPageCount = (): number => {
    return Math.ceil(this.props.total / this.props.countPerPage);
  }

  private onNextClick = (): void => {
    const skip = this.props.selectedPageNumber * this.props.countPerPage;
    if (this.props.fetchData)
      this.props.fetchData(this.props.countPerPage, skip);
  }

  private onPrevClick = (): void => {
    const skip = (this.props.selectedPageNumber - 2) * this.props.countPerPage;
    if (this.props.fetchData)
      this.props.fetchData(this.props.countPerPage, skip);
  }

  private getStartIndex = (): number => {
    //if total count is less than max count shown the return 0
    const pageCount = this.getPageCount();
    if (this.props.total <= this.props.countPerPage) {
      return 0;
    }
    else if (pageCount < this.props.maxPagestoShow) return 0;
    else if (pageCount - this.props.selectedPageNumber >= this.props.maxPagestoShow) {
      return this.props.selectedPageNumber - 1;
    }
    else {
      return this.props.selectedPageNumber - (this.props.maxPagestoShow - (pageCount - this.props.selectedPageNumber + 1)) - 1;
    }
  }

  private getNavigationBox = (): React.ReactElement[] => {
    const pageCount = this.getPageCount();
    const pages: React.ReactElement[] = [];
    const maxPageIndex = this.getStartIndex() + this.props.maxPagestoShow;
    if (this.getStartIndex() !== 0) pages.push(this.getMoreButton('start'));
    for (let i = this.getStartIndex(); i < pageCount && i < maxPageIndex; i++) {
      pages.push(this.getPageNumberBox(i + 1));
    }
    if (maxPageIndex < pageCount)
      pages.push(this.getMoreButton('end'));
    return pages;
  }

  private getPageNumberBox = (index: number): React.ReactElement => {
    return (
      <CommandButton
        ariaLabel={`${this.props.className}-grid-page-${index}-navigation-pill`}
        disabled={this.props.isLoading}
        id={`${this.props.className}-page-${index}`}
        key={`${this.props.className}-page-${index}`}
        styles={this.props.selectedPageNumber === index ? getButtonSelectedStyle : getUnselectedButtonSelectedStyle}
        text={`${index}`}
        onClick={(): void => {
          this.onIndexSelectionClick(index);
        }}
      />
    )
  }

  private onIndexSelectionClick = (pageIndex: number) => {
    const skip = this.props.countPerPage * (pageIndex - 1);
    if (this.props.fetchData)
      this.props.fetchData(this.props.countPerPage, skip);
  }

  private getMoreButton = (type: string): React.ReactElement => {
    return (
      <CommandButton
        disabled={true}
        key={`grid-more-button${type}`}
        iconProps={{
          iconName: 'More',
          styles: { root: { justifyContent: 'center', margin: 'auto', lineHeight: '20px' } },
        }}
      />)

  }
  render(): JSX.Element {
    return (
      <>
        {
          <div className={`${getGridFooterStyle(this.props.className)}`}>
            {this.getPrevious()}
            {this.getNavigationBox()}
            {this.getNext()}
          </div>
        }
      </>
    );
  }
}