import { Maybe } from "../../../types/base-types";
import { fetchBGPDetailsSuccess } from "./action";
import { IBGPDetails, IBGPDetailsResponse, IBGPPrefixListResponse } from "./types/bgpTab.types";
import { IVlansDetailsResponse } from "./types/nmsDeviceDetails.types";
import { IInterfaceDetailsResponse } from "./types/nmsInterfaceTab.types";
import { ISummaryTabDetailsResponse } from "./types/nmsSummaryTab.types";


export interface InmsDeviceDetailsState {
   IVlansDetailsResponse: Maybe<IVlansDetailsResponse>;
   isVlansDetailsLoading: boolean;
   vlansDetailsLoadError: Maybe<Error>;

   IInterfaceDetailsResponse: Maybe<IInterfaceDetailsResponse>;
   isInterfaceDetailsLoading: boolean;
   interfaceDetailsLoadError: Maybe<Error>;

   ISummaryTabDetailsResponse: Maybe<ISummaryTabDetailsResponse>,
   isSummaryTabDetailsLoading: boolean,
   summaryTabDetailsLoadError: Maybe<Error>;

   IBGPDetailsResponse: Maybe<IBGPDetails[]>;
   isBGPDetailsLoading: boolean;
   bgpDetailsLoadError: Maybe<Error>;

   IBGPPrefixListResponse: Maybe<IBGPPrefixListResponse>;
   isBGPPrefixListLoading: boolean;
   bgpPrefixListLoadError: Maybe<Error>;

  
}

export const initialnmsDeviceDetailsState: InmsDeviceDetailsState = {
  IVlansDetailsResponse: undefined,
  isVlansDetailsLoading: false,
  vlansDetailsLoadError: undefined,

  IInterfaceDetailsResponse: undefined,
  isInterfaceDetailsLoading: false,
  interfaceDetailsLoadError: undefined,

  ISummaryTabDetailsResponse: undefined,
  isSummaryTabDetailsLoading: true,
  summaryTabDetailsLoadError: undefined,

  IBGPDetailsResponse: undefined,
  isBGPDetailsLoading: false,
  bgpDetailsLoadError: undefined,

  IBGPPrefixListResponse: undefined,
  isBGPPrefixListLoading: false,
  bgpPrefixListLoadError: undefined,
  
}

