
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { configureAppStore } from '../../store/configureStore';
import { DeepReadonly } from '../../types/base-types';
import { MicroUIService } from '../../utility/@microUI/service';
import {
  accessToken,
  accessTokenExpiry,
  checkAccessTokenTimeout,
  promiseTimeout,
} from '../constants';
import { history } from '../../utility/history';

const maxRetryCount = 3;
let retry = 0;
const tokenWhiteList: string[] = ['/api'];



const store = configureAppStore(history);
const AxiosInstance = axios.create();
const microUI = new MicroUIService((window as unknown) as DeepReadonly<Window>, store)
const getAccessTokenFromSessionStorage = (timeout: number): Promise<void> => {
  return new Promise((resolve, _reject) => setTimeout(() => {
    if (sessionStorage.getItem(accessToken)) return resolve();
    else return _reject();
  }, timeout)   
  );
};

const timeoutPromise = (timeout: number): Promise<void> => {
  return new Promise((_resolve, reject) => setTimeout(() => {
    reject()
  }, timeout));
};

const getCookie = (name: string): string | undefined => {
  let cookieValue: string | undefined = undefined;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export const isTokenRequired = (request: Readonly<AxiosRequestConfig>): boolean => {
  let tokenRequired = true;
  tokenWhiteList.forEach(whiteListItem => {
    const pattern = `^${whiteListItem}`;
    const regEx = RegExp(pattern);

    if (request?.url?.match(regEx)) {
      tokenRequired = true;
      return;
    }
  });

  return tokenRequired;
};

export const isTokenExpired = (): boolean => {
  const expiresOn: string | null = sessionStorage.getItem(accessTokenExpiry);

  return new Date(Date.now()) <= new Date(expiresOn ? expiresOn : '') ? false : true;
};

export const clearAccessTokenFromSessionStorage = (): void => {
  sessionStorage.removeItem(accessToken);
  sessionStorage.removeItem(accessTokenExpiry);
};

const callTokenRequest = async () => {
  let retryCount = 0;
  while (retryCount < 3) {
    try {
      await microUI
        .requestAccessToken()
        .then(() => {
          retryCount=4;
          return Promise.race([
            getAccessTokenFromSessionStorage(checkAccessTokenTimeout),
            timeoutPromise(promiseTimeout),
          ]);
        })
    } catch {
      retryCount++;
      await new Promise(r => setTimeout(r, 2000));
    }
  }
}

AxiosInstance.interceptors.request.use(async function (request: Readonly<AxiosRequestConfig>) {
  const token: string | null = sessionStorage.getItem(accessToken);
  const csrfToken: string | undefined = getCookie('XSRF-TOKEN');
  if (token && isTokenRequired(request) && !isTokenExpired()) {
    if (request && request.headers)
    Object.assign(request?.headers, { Authorization: `Bearer ${token}`, 'X-XSRF-TOKEN': csrfToken });
  } else {
    if (microUI.isEnabled && isTokenRequired(request)) {
      clearAccessTokenFromSessionStorage();      
      await callTokenRequest();
      const tokenAfterWait: string | null = sessionStorage.getItem(accessToken);
      const csrfTokenAfterWait: string | undefined = getCookie('XSRF-TOKEN');
      if (request && request.headers)
      Object.assign(request?.headers, { Authorization: `Bearer ${tokenAfterWait}`, 'X-XSRF-TOKEN': csrfTokenAfterWait });
    }
  }

  if (!Object.prototype.hasOwnProperty.call(request.headers, 'Content-Type')) {
    if (request && request.headers)
      Object.assign(request?.headers, { 'Content-Type': 'application/json' });
  }
  if (request && request.headers)
    Object.assign(request?.headers, { Accept: 'application/json' });
  return request;
});

AxiosInstance.interceptors.response.use(
 
  function (response: AxiosResponse<Response>) {
    retry = 0;
    return response;
  },
  async function (error) {
    retry++;
    const originalRequest = error.config;
    if (!error.response) {
      return Promise.reject("Resources.NetworkError");
    } else if (error?.response?.status === 401 && retry < maxRetryCount) {
      await new Promise(r => setTimeout(r, 2000));      
      if (microUI.isEnabled) {
        clearAccessTokenFromSessionStorage();
        await microUI
          .requestAccessToken()
          .then(() => {
            return Promise.race([
              getAccessTokenFromSessionStorage(checkAccessTokenTimeout),
              timeoutPromise(promiseTimeout),
            ]);
          })
          .catch(e => Promise.reject(e));
        return AxiosInstance.get(originalRequest.url, originalRequest);
      } else {
        const token = prompt("accessTokenPromptText");
        if (token) {
          sessionStorage.setItem(accessToken, token);
          return AxiosInstance.get(originalRequest.url, originalRequest);
        }
      }
    } else {
      retry = 0;
      return Promise.reject(error);
    }
  },
);
export default AxiosInstance;
