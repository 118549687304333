import { getTheme, IColumn, IShimmeredDetailsListProps, mergeStyles, mergeStyleSets, MessageBar, MessageBarType, Panel, PanelType, PrimaryButton, Stack } from "@fluentui/react";
import { Action, compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { injectReducer, injectSaga } from "redux-injectors";
import { IRootState } from "../../../../types";
import { DeepReadonly, Maybe } from "../../../../types/base-types";
import { Grid } from "../../../common/grid";
import Loader from "../../../common/Loader/Loader";
import { deviceReducer } from "../../store/reducers";
import { devicedetailsSagas } from "../../store/sagas";
import * as Actions from '../../store/action';
import { InmsDeviceDetailsState } from "../../store/state";
import { IBGPDetails, IBGPDetailsResponse, IBGPMapState, IBGPTabProps } from "../../store/types/bgpTab.types";


interface IBGPTabLocalState {
  isPrefixListPanelOpen: boolean,
  top: number,
  skip: number,
  countPerPage?: number,
  bgpDetails: IBGPDetails[],
  vrfname: string,
  neighbor: string,
}

const theme = getTheme();
const classNames = mergeStyleSets({
  headerAndFooter: {
    //borderTop: `${1}px solid ${theme.palette.neutralQuaternary}`,
    //borderBottom: `${1}px solid ${theme.palette.neutralQuaternary}`,
    padding: 4,
    margin: `${1}px 0`,
    marginTop: 10,
    //background: theme.palette.neutralLighterAlt,
    backgroundColor: '#edebe9',
    // Overlay the sizer bars
    position: 'relative',
    zIndex: 100,
  },
  headerTitle: [
    theme.fonts.medium,
    {
      padding: '1px 0',
      marginLeft: '10px',
      fontWeight: 'bold'
    },
  ],

  headersubTitle: [
    theme.fonts.medium,
    {
      padding: '1px 0',
    },
  ],
  headerLinkSet: {
    margin: '4px -8px',
  },
  headerLink: {
    margin: '0 8px',
  },
});


export class BGPTab extends React.Component<IBGPTabProps, IBGPTabLocalState> {
  constructor(props: IBGPTabProps) {
    super(props)
    this.state = {
      isPrefixListPanelOpen: false,
      bgpDetails: [],
      top: 25,
      skip: 0,
      countPerPage: 25,
      vrfname: '',
      neighbor: '',
    };
  }

  componentDidMount() {
    this.props.fetchBGPDetails({
      deviceId: this.props.session.queryParam?.id ?? '',
      serviceTreeId: this.props.session?.serviceTree?.serviceTreeId ?? ''
    })
  }


  public componentDidUpdate() {
    console.log(this.props.bgpTabDetailsState.IBGPDetailsResponse)
  }


  public getColumns = (): IColumn[] => {
    let cols: IColumn[] = [{
      key: "neighborsNeighbor",
      name: "Neighbor",
      fieldName: "neighborsNeighbor",
      minWidth: 50,
      maxWidth: 180,
      isRowHeader: true,
      isResizable: true
    },
    {
      key: "neighborsLocalAs",
      name: "Local AS",
      fieldName: "neighborsLocalAs",
      minWidth: 50,
      maxWidth: 180,
      isRowHeader: true,
      isResizable: true
    },
    {
      key: "neighborsRemoteAs",
      name: "Remote AS",
      fieldName: "neighborsRemoteAs",
      minWidth: 50,
      maxWidth: 180,
      isRowHeader: true,
      isResizable: true
    },
    {
      key: "neighborsReceivedCount",
      name: "Prefixes Received",
      fieldName: "neighborsReceivedCount",
      minWidth: 50,
      maxWidth: 180,
      isRowHeader: true,
      isResizable: true
    },
    {
      key: "neighborsDescription",
      name: "Description",
      fieldName: "neighborsDescription",
      minWidth: 50,
      maxWidth: 180,
      isRowHeader: true,
      isResizable: true
    },
    {
      key: "neighborsUptime",
      name: "UpTime(UTC)",
      fieldName: "neighborsUptime",
      minWidth: 50,
      maxWidth: 180,
      isRowHeader: true,
      isResizable: true
    },
    {
      key: "neighborsIsEnabled",
      name: "Status",
      fieldName: "neighborsIsEnabled",
      minWidth: 50,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true
    }]
    return cols;
  }


  private getActiveStatusStyle = mergeStyles({
    height: '22px',
    width: '22px',
    marginLeft: '5px',
    marginRight: '5px',
    marginBottom: '-20px',
    borderRadius: '50%',
    borderStyle: 'solid',
    borderWidth: '0.5px',
    borderColor: '#008000',
    backgroundColor: '#008000',

  })

  private getDisabledStatusStyle = mergeStyles({
    height: '22px',
    width: '22px',
    marginLeft: '5px',
    marginRight: '5px',
    marginBottom: '-20px',
    borderRadius: '50%',
    borderStyle: 'solid',
    borderWidth: '0.5px',
    borderColor: '#f00',
    backgroundColor: '#f00'
  })

  private getNoStatusStyle = mergeStyles({
    height: '22px',
    width: '22px',
    marginLeft: '5px',
    marginRight: '5px',
    marginBottom: '-20px',
    borderRadius: '50%',
    borderStyle: 'solid',
    borderWidth: '0.5px',
    borderColor: '#808080',
    backgroundColor: '#808080'
  })


  private getVRFVaule(): string[] {
    const bgpStatus = this.props.bgpTabDetailsState.IBGPDetailsResponse ?? []
    const vrfValue = bgpStatus.map((t) => t.vrfName).filter((value, index, self) => self.indexOf(value) === index);
    return vrfValue;
  }


  private getUpTimeVaule(sec: string): string {
    let totalSeconds = Number(sec);
    let seconds = totalSeconds % 60;
    let minutes = Math.floor(totalSeconds / 60) % 60;
    let hours = Math.floor(totalSeconds / 3600) % 24;
    let days = Math.floor(totalSeconds / 86400);

    if (totalSeconds <= 3600) {
      return (`${minutes} minutes, ${seconds} seconds`)
    }
    else if (totalSeconds >= 3600 && totalSeconds <= 86400) {
      return (`${hours} hours, ${minutes} minutes`)
    }
    else {
      return (`${days} days, ${hours} hours`)
    }
    //return `${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`;
  }


  //***This is Very Important Function***/
  public getGridData(): JSX.Element[] {
    const gridProp = this.props.bgpTabDetailsState.IBGPDetailsResponse ?? []

    const vrfList = (this.getVRFVaule()).map((element) => {
      const item = gridProp.filter((t) => { return t.vrfName === element })

      const gridProps: IShimmeredDetailsListProps = {
        setKey: "set",
        items: item,
        columns: this.getColumns(),
        onRenderItemColumn: this.onRenderItemColumn,
        selectionMode: 0,
      }
      return (
        <>
          <div className={classNames.headerAndFooter}>
            <div className={classNames.headerTitle}>{element}</div>
            <div className={classNames.headersubTitle}>(Router Id: {item[0].routerId})</div>
            {/* </div>
          <div> */}
            <Grid className="nms-details-grid"
              grid={gridProps}
              isLoading={false}
              isSearchBarEnabled={true}
              isSearchBarActive={true}
            />
          </div>
        </>
      )
    })
    return vrfList
  }


  public getSideColumns = (): IColumn[] => {
    let cols: IColumn[] = [{
      key: "prefixes",
      name: "Received Prefixes",
      fieldName: "prefixes",
      minWidth: 30,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true
    }]
    return cols;
  }


  public getSideGridProps(): IShimmeredDetailsListProps {
    const gridProp: IShimmeredDetailsListProps = {
      setKey: "set",
      items: this.props.bgpTabDetailsState.IBGPPrefixListResponse?.prefixes ?? [],
      columns: this.getSideColumns(),
      selectionMode: 0,
    }
    return gridProp
  }


  private onDismiss = (): void => {
    this.setState((prev) => {
      return { isPrefixListPanelOpen: false, }
    })
  }


  public onRenderItemColumn = (item: any, index?: number, column?: IColumn): JSX.Element | string | number => {
    if (!column) return "";
    if (column && column.key === 'neighborsReceivedCount') {
      return <span onClick={() => this.redirect(item["vrfName"], item["neighborsNeighbor"])} style={{ cursor: 'pointer', fontWeight: 'bold', fontFamily: 'sans-serif' }}>{item["neighborsReceivedCount"]} </span>
    }

    else if (column && column.key == 'neighborsIsEnabled') {
      return <div className={item[column?.key] == 0 ? this.getDisabledStatusStyle : (item[column?.key] == 1 ? this.getActiveStatusStyle : this.getNoStatusStyle)}></div>
    }

    else if (column && column.key == 'neighborsUptime') {
      return <div>
        {this.getUpTimeVaule(item[column?.key])}
      </div>
    }

    return item[column?.key];
  };


  private redirect(vrfName: string, neighborsNeighbor: string): void {

    this.setState({ vrfname: vrfName, neighbor: neighborsNeighbor, isPrefixListPanelOpen: true })

    this.props.fetchBGPPrefixList({
      prefixRequest: {
        deviceId: this.props.session.queryParam?.id ?? '',
        vrfname: vrfName,
        neighbor: neighborsNeighbor,
        top: this.state.top,
        skip: this.state.skip
      },
      serviceTreeId: this.props.session?.serviceTree?.serviceTreeId ?? ''
    })
  }


  public updateDisplayPerPage(key: number) {
    this.setState({ top: key, skip: 0, countPerPage: key }, () => {
      this.props.fetchBGPPrefixList({
        prefixRequest: {
          deviceId: this.props.session.queryParam?.id ?? '',
          vrfname: this.state.vrfname,
          neighbor: this.state.neighbor,
          top: this.state.top,
          skip: this.state.skip,
        },
        serviceTreeId: this.props.session.serviceTree?.serviceTreeId ?? '',
      })
    });
  }

  private getMessageBar = (messageType: MessageBarType, message: string): React.ReactElement => (
    <MessageBar
      messageBarType={messageType}
      isMultiline={false}
    >
      {message}
    </MessageBar>
  );

  private getData(top: number, skip: number): void {
    this.setState((prevState) => {
      return { top: top, skip: skip }
    },
      () => {
        this.props.fetchBGPPrefixList({
          prefixRequest: {
            deviceId: this.props.session.queryParam?.id ?? '',
            vrfname: this.state.vrfname,
            neighbor: this.state.neighbor,
            top: this.state.top,
            skip: this.state.skip,
          },
          serviceTreeId: this.props.session.serviceTree?.serviceTreeId ?? '',
        })
      }
    );
  }


  render(): JSX.Element {

    return (
      <>
        <div className={classNames.headerAndFooter}>
          <div className={classNames.headerTitle}>{this.props.session.queryParam?.name}</div>
        </div>
        {
          this.props.bgpTabDetailsState?.isBGPDetailsLoading ?
            <div className="app-loading-icon" style={{ minHeight: '400px' }}>
              <Loader />
            </div> :
            <>
              {/* {this.getGridData()} */}
              {
                ((this.props.bgpTabDetailsState.IBGPDetailsResponse?.length ?? 0) > 0) ?
                  <>{this.getGridData()}</> :
                  <div>{this.getMessageBar(MessageBarType.info, "There is no BGP data to display")}</div>
              }
            </>
        }


        <Panel
          isOpen={this.state.isPrefixListPanelOpen}
          onDismiss={this.onDismiss}
          headerText={"Prefix Received"}
          closeButtonAriaLabel="Close"
          isLightDismiss={true}
          type={PanelType.custom}
          customWidth={'50%'}
        >
          <Stack>
            {
              this.props.bgpTabDetailsState?.isBGPPrefixListLoading ?
                <div className="app-loading-icon" style={{ minHeight: '400px' }}>
                  <Loader />
                </div> :
                <>
                  <Grid className="nms-details-grid"
                    grid={this.getSideGridProps()}
                    top={this.state.top}
                    skip={this.state.skip}
                    countPerPage={this.state.countPerPage}
                    maxPagestoShow={3}
                    updateCountPerPage={this.updateDisplayPerPage.bind(this)}
                    total={this.props.bgpTabDetailsState.IBGPPrefixListResponse?.count}
                    isLoading={false}
                    //actionBarItem={this.getCommandBarItems()} //top bars
                    isFilterVisible={false} //Filter show hide
                    //filters={this.getFilterData()}   //Filter options
                    //noDataMessage={Resources.PolicyScreenNoDataMessage}  //When no data
                    fetchData={this.getData.bind(this)}  //to re-trigger the data fetch for filter. function to get data.
                    //selectedFilter={this.state.filters}  //applied filters.
                    //isGridVisible={this.isGridVisible}  //Grid fileter boolean
                    //onActiveItemChanged={this.activeItemChanged} //radio button to call actions
                    isSearchBarEnabled={true}   //filtered on visible items
                    //searchBarSearchCallback={this.searchBarCallback}  // data filterration in seach bar
                    isSearchBarActive={true}  // disabled when no data.... 
                  />
                </>
            }
          </Stack>
        </Panel>
      </>
    );
  }
}


export const mapStateToProps = (state: DeepReadonly<IRootState>): DeepReadonly<IBGPMapState> => ({
  bgpTabDetailsState: state.nmsDeviceDetailsState,
  session: state.session,
  microUI: state.microUI
});

const mapDispatchToProps = {
  fetchBGPDetails: Actions.fetchBGPDetails,
  fetchBGPPrefixList: Actions.fetchBGPPrefixList
};

export default compose<React.ComponentType>(
  injectReducer({
    key: 'nmsDeviceDetailsState',//Keep this same in IRoot, and props interface
    reducer: deviceReducer as React.Reducer<DeepReadonly<InmsDeviceDetailsState>, DeepReadonly<Action>>,
  }),
  injectSaga({ key: 'nmsDeviceDetailsState', saga: devicedetailsSagas }),
  connect(mapStateToProps, mapDispatchToProps),
)
  (BGPTab);