import { Reducer } from "@reduxjs/toolkit";
import { DeepReadonly } from "../../../types/base-types";
import { DeviceActions } from "./actionTypes";
import { IDeviceState, initialDeviceState } from "./state";
import * as types from './actionTypes';

export const deviceReducer: Reducer<DeepReadonly<IDeviceState>, DeepReadonly<DeviceActions>> = (
  state: DeepReadonly<IDeviceState> = initialDeviceState,
  action: DeepReadonly<DeviceActions>,
): DeepReadonly<IDeviceState> => {
  switch (action.type) {
    case types.FetchNMSDeviceInventory: {
      return {
        ...state,
        isDeviceIventoryLoading: true,
        deviceInventoryLoadError: undefined,
        INMSDevInentoryResponse: undefined

      };
    }
    case types.FetchNMSDeviceInventorySuccess: {
      return {
        ...state,
        isDeviceIventoryLoading: false,
        deviceInventoryLoadError: undefined,
        INMSDevInentoryResponse: action.payload,
      };
    }
    case types.FetchNMSDeviceInventoryFailure: {
      return {
        ...state,
        isDeviceIventoryLoading: false,
        deviceInventoryLoadError: action.payload,
        INMSDevInentoryResponse: undefined
      };
    };

    //Update NMS Device Details.
    case types.UpdateNMSDeviceDetails: {
      return {
        ...state,
        isUpdateDeviceDetailsDataSaved: undefined
      };
    }

    case types.UpdateNMSDeviceDetailsSuccess: {
      return {
        ...state,
        isUpdateDeviceDetailsDataSaved: true,
      };
    }
    case types.UpdateNMSDeviceDetailsFailure: {
      return {
        ...state,
        nmsDeviceDetailsUpdateError: action.payload,
        isUpdateDeviceDetailsDataSaved: false,
      };
    }

  
     //Edit NMS Device Details.
     case types.EditNMSDeviceDetails: {
      return {
        ...state,
        isEditDeviceDetailsDataSaved: undefined
      };
    }

    case types.EditNMSDeviceDetailsSuccess: {
      return {
        ...state,
        isEditDeviceDetailsDataSaved: true,
      };
    }
    case types.EditNMSDeviceDetailsFailure: {
      return {
        ...state,
        nmsDeviceDetailsEditError: action.payload,
        isEditDeviceDetailsDataSaved: false,
      };
    }


    //Delete NMS Device Details.
    case types.DeleteNMSDeviceDetails: {
      return {
        ...state,
        isDeleteDeviceDetailsDataSaved: undefined
      };
    }

    case types.DeleteNMSDeviceDetailsSuccess: {
      return {
        ...state,
        isDeleteDeviceDetailsDataSaved: true,
      };
    }
    case types.DeleteNMSDeviceDetailsFailure: {
      return {
        ...state,
        nmsDeviceDetailsUpdateError: action.payload,
        isDeleteDeviceDetailsDataSaved: false,
      };
    }

    //Fetch DataCenter List  
    case types.FetchDataCentersList: {
      return {
        ...state,
        isDataCentersListLoading: true
      };
    }
    case types.FetchDataCentersListSuccess: {
      return {
        ...state,
        isDataCentersListLoading: false,
        dataCentersList: action.payload
      };
    }
    case types.FetchDataCentersListFailure: {
      return {
        ...state,
        isDataCentersListLoading: false,
        dataCentersListLoadError: action.payload
      };
    }

    //Fetch Device Type List  
    case types.FetchDeviceTypeList: {
      return {
        ...state,
        isDeviceTypeListLoading: true
      };
    }
    case types.FetchDeviceTypeListSuccess: {
      return {
        ...state,
        isDeviceTypeListLoading: false,
        deviceTypeList: action.payload
      };
    }
    case types.FetchDeviceTypeListFailure: {
      return {
        ...state,
        isDeviceTypeListLoading: false,
        deviceTypeListLoadError: action.payload
      };
    }

    //Fetch Environments List 
    case types.FetchEnvironmentsList: {
      return {
        ...state,
        isEnvironmentsListLoading: true
      };
    }
    case types.FetchEnvironmentsListSuccess: {
      return {
        ...state,
        isEnvironmentsListLoading: false,
        environmentsList: action.payload
      };
    }
    case types.FetchEnvironmentsListFailure: {
      return {
        ...state,
        isEnvironmentsListLoading: false,
        environmentsListLoadError: action.payload
      };
    }

    
    //Fetch State List 
    case types.FetchStateList: {
      return {
        ...state,
        isStateListLoading: true
      };
    }
    case types.FetchStateListSuccess: {
      return {
        ...state,
        isStateListLoading: false,
        stateList: action.payload
      };
    }
    case types.FetchStateListFailure: {
      return {
        ...state,
        isStateListLoading: false,
        stateListLoadError: action.payload
      };
    }

//Fetch AntiForgeryToken
    case types.FetchAntiForgeryToken: {
      return {
        ...state,
        isTokenLoading: true,
        istokenFetched:false,
        tokenLoadingError:undefined
      };
    }
    case types.FetchAntiForgeryTokenSuccess: {
      return {
        ...state,
        isTokenLoading: false,
        istokenFetched:true,
      };
    }
    case types.FetchAntiForgeryTokenFailed: {
      return {
        ...state,
        isTokenLoading: false,
        istokenFetched:false,
        tokenLoadingError:action.payload
      };
    }
    
    default:
      return state;
  }
};


