import { Maybe } from "../../../../types/base-types"
import { IColumnData } from "../../../common/charts/column/column.types"
import { IPieData } from "../../../common/charts/pie/pie.types"
import { INS1DirectionChartResponse, INS1ScoreChartResponse, INSGDetailsResponse, INSGRuleDetailsResponse } from "./types/ns1.types"

export interface INS1State {
  isSeverityPieChartLoading: boolean
  severityScoreChartData: Maybe<INS1ScoreChartResponse>
  severityScoreChartError: Maybe<Error>
  isServiceTreeOutboundDataLoading: boolean
  serviceTreeOutboundChartData: Maybe<INS1DirectionChartResponse>
  serviceTreeOutboundChartError: Maybe<Error>
  isServiceTreeBothDataLoading: boolean
  serviceTreeBothChartData: Maybe<INS1DirectionChartResponse>
  serviceTreeBothChartError: Maybe<Error>
  isServiceTreeInboundDataLoading: boolean
  serviceTreeInboundChartData: Maybe<INS1DirectionChartResponse>
  serviceTreeInboundChartError: Maybe<Error>
  isNSGDetailsLoading: boolean,
  nsgDetailsResponse: Maybe<INSGDetailsResponse>
  nsgDetailsError: Maybe<Error>
  isNSGRuleDetailsLoading: boolean,
  nsgRuleDetails: Maybe<INSGRuleDetailsResponse>
  nsgRuleDetailsError: Maybe<Error>
  isTokenLoading: boolean;
  istokenFetched:boolean;
  tokenLoadingError: Maybe<Error>
}

export const initialNS1State: INS1State = {
  isSeverityPieChartLoading: false,
  severityScoreChartData: undefined,
  severityScoreChartError: undefined,
  isServiceTreeOutboundDataLoading: false,
  serviceTreeOutboundChartData: undefined,
  serviceTreeOutboundChartError: undefined,
  isServiceTreeBothDataLoading: false,
  serviceTreeBothChartData: undefined,
  serviceTreeBothChartError: undefined,
  isServiceTreeInboundDataLoading: false,
  serviceTreeInboundChartData: undefined,
  serviceTreeInboundChartError: undefined,
  isNSGDetailsLoading: false,
  nsgDetailsResponse: undefined,
  nsgDetailsError: undefined,
  isNSGRuleDetailsLoading: false,
  nsgRuleDetails: undefined,
  nsgRuleDetailsError: undefined,
  isTokenLoading: false,
  istokenFetched:false,
  tokenLoadingError: undefined,
}