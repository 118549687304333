import { DeepReadonly } from "../../types/base-types";

export interface IMessage {
  type: string;
}

export interface IMessageSize {
  type: string;
  height: number
}

export const INIT = 'MICROUI.INIT';
export const SetUser = 'MICROUI.SET_USER';
export const SetAccessToken = 'MICROUI.SET_ACCESSTOKEN';
export const RequestResize = 'MICROUI.REQUEST_RESIZE';
export const RequestAccessToken = 'MICROUI.REQUEST_ACCESSTOKEN';
export const RequestURLRedirect = 'MICROUI.REQUEST_URL_REDIRECTION';
export const RequestViewUpdate = 'MICROUI.REQUEST_VIEW_UPDATE';
export const SetServiceTreeId = 'MICROUI.SET_SERVICE_TREE_ID'
export const SetQueryParams = 'MICROUI.SET_QUERY_PARAMS';

export class InitMessage implements IMessage {
  readonly type = INIT;
}

export class RequestResizeMessage implements IMessage {
  readonly type = RequestResize;
  constructor(public height: number) {
  }
}


export class SetUserMessage implements IMessage {

  readonly type = SetUser;

  constructor(
    public readonly id: string,
    public readonly alias: string,
    public readonly firstName: string,
    public readonly lastName: string,
  ) { }
}

export class SetAccessTokenMessage implements IMessage {
  readonly type = SetAccessToken;

  constructor(
    public readonly token: string,
    public readonly notBefore: string,
    public readonly expiresOn: string,
  ) { }
}

export class RequestAccessTokenMessage implements IMessage {
  readonly type = RequestAccessToken;
}


export class RequestURLRedirectMessage implements IMessage {
  readonly type = RequestURLRedirect;

  constructor(
    public readonly urlPathName: string,
    public readonly isExternalLink: boolean,
    public readonly openInNewTab: boolean,
  ) { }
}

export class RequestViewUpdateMessage implements IMessage {
  readonly type = RequestViewUpdate;

  constructor(public readonly viewKey: string) { }
}

export class SetServiceTreeIdMessage implements IMessage {
  readonly type = SetServiceTreeId;

  constructor(
    public readonly serviceTreeId: string,
    public readonly serviceTreeName: string

  ) { }
}

export class SetQueryParamsMessage implements IMessage {
  readonly type = SetQueryParams;
  constructor(public readonly params: DeepReadonly<Record<string, string>>) {}
}