import React from "react";
import { mergeStyles, Dropdown, Stack, IStackStyles, PrimaryButton, IDropdownOption, IDropdownStyles, TextField, ITextFieldStyleProps, ITextFieldStyles } from '@fluentui/react';
import { IFilter, IFilterValues, IPagableGridFilterProps } from "../grid.types";
import { Maybe } from "../../../../types/base-types";
import { Resources } from "../../../../locales/resources";

const getGridFilterStyle = (className): string => {

  return mergeStyles(
    {
      displayName: 'paginationContainer',
      alignItems: 'center',
      display: 'flex',
      margin: '4px 0px',
      padding: '4px 16px',
      width: 'auto',
      minHeight: '48px',
      marginTop: '4px',
      backgroundColor: '#edebe9',
      fontFamily: 'Segoe UI',
      fontSize: '15px'
    },
    className,
  );
};

const stackStyles: IStackStyles = {
  root: {
    width: '100%',
    minHeight: '85px'
  },
};

interface IFilterState {
  selectedFilter: IFilter[]
}

export class GridFilter extends React.Component<IPagableGridFilterProps, IFilterState> {
  constructor(props: IPagableGridFilterProps) {
    super(props);
    this.state = {
      selectedFilter: []
    };
  }
  private textBoxStyles = (props: ITextFieldStyleProps): Partial<ITextFieldStyles> => {
    if (props.hasErrorMessage) {
      return {
        root: {         
          minWidth: '195px'
        }
      }
    }
    else {
      return {
        root: {
          paddingBottom: '23px',
          minWidth: '195px'
        }
      }
    }
  }
  private updateFilterState = (selectedFilter: Maybe<IDropdownOption>, filterKey: string, isMultiselect: boolean, isTextFilter: boolean = false, inputFilterValue?: string): void => {
    this.setState((prev) => {
      if (isTextFilter) {
        const filters = [...prev.selectedFilter];
        const val: IFilterValues[] = [];
        val.push({ key: inputFilterValue as string, displayName: inputFilterValue as string })
        let index: number = filters.findIndex(t => t.key === filterKey);
        if (index != -1) {
          filters[index].values = val
        }
        else {
          const filter: IFilter = { key: filterKey, values: val, };
          filters.push(filter);
        }
        return { selectedFilter: filters }
      }
      if (selectedFilter) {
        if (isMultiselect) {
          const filters = prev.selectedFilter;
          if (selectedFilter.selected) {
            if (!filters.some(t => t.key === filterKey)) {
              const filter: IFilter = { key: filterKey, values: [] };
              filters.push(filter);
            }
            filters.map((u): void => {
              if (u.key === filterKey)
                u.values.push({ key: selectedFilter.key as string, displayName: selectedFilter.text });
            })
          }
          else {
            //Some filter is deselected
            filters.map((u): void => {
              if (u.key === filterKey) {
                const key = selectedFilter.key as string;
                const index = u.values.findIndex(t => t.key === key);
                u.values.splice(index, 1);
              }
            })
          }
          return { selectedFilter: filters }
        }
        else {
          const filters = [...prev.selectedFilter];
          const val: IFilterValues[] = [];
          val.push({ key: selectedFilter.key as string, displayName: selectedFilter.text })
          let index: number = filters.findIndex(t => t.key === filterKey);
          if (index !== -1) {
            filters[index].values = val
          }
          else {
            const filter: IFilter = { key: filterKey, values: val, };
            filters.push(filter);
          }
          return { selectedFilter: filters }
        }
      }
    }
    )
  }

  dropdownStyles: Partial<IDropdownStyles> = {
    dropdownOptionText: { overflow: 'visible', whiteSpace: 'normal' },
    dropdownItem: { height: 'auto' },
    dropdown: { width: 300 },
    root:{paddingBottom:'23px'}
  };

  private getFilterDropDown = (): React.ReactElement[] => {
    const filterItems: React.ReactElement[] = [];
    this.props.filters.map((t, index): void => {
      filterItems.push(
        <Stack.Item key={`${this.props.className}-gridFilter-${index}`} styles={{ root: { alignSelf: 'center', height: '100%', paddingLeft: '10px' } }}>
          {t.items == undefined ?
            <TextField key={`text-field-item-add-${index}`}
              label={t.label}
              styles={(props)=>this.textBoxStyles(props)}
              onChange={(event: Maybe<React.FormEvent<HTMLInputElement | HTMLTextAreaElement>>, newValue?: string): void => this.updateFilterState(undefined, t.label, false, true, newValue)}
              onGetErrorMessage={(value: string) => {
                if (t?.onGetErrorMessage == undefined) return ""
                return t.onGetErrorMessage(value, t.label);
              }}
            />
            :
            t?.isMultiselect ?
              <Dropdown
                label={t.label}
                styles={this.dropdownStyles}
                multiSelect
                options={t.items ?? []}
                onChange={(_event: React.FormEvent<HTMLDivElement>, newValue): void => {
                  this.updateFilterState(newValue, t.label, true);
                }}
                defaultSelectedKeys={this.props.selectedFilter?.find(j => j.key === t.label)?.values.map(t => t.key)}
                role="combobox"
                key={`filter-filter`}
              /> :
              <Dropdown
                label={t.label}
                styles={this.dropdownStyles}
                options={t.items ?? []}
                onChange={(_event: React.FormEvent<HTMLDivElement>, newValue, index): void => {
                  this.updateFilterState(newValue, t.label, false);
                }}
                defaultSelectedKey={this.props.selectedFilter?.find(j => j.key === t.label)?.values[0].key}
                role="combobox"
              />}
        </Stack.Item>
      )
    });
    return filterItems;
  }

  private applyFilter = (): void => {
    if (this.props.countPerPage && this.props.fetchData)
      this.props.fetchData(this.props.countPerPage, 0, this.state.selectedFilter, true);
    else if (this.props.fetchAllData)
      this.props.fetchAllData(this.state.selectedFilter, true)
  }
  render(): JSX.Element {
    return (
      <>
        {
          <div className={`${getGridFilterStyle(this.props.className)}`}>
            <Stack
              className={`${this.props.className} itemsList`}
              horizontal
              tokens={{ childrenGap: 10 }}
              styles={stackStyles}>
              {this.getFilterDropDown()}
              <Stack.Item styles={{ root: { alignSelf: 'center', height: '100%', width: '100%', paddingLeft: '10px' , paddingTop:'5px'} }} >
                <PrimaryButton
                  text={Resources.ApplyButtonText}
                  disabled={this.props.customApplyEnableCheck != undefined ? this.props.customApplyEnableCheck(this.state.selectedFilter) : !(this.state.selectedFilter.length > 0)}
                  onClick={() => this.applyFilter()} />
              </Stack.Item>
            </Stack>
          </div>
        }
      </>
    );
  }
}