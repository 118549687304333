import { Maybe } from '../../types/base-types';
import {
  IUserInfo,
} from './models';

export interface IServiceTree {
  serviceTreeId?: string
  serviceTreeName?: string
}

export interface ISessionState {
  user?: IUserInfo;
  serviceTree?: Maybe<IServiceTree>,
  queryParam?:Maybe<Record<string, string>>
}

export interface ISession {
  session: ISessionState;
}

export const initialUserState: IUserInfo = {
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  alias: '',
};

export const initialSessionState: ISessionState = {
  user: undefined,
  serviceTree: undefined,
  queryParam:undefined
};