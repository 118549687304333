import { Reducer } from "@reduxjs/toolkit";
import { DeepReadonly } from "../../../../types/base-types";
import { NS1Actions } from "./action.types";
import { initialNS1State, INS1State } from "./state";
import * as types from './action.types';

export const ns1Reducer: Reducer<DeepReadonly<INS1State>, DeepReadonly<NS1Actions>> = (
  state: DeepReadonly<INS1State> = initialNS1State,
  action: DeepReadonly<NS1Actions>,
): DeepReadonly<INS1State> => {
  switch (action.type) {
    case types.FetchScoreDistribution:
      return {
        ...state,
        isSeverityPieChartLoading: true,
        severityScoreChartData: undefined,
        severityScoreChartError: undefined
      };
    case types.FetchScoreDistributionSuccess:
      return {
        ...state,
        isSeverityPieChartLoading: false,
        severityScoreChartData: action.payload,
        severityScoreChartError: undefined
      };
    case types.FetchScoreDistributionFailure:
      return {
        ...state,
        isSeverityPieChartLoading: false,
        severityScoreChartData: undefined,
        severityScoreChartError: action.payload
      }
    case types.FetchServiceTreeBothData:
      return {
        ...state,
        isServiceTreeBothDataLoading: true,
        serviceTreeBothChartData: undefined,
        serviceTreeBothChartError: undefined
      };
    case types.FetchServiceTreeBothDataSuccess:
      return {
        ...state,
        isServiceTreeBothDataLoading: false,
        serviceTreeBothChartData: action.payload,
        serviceTreeBothChartError: undefined
      };
    case types.FetchServiceTreeBothDataFailure:
      return {
        ...state,
        isServiceTreeBothDataLoading: false,
        serviceTreeBothChartData: undefined,
        serviceTreeBothChartError: action.payload
      }
    case types.FetchServiceTreeInboundData:
      return {
        ...state,
        isServiceTreeInboundDataLoading: true,
        serviceTreeInboundChartData: undefined,
        serviceTreeInboundChartError: undefined
      };
    case types.FetchServiceTreeInboundDataSuccess:
      return {
        ...state,
        isServiceTreeInboundDataLoading: false,
        serviceTreeInboundChartData: action.payload,
        serviceTreeInboundChartError: undefined
      };
    case types.FetchServiceTreeInboundDataFailure:
      return {
        ...state,
        isServiceTreeInboundDataLoading: false,
        serviceTreeInboundChartData: undefined,
        serviceTreeInboundChartError: action.payload
      }
    case types.FetchServiceTreeOutboundData:
      return {
        ...state,
        isServiceTreeOutboundDataLoading: true,
        serviceTreeOutboundChartData: undefined,
        serviceTreeOutboundChartError: undefined
      };
    case types.FetchServiceTreeOutboundDataSuccess:
      return {
        ...state,
        isServiceTreeOutboundDataLoading: false,
        serviceTreeOutboundChartData: action.payload,
        serviceTreeOutboundChartError: undefined
      };
    case types.FetchServiceTreeOutboundDataFailure:
      return {
        ...state,
        isServiceTreeOutboundDataLoading: false,
        serviceTreeOutboundChartData: undefined,
        serviceTreeOutboundChartError: action.payload
      }
    case types.FetchServiceTreeInboundDataSuccess:
      return {
        ...state,
        isServiceTreeInboundDataLoading: false,
        serviceTreeInboundChartData: action.payload,
        serviceTreeInboundChartError: undefined
      };
    case types.FetchServiceTreeInboundDataFailure:
      return {
        ...state,
        isServiceTreeInboundDataLoading: false,
        serviceTreeInboundChartData: undefined,
        serviceTreeInboundChartError: action.payload
      }
      case types.FetchNSGDetails:
      return {
        ...state,
        isNSGDetailsLoading: true,
        nsgDetailsResponse: undefined,
        nsgDetailsError: undefined
      };
    case types.FetchNSGDetailsSuccess:
      return {
        ...state,
        isNSGDetailsLoading: false,
        nsgDetailsResponse: action.payload,
        nsgDetailsError: undefined
      };
    case types.FetchNSGDetailsFailure:
      return {
        ...state,
        isNSGDetailsLoading: false,
        nsgDetailsResponse: undefined,
        nsgDetailsError: action.payload
      }
      case types.FetchNSGRulesDetails:
        return {
          ...state,
          isNSGDetailsLoading: true,
          nsgRuleDetails: undefined,
          nsgRuleDetailsError: undefined
        };
      case types.FetchNSGRulesDetailsSuccess:
        return {
          ...state,
          isNSGDetailsLoading: false,
          nsgRuleDetails: action.payload,
          nsgRuleDetailsError: undefined
        };
      case types.FetchNSGRulesDetailsFailure:
        return {
          ...state,
          isNSGDetailsLoading: false,
          nsgRuleDetails: undefined,
          nsgRuleDetailsError: action.payload
        }       
        case types.FetchAntiForgeryToken: {
          return {
            ...state,
            isTokenLoading: true,
            istokenFetched:false,
            tokenLoadingError:undefined
          };
        }
        case types.FetchAntiForgeryTokenSuccess: {
          return {
            ...state,
            isTokenLoading: false,
            istokenFetched:true,
          };
        }
        case types.FetchAntiForgeryTokenFailed: {
          return {
            ...state,
            isTokenLoading: false,
            istokenFetched:false,
            tokenLoadingError:action.payload
          };
        }
    default:
      return state;
  }
};