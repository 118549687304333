import { IShimmerElement, Shimmer, ShimmerElementType } from "@fluentui/react";
import React from "react";
import { IPieChart } from "../pie.types";

export class ShimmerPieChart extends React.Component<IPieChart> {

  private shimmerVerticalElement = (): IShimmerElement[] => {
    return [
      { type: ShimmerElementType.gap, verticalAlign: 'center', width: '45%', height: 230 },
      { type: ShimmerElementType.circle, verticalAlign: 'center', width: '10%', height: 230 },
      { type: ShimmerElementType.gap, verticalAlign: 'center', width: '45%', height: 230 },

    ];
  }

  render(): JSX.Element {
    return (
      <Shimmer
        shimmerElements={this.shimmerVerticalElement()}
        height={this.props.height}
        styles={{ root: { padding: '5%' } }} />
    )
  }
}