import { CommandBar, FontWeights, ICommandBarItemProps, Icon, ILabelStyles, INavLink, INavLinkGroup, INavStyles, IRenderGroupHeaderProps, IStyleSet, mergeStyles, Nav, Stack } from "@fluentui/react";
import React from "react";
import { Helmet } from "react-helmet-async";
import { compose } from "redux";
import { Resources } from "../../locales/resources";
import  NS1  from "./ns1";

export interface IMCBSecurityState {
  isSelectionPanelVisible: boolean,
  selectedKey: string
}

export interface IMCBSecurityProps {
}

export class MCSBSecurity extends React.Component<IMCBSecurityProps, IMCBSecurityState> {
  private chartHeight: string;
  constructor(props: IMCBSecurityProps) {
    super(props)
    this.state = {
      isSelectionPanelVisible: true,
      selectedKey: Resources.MCSBNS1
    }
    this.chartHeight = '480px'
  }

  navStyles: Partial<INavStyles> = {
    root: {
      width: 208,
      height: 350,
      boxSizing: 'border-box',
      border: '1px solid #eee',
      overflowY: 'auto',
    },
    // these link styles override the default truncation behavior
    link: {
      whiteSpace: 'normal',
      lineHeight: 'inherit',
    },
  };

  private getCommandBarItems(): ICommandBarItemProps[] {
    return [
      {
        id: 'sidebarController',
        key: 'sidebarController',
        role: 'menuitem',
        ariaLabel: 'sidebarController',
        iconProps: { iconName: 'CheckListText', styles: { root: { fontSize: '2rem', width: '100%' } } },
        buttonStyles: { root: { backgroundColor: 'transparent' } },
        onClick: this.updateIsVisibleSelectionPanel,
      }
    ];
  }

  renderGroupHeader = (): JSX.Element => {
    return (
      <CommandBar
        className="header-commandBar-desktop"
        items={this.getCommandBarItems()}
        styles={{ root: { paddingLeft: 0, backgroundColor: '#edebe9' } }}
      />
    );
  };

  updateIsVisibleSelectionPanel = () => {
    this.setState((prevState) => {
      return {
        isSelectionPanelVisible: !prevState.isSelectionPanelVisible
      }
    })
  }

  updateSelectedKey = (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
    this.setState({
      selectedKey: item?.key ?? Resources.MCSBNS1
    })
  }

  getSelectedGroup = () => {
    switch (this.state.selectedKey) {
      case Resources.MCSBNS1:
        return (<NS1 />)
      default:
        return (
          <Stack>
            <Stack.Item
              align="center"
              styles={{
                root: {
                  color: '#3078bc'
                }
              }}>
              <Icon
                iconName="DeveloperTools"
                styles={{
                  root:
                  {
                    fontSize: '7rem',
                  }
                }}
              />
            </Stack.Item>
            <Stack.Item align="center">
              <h1
                style={{
                  color: '#3078bc'
                }}>
                {'Coming Soon...'}
              </h1>
            </Stack.Item>
          </Stack>
        )
    }
  }

  render(): JSX.Element {

    const labelStyles: Partial<IStyleSet<ILabelStyles>> = {
      root: { marginTop: 10 },
    };

    const navStyles: Partial<INavStyles> = { root: { maxWidth: 300 }, link: { paddingLeft: '11px' } };
    const navStylesShortHeader: Partial<INavStyles> = { link: { paddingLeft: '11px' } };

    const navLinkGroups: INavLinkGroup[] = [
      {
        links: [
          {
            name: Resources.MCSBNS1,
            url: '#',
            key: Resources.MCSBNS1,
            title: '',
          },
          {
            name: Resources.MCSBNS2,
            url: '#',
            key: Resources.MCSBNS2,
            title: '',
          },
          {
            name: Resources.MCSBNS3,
            url: '#',
            key: Resources.MCSBNS3,
            title: '',
          },
          {
            name: Resources.MCSBNS4,
            url: '#',
            key: Resources.MCSBNS4,
            title: '',
          },
          {
            name: Resources.MCSBNS5,
            url: '#',
            key: Resources.MCSBNS5,
            title: '',
          },
          {
            name: Resources.MCSBNS6,
            url: '#',
            key: Resources.MCSBNS6,
            title: '',
          },
          {
            name: Resources.MCSBNS7,
            url: '#',
            key: Resources.MCSBNS7,
            title: '',
          },
          {
            name: Resources.MCSBNS8,
            url: '#',
            key: Resources.MCSBNS8,
            title: '',
          },
          {
            name: Resources.MCSBNS9,
            url: '#',
            key: Resources.MCSBNS9,
            title: '',
          },
          {
            name: Resources.MCSBNS10,
            url: '#',
            key: Resources.MCSBNS10,
            title: '',
          },
        ],
      },
    ];

    const navLinkGroupsShortHeader: INavLinkGroup[] = [
      {
        links: [
          {
            name: Resources.MCSBNS1ShortHeader,
            url: '#',
            key: Resources.MCSBNS1,
            title: '',
          },
          {
            name: Resources.MCSBNS2ShortHeader,
            url: '#',
            key: Resources.MCSBNS2,
            title: '',
          },
          {
            name: Resources.MCSBNS3ShortHeader,
            url: '#',
            key: Resources.MCSBNS3,
            title: '',
          },
          {
            name: Resources.MCSBNS4ShortHeader,
            url: '#',
            key: Resources.MCSBNS4,
            title: '',
          },
          {
            name: Resources.MCSBNS5ShortHeader,
            url: '#',
            key: Resources.MCSBNS5,
            title: '',
          },
          {
            name: Resources.MCSBNS6ShortHeader,
            url: '#',
            key: Resources.MCSBNS6,
            title: '',
          },
          {
            name: Resources.MCSBNS7ShortHeader,
            url: '#',
            key: Resources.MCSBNS7,
            title: '',
          },
          {
            name: Resources.MCSBNS8ShortHeader,
            url: '#',
            key: Resources.MCSBNS8,
            title: '',
          },
          {
            name: Resources.MCSBNS9ShortHeader,
            url: '#',
            key: Resources.MCSBNS9,
            title: '',
          },
          {
            name: Resources.MCSBNS10ShortHeader,
            url: '#',
            key: Resources.MCSBNS10,
            title: '',
          },
        ],
      },
    ];



    return (
      <div>
        <Helmet
          defaultTitle={Resources.NMSDetailPageTitle}
          titleTemplate={`%s - ${Resources.NMSDetailPageTitle}`}>
          <meta content={Resources.NMSLandingPageDescription} name="description" />
        </Helmet>
        <Stack>
          <Stack horizontal>
            {/* nav panel */}
            <Stack.Item styles={{ root: { backgroundColor: '#edebe9' } }}>
              <Stack>
                <Stack.Item>
                  {this.renderGroupHeader()}
                </Stack.Item>
                <Stack.Item>
                  {this.state.isSelectionPanelVisible ?
                    <Nav
                      selectedKey={this.state.selectedKey}
                      ariaLabel="MCSB Security navigation"
                      styles={navStyles}
                      groups={navLinkGroups}
                      onLinkClick={(ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => { this.updateSelectedKey(ev, item) }}
                    /> :
                    <Nav
                      selectedKey={this.state.selectedKey}
                      ariaLabel="MCSB Security navigation"
                      styles={navStylesShortHeader}
                      groups={navLinkGroupsShortHeader}
                      onLinkClick={(ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => { this.updateSelectedKey(ev, item) }}
                    />
                  }
                </Stack.Item>
              </Stack>
            </Stack.Item>
            {/* vertical section 2 */}
            <Stack.Item grow style={{ backgroundColor: '#faf9f8', margin: '1px 10px 10px 10px' }}>

              {this.getSelectedGroup()}
            </Stack.Item>
          </Stack>
        </Stack>
      </div>
    );
  }
}

export default compose<React.ComponentType>(
)(MCSBSecurity);