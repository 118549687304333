import { Reducer } from 'redux';
import { IMicroUIState, initialMicroUIState } from './state';
import {  MicroUIInitialized, MicroUIActions } from './actions';
import { DeepReadonly } from '../../types/base-types';

export const microUIReducer: Reducer<IMicroUIState, MicroUIActions> = (
  state: DeepReadonly<IMicroUIState> = initialMicroUIState,
  action: DeepReadonly<MicroUIActions>,
): IMicroUIState => {
  switch (action.type) {
    case MicroUIInitialized:
      return {
        ...state,
        isEnabled: action.payload.isEnabled,
      };
    
    default:
      return state;
  }
};
