import { DeepReadonly } from "../types/base-types";
import { MicroUIService } from "./@microUI/service";

export default class AppContext {
  constructor(private readonly microUIService: DeepReadonly<MicroUIService>) {}

  public microUIResize(): void {
    this.microUIService.resize();
  }

  public microUIRedirect(urlPathName: string, isExternalLink = false, openInNewTab = false): void {
    this.microUIService.redirect(urlPathName, isExternalLink, openInNewTab);
  }

  public microUIViewUpdate(viewKey: string): void {
    this.microUIService.updateView(viewKey);
  }
}
